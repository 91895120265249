
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import VaultsList from '@/components/vaults/VaultsList.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {
    VaultsList,
  },

  setup() {
    const router = useRouter();
    const { upToLargeBreakpoint } = useBreakpoints();

    // COMPUTED
    const deprecated = computed(
      () => router.currentRoute.value.name === 'deprecated_vaults'
    );

    return {
      upToLargeBreakpoint,
      // computed
      deprecated,
    };
  },
});
