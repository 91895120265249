import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ebc618f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "locale-container"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative",
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLocaleMenu = false))
  }, [
    _createVNode(_component_BalBtn, {
      circle: "",
      color: "zink",
      size: _ctx.upToLargeBreakpoint ? 'xs' : 'sm',
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLocaleMenu = true))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.appLocale.split('-')[1]), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    (_ctx.showLocaleMenu)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, localeKey) => {
            return (_openBlock(), _createElementBlock("li", {
              key: localeKey,
              class: _normalizeClass(["locale-item", { active: _ctx.appLocale === localeKey }]),
              onClick: ($event: any) => (_ctx.setLocale(localeKey))
            }, _toDisplayString(_ctx.locales[localeKey]), 11, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 32))
}