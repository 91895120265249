import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notice-box", { 'text-black': _ctx.isNftPage }])
  }, [
    _createElementVNode("div", null, [
      _createTextVNode(" Please use our Lite UI "),
      _createElementVNode("span", {
        class: "appx-con",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoAppX && _ctx.gotoAppX(...args)))
      }, "app.acryptos.com"),
      _createTextVNode(" for staking if you encounter performance issues on this site. ")
    ])
  ], 2))
}