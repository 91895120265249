import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6766892e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tvl = _resolveComponent("Tvl")!
  const _component_PendingHarvest = _resolveComponent("PendingHarvest")!
  const _component_AcsAcsiPrice = _resolveComponent("AcsAcsiPrice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tvl, { class: "mr-4 mb-4" }),
    (_ctx.userNetworkConfig.chainId === 56)
      ? (_openBlock(), _createBlock(_component_PendingHarvest, {
          key: 0,
          class: "mr-4 mb-4"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_AcsAcsiPrice, { class: "mr-4 mb-4" })
  ]))
}