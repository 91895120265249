
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'BalRadio',

  emits: ['update:modelValue'],

  props: {
    name: { type: String, required: true },
    value: { type: [String, Number], required: true },
    modelValue: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'md',
      validator: (val: string): boolean => ['sm', 'md', 'lg'].includes(val),
    },
    color: {
      type: String,
      default: 'blue',
      validator: (val: string): boolean => ['blue'].includes(val),
    },
  },

  setup(props, { emit }) {
    function onChange(value) {
      if (!props.disabled) emit('update:modelValue', value);
    }

    const sizeClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'w-3 h-3';
        case 'lg':
          return 'w-6 h-6';
        default:
          return 'w-4 h-4';
      }
    });

    const textSizeClass = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'leading-none text-sm';
        case 'lg':
          return 'leading-none text-lg';
        default:
          return 'leading-none text-base';
      }
    });

    const colorClass = computed(() => {
      if (props.disabled) return 'text-zink-500';
      return `text-${props.color}-500`;
    });

    const cursrorClass = computed(() => {
      if (props.disabled) return 'cursor-not-allowed';
      return 'cursor-pointer';
    });

    const inputClasses = computed(() => {
      return {
        [sizeClasses.value]: true,
        [colorClass.value]: true,
        [cursrorClass.value]: true,
      };
    });

    const labelClasses = computed(() => {
      return {
        [textSizeClass.value]: true,
        [cursrorClass.value]: true,
      };
    });

    return {
      onChange,
      inputClasses,
      labelClasses,
    };
  },
});
