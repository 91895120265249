const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x51Ed9c0c8e0bB36f062CCEc5Ebeb73C8a234656A',
  wbnb: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // WFTM
  busd: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E', // DAI
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [
    '0xe120ffBDA0d14f3Bb6d6053E90E63c572A66a428', // spooky WFTM-DAI
  ],
  additionalPricingPairsDeprecated: [],
};

Config.farmVaults = [
  {
    vault: {
      address: '0x569B344AD6BF087A285f0D415d0066028921D873',
      tokenSymbol: 'FTM',
      tokenImage: 'ftm.svg',
      isGasToken: true,
      firstDepositBlock: 35426243,
      isLiquid: true,
      isBestDayReturn: true,
    },
    tags: ['validator'],
  },
];

export { Config };
