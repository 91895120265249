
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},

  props: {
    farmVaultTags: { type: Array },
  },

  setup() {
    // DATA
    const tags = {
      core: 'Core',
      venusv1: 'VenusV1',
      venusProtocol: 'Venus',
      channels: 'Channels',
      channelsv1: 'ChannelsV1',
      swipeswap: 'Swip',
      mdex: 'MDEX',
      unifi: 'Unifi',
      wav: '🌊',
      atlantis: 'Atlantis',
      dsg: 'DSG',
      biswap: 'Biswap',
      pancakeV2: 'PancakeV2',
      pancakeswapv3: 'PancakeswapV3',
      uniswapv3: 'UniswapV3',
      stableswap: 'StableSwap',
      acsfi: 'ACS',
      acsifi: 'ACSI',
      acryptos: 'Acryptos',
      new: 'New',
      newFees: 'New Fees',
      beta: 'Beta',
      v2: 'V2',
      boost: '🔥',
      onsen: '♨️',
      test: 'Test',
      deprecated: 'Deprecated',
      upgrading: 'Upgrading',
      pancakeV1: 'PancakeV1',
      acsV1: 'AcsFarmV1',
      acsiV1: 'AcsiFarmV1',
      tectonic: 'Tectonic',
      tranquil: 'Tranquil',
      aave: 'Aave',
      valas: 'Valas',
      validator: 'Validator',
      liquidstaking: 'LiquidStaking',
      annex: 'Annex',
      ankr: 'Ankr',
      benqi: 'Benqi',
      flairdex: 'Flairdex',
      moonwell: 'Moonwell',
      scream: 'Scream',
      geist: 'Geist',
      apelending: 'Apelending',
      starlay: 'Starlay',
      agave: 'Agave',
      dforce: 'Dforce',
      radiantv1: 'RadiantV1',
      sonne: 'Sonne',
      mare: 'Mare',
      marev1: 'MareV1',
      equilibre: 'Equilibre',
      velocimeter: 'Velocimeter',
      solidlizard: 'SolidLizard',
      chronos: 'Chronos',
      solunea: 'Solunea',
      verse: 'Verse',
      toreus: 'Toreus',
      aerodrome: 'Aerodrome',
      magicfox: 'Magicfox',
      biswapv3: 'BiswapV3',
      biswapv3d: 'BiswapV3d',
      warning: 'Warning',
    };

    return {
      // data
      tags,
    };
  },
});
