import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import useWeb3 from './web3/useWeb3';
import useVaults from './useVaults';
import useMultiCall from './useMultiCall';
import collections from '@/constants/collections';
import abis from '@/constants/abis';
import { Call, NFT } from '@/constants/types';
import { bn, tw, fw } from '@/lib/utils/bn';
import { showTokenBalance } from '@/lib/utils/web3';
import { Config } from '@/lib/vault/config/config';

const isInited = ref(false as boolean);
const calls = ref([] as Call[]);
const successModalOpen = ref(false as boolean);
const loadingNewNft = ref(false as boolean);
const newNft = ref({} as NFT);
// const nftsMetadata = ref(null as any);
const collectionsData = ref(
  collections.map((item) => ({
    ...item,
    jsonDatas: {} as any,
    allNfts: [],
    tokenIds: [],
  })) as any[]
);

export default function useNfts() {
  const toast = useToast();
  const { t } = useI18n();
  const { connector, account, connectWallet } = useWeb3();
  const { acsStats } = useVaults();
  const { multiCall } = useMultiCall();

  // MOUNTED
  onMounted(() => {
    getNftsMetadata();
    if (!isInited.value) {
      isInited.value = true;
      setInterval(() => {
        getInitialData();
      }, 10000);
    }
  });

  // METHODS
  const nftContract = (address) => {
    return connector.value.web3
      ? new connector.value.web3.eth.Contract(abis.nft, address)
      : null;
  };
  const tokenContract = (address) => {
    return connector.value.web3
      ? new connector.value.web3.eth.Contract(abis.erc20, address)
      : null;
  };
  const vaultContract = (address) => {
    return connector.value.web3
      ? new connector.value.web3.eth.Contract(abis.vault, address)
      : null;
  };

  const stakeMint = async (amount, index = 0) => {
    const collection = collections[index];
    const collectionData = collectionsData.value[index];
    const nftCon = nftContract(collection.address);
    const tokenCon = tokenContract(collection.token);
    try {
      if (!account.value) {
        await connectWallet();
        await getInitialData();
        return false;
      }
      if (!amount || amount < collection.minStake) {
        toast.warning(t('alert_input_correct_amount'));
        return;
      }
      if (!nftCon) {
        toast.error(t('alert_stake_mint_error'));
        return false;
      }

      if (collectionData.userAllowance.lt(bn(tw(`${amount}`)))) {
        await connector.value.send(
          tokenCon.methods.approve(collection.address, Config.maxuint)
        );
      }
      await connector.value.send(
        nftCon.methods.mintWithStake(account.value, tw(`${amount}`))
      );
      toast(t('alert_stake_mint_success'));

      // loadingNewNft.value = true;
      await getInitialData();
      // const collectionNfts = collectionsData.value[index].allNfts;
      // const userNfts = collectionNfts.filter(
      //   item =>
      //     item.owner &&
      //     account.value &&
      //     item.owner.toLowerCase() === account.value.toLowerCase()
      // );
      // newNft.value = userNfts[userNfts.length - 1];
      // loadingNewNft.value = false;
      //setSuccessModalOpen(true);

      return true;
    } catch (error) {
      console.error(error);
      toast.error(t('alert_stake_mint_error'));
      return false;
    }
  };

  const getNftsMetadata = () => {
    Promise.all(
      collections.map(async (collection, index) => {
        fetch(`${collection.baseUri}/index.json`)
          .then(async (r) => {
            const response = await r.text();
            collectionsData.value[index].jsonDatas = JSON.parse(response);
          })
          .catch((e) => {
            console.error(e);
          });
      })
    );
  };

  const getInitialData = async () => {
    collections.map(async (collection, index) => {
      for (
        let ind = 0;
        ind < collectionsData.value[index].allNfts.length;
        ind++
      ) {
        const staked = collectionsData.value[index].allNfts[ind]?.stakedBN;
        if (staked) {
          const stakedValue = collectionsData.value[index].allNfts[ind].staked;
          const tokenUsd =
            collection.key === 'acs'
              ? // @ts-ignore
                acsStats.value?.acsPriceUsd
              : // @ts-ignore
                acsStats.value?.acsiPriceUsd;
          const stakedUsd = stakedValue * Number.parseFloat(tokenUsd);
          const stakedUsdShow = Number.parseInt(`${stakedUsd}`);
          const priceShow = showTokenBalance(staked.mul(bn(100)).div(bn(88)));
          collectionsData.value[index].allNfts[ind] = {
            ...collectionsData.value[index].allNfts[ind],
            staked: stakedValue,
            stakedUsd,
            stakedUsdShow,
            price: priceShow,
            priceShow: `${priceShow} ${collection.tokenSymbol}`,
          };
        }
      }
    });

    calls.value = [];
    collections.map((collection, index) => {
      const vaultCon = vaultContract(collection.vault);
      const nftCon = nftContract(collection.address);
      const tokenCon = tokenContract(collection.token);
      if (vaultCon) {
        calls.value.push({
          target: collection.vault,
          method: () => vaultCon.methods.getPricePerFullShare(),
          cb: (i) => {
            collectionsData.value[index].pricePerFullShare = bn(i);
          },
        });
      }
      if (nftCon) {
        calls.value.push({
          target: collection.address,
          method: () => nftCon.methods.totalSupply(),
          cb: async (i) => {
            const newTotalSupply = Number.parseInt(i);
            const oldTotalSupply =
              collectionsData.value[index].totalSupply || 0;
            if (oldTotalSupply < newTotalSupply) {
              await getTokenIds(index, oldTotalSupply, newTotalSupply);
              getNfts(index, oldTotalSupply, newTotalSupply);
            } else if (oldTotalSupply > newTotalSupply) {
              await getTokenIds(index, 0, newTotalSupply);
              getNfts(index, 0, newTotalSupply);
            }
            collectionsData.value[index].totalSupply = newTotalSupply;
          },
        });
      }
      if (account.value && tokenCon) {
        calls.value.push({
          target: collection.token,
          method: () =>
            tokenCon.methods.allowance(account.value, collection.address),
          cb: (i) => {
            collectionsData.value[index].userAllowance = bn(i);
          },
        });
      }
    });
    if (!calls.value.length) {
      return;
    }
    await multiCall(calls.value);
  };

  const getTokenIds = async (index, from, to) => {
    const collection = collections[index];
    const nftCon = nftContract(collection.address);
    if (!nftCon) {
      return;
    }
    calls.value = [];
    for (let ind = from; ind < to; ind++) {
      calls.value.push({
        target: collection.address,
        method: () => nftCon.methods.tokenByIndex(ind),
        cb: (i) => {
          collectionsData.value[index].tokenIds[ind] = i;
        },
      });
    }
    await multiCall(calls.value);
  };

  const getNfts = async (index, from, to) => {
    try {
      const collection = collections[index];
      const nftCon = nftContract(collection.address);
      const tokenIds = collectionsData.value[index]?.tokenIds;
      if (!nftCon) {
        return;
      }
      calls.value = [];
      for (let ind = from; ind < to; ind++) {
        const jsonData =
          collectionsData.value[index].jsonDatas[`${tokenIds[ind]}`];
        if (jsonData) {
          collectionsData.value[index].allNfts[ind] = jsonData;

          const imageSplit = jsonData.image.split('/');
          const imageName = imageSplit[imageSplit.length - 1].split('.')[0];
          collectionsData.value[index].allNfts[ind] = {
            ...collectionsData.value[index].allNfts[ind],
            character: imageName,
          };
        }

        calls.value.push({
          target: collection.address,
          method: () => nftCon.methods.ownerOf(tokenIds[ind]),
          cb: (i) => {
            collectionsData.value[index].allNfts[ind] = {
              ...collectionsData.value[index].allNfts[ind],
              owner: i,
              id: `${tokenIds[ind]}`,
            };
          },
        });

        calls.value.push({
          target: collection.address,
          method: () => nftCon.methods.balanceOfStakedVault(tokenIds[ind]),
          cb: (i) => {
            const staked = bn(i)
              .mul(collectionsData.value[index].pricePerFullShare)
              .div(bn(1e18));
            const stakedValue = parseFloat(fw(staked));
            const stakedShow = `${showTokenBalance(staked)}  ${
              collection.tokenSymbol
            }`;

            collectionsData.value[index].allNfts[ind] = {
              ...collectionsData.value[index].allNfts[ind],
              stakedBN: staked,
              staked: stakedValue,
              stakedShow,
              id: `${tokenIds[ind]}`,
            };
          },
        });
      }
      multiCall(calls.value);
    } catch (error) {
      console.error(error);
    }
  };

  const setSuccessModalOpen = (newValue) => {
    successModalOpen.value = newValue;
  };

  const burn = async (tokenId, index = 0) => {
    if (!account.value) {
      throw new Error('no connected account');
    }
    const collection = collections[index];
    const nftCon = nftContract(collection.address);
    if (!nftCon) {
      throw new Error('no nft contract');
    }
    await connector.value.send(nftCon.methods.burn(tokenId));
  };

  return {
    // data
    collectionsData,
    loadingNewNft,
    newNft,
    successModalOpen,
    // methods
    burn,
    setSuccessModalOpen,
    stakeMint,
  };
}
