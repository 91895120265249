require('promise.any').shim(); // needed for android 4 - browserslist doesn't work for this

import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import WalletConnectProvider from '@walletconnect/web3-provider';
//import resolveConfig from 'tailwindcss/resolveConfig';
import { providers as ethersProviders } from 'ethers';
import { providers } from '@0xsequence/multicall';
//import tailwindConfig from '../../../tailwind.config';
import { Network, networkId } from '@/composables/useNetwork';
import { mobileCheck } from '../utils';

//const fullConfig = resolveConfig(tailwindConfig);

class Connect extends EventTarget {
  web3Ago;
  web3Modal;
  account = '';
  publicWeb3;
  web3;
  connectedWallet;
  networkConfig;
  connectStatus;
  multicallProvider;
  agoMulticallProvider;

  constructor() {
    super();
  }

  async init(networkConfig) {
    // this.init = async () => {};
    this.networkConfig = networkConfig;

    this.web3Ago = new Web3(
      this.networkConfig.agoRpc || this.networkConfig.rpc
    );
    await this._initPublicRpcs();

    if (this.networkConfig.chainId === networkId.value) {
      this._initWeb3Modal();
      this._initUserRpc(); // note this may error out, so no await
    }
  }

  _initWeb3Modal() {
    const isMobile = mobileCheck();
    let providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: {
            [this.networkConfig.chainId]: this.networkConfig.rpc,
          },
          network: this.networkConfig.networkFull,
          chainId: this.networkConfig.chainId,
        },
      },
      'custom-coinbase': {
        display: {
          logo: '/images/connectors/walletlink.svg',
          name: 'Coinbase Wallet',
          description: 'Scan with WalletLink to connect',
        },
        options: {
          appName: 'ACryptoS', // Your app name
          networkUrl: this.networkConfig.rpc,
          chainId: this.networkConfig.chainId,
        },
        package: WalletLink,
        connector: async (_, options) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
    };
    if (!isMobile) {
      providerOptions = {
        ...providerOptions,
        // @ts-ignore
        'custom-metamask': {
          display: {
            logo: '/images/connectors/metamask.svg',
            name: 'Metamask',
            description: 'Connect to your Metamask Wallet',
          },
          options: {
            appName: 'ACryptoS', // Your app name
            networkUrl: this.networkConfig.rpc,
            chainId: this.networkConfig.chainId,
          },
          package: true,
          connector: async () => {
            let provider = undefined;
            if (
              typeof window.ethereum !== 'undefined' &&
              window.ethereum.isMetaMask &&
              !window.ethereum.isBraveWallet
            ) {
              provider = window.ethereum;
              try {
                // @ts-ignore
                await provider.request({ method: 'eth_requestAccounts' });
              } catch (error) {
                console.error(error);
                throw new Error('User Rejected');
              }
            } else {
              throw new Error('No Metamask Wallet found');
            }
            if (provider === undefined) {
              throw new Error(
                'There is no Metamask wallet installed in this browser.'
              );
            }
            return provider;
          },
        },
        'custom-brave': {
          display: {
            logo: '/images/connectors/brave.svg',
            name: 'Brave',
            description: 'Connect to your Brave Wallet',
          },
          options: {
            appName: 'ACryptoS', // Your app name
            networkUrl: this.networkConfig.rpc,
            chainId: this.networkConfig.chainId,
          },
          package: true,
          connector: async () => {
            let provider = undefined;
            if (
              typeof window.ethereum !== 'undefined' &&
              window.ethereum.isBraveWallet
            ) {
              provider = window.ethereum;
              try {
                // @ts-ignore
                await provider.request({ method: 'eth_requestAccounts' });
              } catch (error) {
                console.error(error);
                throw new Error('User Rejected');
              }
            } else {
              throw new Error('No Brave Wallet found');
            }
            if (provider === undefined) {
              throw new Error(
                'There is no Brave wallet installed in this browser.'
              );
            }
            return provider;
          },
        },
        'custom-trust': {
          display: {
            logo: '/images/connectors/trust.svg',
            name: 'Trust Wallet',
            description: 'Connect to your Trust Wallet',
          },
          options: {
            appName: 'ACryptoS', // Your app name
            networkUrl: this.networkConfig.rpc,
            chainId: this.networkConfig.chainId,
          },
          package: true,
          connector: async () => {
            let provider = undefined;
            if (
              typeof window.ethereum !== 'undefined' &&
              window.ethereum.isTrustWallet
            ) {
              provider = window.ethereum;
              try {
                // @ts-ignore
                await provider.request({ method: 'eth_requestAccounts' });
              } catch (error) {
                console.error(error);
                throw new Error('User Rejected');
              }
            } else {
              throw new Error('No Trust Wallet found');
            }
            if (provider === undefined) {
              throw new Error(
                'There is no Trust wallet installed in this browser.'
              );
            }
            return provider;
          },
        },
      };
    }
    if (this.networkConfig.chainId === Network.BSC) {
      providerOptions = {
        ...providerOptions,
        // @ts-ignore
        'custom-binancechainwallet': {
          display: {
            logo: '/images/connectors/binance.svg',
            name: 'Binance Chain Wallet',
            description: 'Connect to your Binance Chain Wallet',
          },
          package: true,
          connector: async () => {
            let provider = null;
            // @ts-ignore
            if (typeof window.BinanceChain !== 'undefined') {
              // @ts-ignore
              provider = window.BinanceChain;
              try {
                // @ts-ignore
                await provider.request({ method: 'eth_requestAccounts' });
              } catch (error) {
                throw new Error('User Rejected');
              }
            } else {
              throw new Error('No Binance Chain Wallet found');
            }
            return provider;
          },
        },
      };
    }
    this.web3Modal = new Web3Modal({
      theme: {
        //background: fullConfig.theme.colors.gray['750'],
        background: '#25252D',
        main: 'rgb(199, 199, 199)',
        secondary: 'rgb(136, 136, 136)',
        border: 'rgba(195, 195, 195, 0.14)',
        hover: '#31313C',
        //hover: fullConfig.theme.colors.gray['730'],
      },
      network: this.networkConfig.networkFull, // optional
      cacheProvider: true, // optional
      disableInjectedProvider: !isMobile,
      providerOptions,
    });
  }

  setAccount(account) {
    if (this.account !== account) {
      this.account = account;
      this.dispatchEvent(new CustomEvent('accountChanged'));
    }
  }

  handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      this.account = '';
      this.setConnectStatus('disconnected');
    } else if (this.account !== accounts[0]) {
      this.account = accounts[0];
      this.setConnectStatus('connected');
    }
    this.dispatchEvent(new CustomEvent('accountChanged'));
  }
  setConnectStatus(value) {
    this.connectStatus = value;
    this.dispatchEvent(new CustomEvent('updateConnectStatus'));
  }

  async _initPublicRpcs() {
    const web3 = await Promise.any(
      shuffleArray(this.networkConfig.allRpcs)
        .slice(0, 3)
        .map(async (rpc) => {
          const web3 = new Web3(rpc);
          // if (this.networkConfig.chainId === Network.BSC) {
          //   const validProvider = await checkProvider(
          //     web3,
          //     this.networkConfig.addresses.acs
          //   );
          //   if (validProvider) {
          //     return web3;
          //   } else {
          //     throw new Error('invalid');
          //   }
          // }
          return web3;
        })
    );
    this.publicWeb3 = web3;
    this.web3 = web3;
    this.dispatchEvent(new CustomEvent('networkConnected'));

    this.multicallProvider = new providers.MulticallProvider(
      new ethersProviders.JsonRpcProvider(this.networkConfig.rpc) as any,
      {
        verbose: true,
        batchSize: 300,
        timeWindow: 500, // ms
        contract: this.networkConfig.addresses.multicall,
      }
    );
    if (this.networkConfig.chainId === 56) {
      this.agoMulticallProvider = new providers.MulticallProvider(
        new ethersProviders.JsonRpcProvider(this.networkConfig.agoRpc) as any,
        {
          verbose: true,
          batchSize: 300,
          timeWindow: 500, // ms
          contract: this.networkConfig.addresses.multicall,
        }
      );
    }
  }

  async _initUserRpc() {
    if (this.web3Modal.cachedProvider) {
      this.setUserProvider(await this.web3Modal.connect());
    }
  }

  async connect() {
    try {
      await this.web3Modal.clearCachedProvider();
      const provider = await this.web3Modal.connect();
      const isSucceed = await this.setUserProvider(provider);
      if (!isSucceed) {
        throw new Error('invalid');
      }
    } catch (error) {
      this.setConnectStatus('disconnected');
      throw new Error();
    }
  }

  disconnect = async () => {
    await this.web3Modal.clearCachedProvider();
    this.handleAccountsChanged([]);
  };

  async setUserProvider(provider) {
    try {
      this.setConnectStatus('connecting');
      // let validProvider = false;
      // if (this.networkConfig.chainId === Network.BSC) {
      //   validProvider = await checkProvider(
      //     providerWeb3,
      //     this.networkConfig.addresses.acs
      //   );
      // } else {
      // }
      if (parseInt(provider.chainId) !== networkId.value) {
        const switchSuccess = await switchToAppNetwork(
          provider,
          this.networkConfig
        );
        if (!switchSuccess) {
          this.setConnectStatus('disconnected');
          return false;
        }
      }
      this.connectedWallet = this.web3Modal.cachedProvider;

      //provider.autoRefreshOnNetworkChange = false;
      provider.on('chainChanged', async (chainId) => {
        if (parseInt(chainId) !== networkId.value) {
          window.location.reload();
        }
      });

      if (this.web3) {
        this.web3.setProvider(provider);
      } else {
        this.web3 = new Web3(provider);
      }

      /***********************************************************/
      /* Handle user accounts and accountsChanged (per EIP-1193) */
      /***********************************************************/
      await provider
        .request({ method: 'eth_accounts' })
        .then((accounts) => this.handleAccountsChanged(accounts))
        .catch((err) => {
          // Some unexpected error.
          // For backwards compatibility reasons, if no accounts are available,
          // eth_accounts will return an empty array.
          console.error(err);
        });

      provider.on('accountsChanged', (accounts) => {
        this.handleAccountsChanged(accounts);
        // if (provider === this.web3.currentProvider) {
        // }
      });
      // provider.on('disconnect', () => {
      //   if (provider === this.web3.currentProvider) {
      //     this.handleAccountsChanged([]);
      //   }
      // });
      return true;
    } catch (error) {
      this.setConnectStatus('disconnected');
      console.error(error);
      return false;
    }
  }

  async send(tx, options = {}) {
    const gasPrice = parseInt(await this.web3.eth.getGasPrice()) + 1;
    let txOption;
    if (this.networkConfig.chainId === 56) {
      txOption = {
        from: this.account,
        // gasPrice: 5e9,
        gasPrice: gasPrice,
        ...options,
      };
    } else if (this.networkConfig.chainId === 1666600000) {
      const estimateGas = await tx.estimateGas({
        from: this.account,
        gasPrice,
        ...options,
      });
      txOption = {
        from: this.account,
        // gasPrice: 5e9,
        gasPrice: gasPrice,
        gas: parseInt(`${estimateGas * 2}`),
        ...options,
      };
    } else {
      //rest of the chains are eip-1559 ready

      const estimateGas = await tx.estimateGas({
        from: this.account,
        //gasPrice,
        maxPriorityFeePerGas: null, //eip-1559 compat
        maxFeePerGas: null, //eip-1559 compat
        ...options,
      });

      txOption = {
        from: this.account,
        maxPriorityFeePerGas: null, //eip-1559 compat
        maxFeePerGas: null, //eip-1559 compat
        //gas: parseInt(
        //  `${estimateGas * (this.networkConfig.chainId === 250 ? 4 : 2)}`
        //),
        gas: parseInt(`${estimateGas * 1.5}`),
        ...options,
      };
    }
    // console.log('Sending tx', tx);
    return new Promise((resolve, reject) => {
      tx.send(txOption)
        .on('transactionHash', function () {
          // console.log('tx hash', hash);
        })
        .catch((e) => {
          console.error(e);
          //alert(JSON.stringify(e, null, 2));
          reject(e);
        })
        .then((receipt) => {
          // console.log('tx receipt', receipt);
          // if(!resolveOnSent)
          resolve(receipt);
        });
    });
  }
}

// async function checkProvider(web3, address) {
//   const acsC = new web3.eth.Contract(
//     [
//       {
//         constant: !0,
//         inputs: [],
//         name: 'name',
//         outputs: [{ internalType: 'string', name: '', type: 'string' }],
//         payable: !1,
//         stateMutability: 'view',
//         type: 'function'
//       }
//     ],
//     address
//   ); // ACS token
//   const validProvider =
//     (await acsC.methods
//       .name()
//       .call()
//       .catch(e => e)) === 'ACryptoS';
//   return validProvider;
// }

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

async function switchToAppNetwork(provider, networkConfig) {
  const hexChainId = `0x${networkConfig.chainId.toString(16)}`;
  try {
    if (provider.request) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
      return true;
    }
  } catch (error) {
    console.error(error);
    // user rejected request
    // @ts-ignore
    if (error.code === 4001) {
      return false;
    }
    // chain does not exist, let's add it
    // @ts-ignore
    if (error.code === 4902) {
      return importNetworkDetailsToWallet(provider, networkConfig);
    }
  }
  return false;
}

async function importNetworkDetailsToWallet(provider, networkConfig) {
  const hexChainId = `0x${networkConfig.chainId.toString(16)}`;
  try {
    const request = {
      id: '1',
      jsonrpc: '2.0',
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: hexChainId,
          chainName: networkConfig.name,
          rpcUrls: [networkConfig.rpc],
          iconUrls: [networkConfig.nativeAsset.logoURI],
          nativeCurrency: {
            name: networkConfig.nativeAsset.name,
            symbol: networkConfig.nativeAsset.symbol,
            decimals: networkConfig.nativeAsset.decimals,
          },
          blockExplorerUrls: [networkConfig.explorer],
        },
      ],
    };
    if (provider?.request) {
      const response = await provider.request(request);
      if (response?.error) {
        throw new Error(
          `Failed to add network information to wallet. ${response.error.code}:${response.error.message}`
        );
      }
      return true;
    } else {
      throw new Error(`Could not find an external provider with 'request'`);
    }
  } catch (err) {
    console.error(
      `An error occurred while attempting to add network information to wallet. ${
        (err as Error).message
      }`
    );
    return false;
  }
}

export { Connect };
