<template>
  <div class="release">
    <div class="release-title-top">
      <div class="release-title-top-left parallelogram"></div>
      <div class="release-title-top-desc">
        {{ $t('welcome_to_acryptos_samurais_first') }}
      </div>
      <div class="release-title-top-right parallelogram"></div>
    </div>
    <div class="release-title">{{ $t('nft_release') }}</div>
    <div class="release-title-bottom parallelogram"></div>
    <div class="release-characters-container">
      <img
        src="~@/assets/images/release-characters-gold.png"
        class="release-characters"
      />
    </div>
  </div>
</template>
<style scoped>
@media (max-width: 1024px) {
  .release {
    height: 470px;
  }
  .release-title-top {
    margin-left: calc(50% - 126px);
  }
  .release-title-top-left,
  .release-title-top-right {
    width: 30px;
    margin-top: 5px;
  }
  .release-title-bottom {
    width: 282px;
    margin-left: calc(50% - 142px);
    margin-top: -2px;
  }
  .release-characters-container {
    @apply -mt-1;
    height: 219px;
  }
}
@media (min-width: 1024px) {
  .release {
    height: 1086px;
  }
  .release-title-top {
    margin-left: calc(50% - 268px);
  }
  .release-title-top-left,
  .release-title-top-right {
    width: 82px;
    margin-top: 7px;
  }
  .release-title-bottom {
    width: 600px;
    margin-left: calc(50% - 302px);
    margin-top: -2px;
  }
  .release-characters-container {
    @apply -mt-4;
    height: 610px;
  }
}
.release {
  @apply w-full bg-transparent;
  background-image: url('~@/assets/images/release-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.release-title-top {
  @apply flex pt-24 lg:pt-36;
}
.release-title-top-desc {
  @apply text-red-520 font-black text-ssm lg:text-xl italic ml-2 lg:ml-3 mr-3 lg:mr-5;
  letter-spacing: 0.01em;
}
.release-title {
  @apply text-center text-red-520 font-black text-4xl lg:text-7xl italic -mt-2 lg:-mt-4;
  letter-spacing: -0.03em;
}
.release-characters {
  @apply mx-auto h-full;
}
</style>
