<template>
  <div class="join">
    <img src="~@/assets/images/join-effect.svg" class="join-effect" />
    <img src="~@/assets/images/join-akira-gold.png" class="join-akira" />
    <p class="join-title">{{ $t('join') }}</p>
    <p class="join-subtitle" v-html="$t('join_subtitle')"></p>
    <p class="join-desc" v-html="$t('join_desc')"></p>
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .join {
    margin-top: -21%;
  }
  .join-title {
    top: 34%;
    left: 4%;
  }
  .join-subtitle {
    top: 46%;
    left: 23%;
  }
  .join-desc {
    top: 70%;
    left: 3%;
  }
}
@media (min-width: 440px) {
  .join {
    margin-top: -22%;
  }
  .join-title {
    top: 34%;
    left: 8%;
  }
  .join-subtitle {
    top: 44%;
    left: 24%;
  }
  .join-desc {
    top: 60%;
    left: 10%;
  }
}
@media (min-width: 640px) {
  .join {
    margin-top: -22%;
  }
  .join-title {
    top: 34%;
    left: 4%;
  }
  .join-subtitle {
    top: 39%;
    left: 6%;
  }
  .join-desc {
    top: 70%;
    left: 3%;
  }
}
@media (min-width: 768px) {
  .join {
    margin-top: -22%;
  }
  .join-title {
    top: 34%;
    left: 4%;
  }
  .join-subtitle {
    top: 39%;
    left: 6%;
  }
  .join-desc {
    top: 70%;
    left: 8%;
  }
}
@media (min-width: 1024px) {
  .join {
    margin-top: -21%;
  }
  .join-title {
    top: 28%;
    left: 9%;
  }
  .join-subtitle {
    top: 33%;
    left: 17%;
  }
  .join-desc {
    top: 51%;
    left: 18%;
  }
}
@media (min-width: 1280px) {
  .join {
    margin-top: -20%;
  }
  .join-title {
    top: 28%;
    left: 9%;
  }
  .join-subtitle {
    top: 33%;
    left: 17%;
  }
  .join-desc {
    top: 51%;
    left: 18%;
  }
}
@media (min-width: 1536px) {
  .join {
    margin-top: -20%;
  }
  .join-title {
    top: 28%;
    left: 9%;
  }
  .join-subtitle {
    top: 33%;
    left: 17%;
  }
  .join-desc {
    top: 51%;
    left: 18%;
  }
}
.join {
  @apply w-full bg-transparent relative;
  height: min(1215px, 61.9vw);
  background-image: url('~@/assets/images/join-bg.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.join-effect {
  @apply absolute bottom-0 w-full;
}
.join-akira {
  @apply absolute bottom-0 right-0;
  width: 57.5%;
}
.join-title {
  @apply absolute text-black font-normal text-4xl sm:text-5xl lg:text-7xl 2xl:text-9xl;
  font-family: Gloss And Bloom;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.join-subtitle {
  @apply absolute text-white font-black w-auto text-right italic text-base sm:text-3xl lg:text-4xl 2xl:text-5xl;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.join-desc {
  @apply absolute text-white italic font-normal text-center text-sssm sm:text-md lg:text-lg 2xl:text-xl;
  font-family: DINPro-CondMedium;
  transform: rotate(-9deg);
}
</style>
