import { ref } from 'vue';

// DATA
const selectedVaultId = ref(-1);

export default function useVaultsList() {
  // METHOD
  const setSelectedVaultId = (value) => {
    selectedVaultId.value = value;
  };

  return {
    // data
    selectedVaultId,
    // method
    setSelectedVaultId,
  };
}
