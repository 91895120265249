const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  wbnb: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', // WETH
  // busd: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802', // USDC
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

Config.farmVaults = [
  {
    vault: {
      address: '0x16C4BA98623a1B05d35b9923fB00E9fE02D0B47f',
      title: 'Acryptos Liquid veSLIZ (acsSLIZ)',
      tokenSymbol: 'SLIZ',
      tokenImage: 'sliz.png',
      isLiquid: true,
      isBestDayReturn: true,
      //defaultRoiDay: 0.0318 / 365,
    },
    tags: ['solidlizard', 'liquidstaking'],
  },
  {
    vault: {
      address: '0x94dc9Ec5A8a62f1c1DAb495D5FE65F3A070F2d27',
      title: 'ACryptoS Liquid veSLNA (acsSLNA)',
      tokenSymbol: 'SLNA',
      //tokenImage: 'sliz.png',
      isLiquid: true,
      isBestDayReturn: true,
      //defaultRoiDay: 0.0318 / 365,
    },
    tags: ['solunea', 'liquidstaking'],
  },
];

export { Config };
