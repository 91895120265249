import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-049de001"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pending-harvest" }
const _hoisted_2 = { class: "pending-harvest-label" }
const _hoisted_3 = { class: "pending-harvest-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('pending_harvest')) + ":", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_ctx.acsStats &&
          _ctx.acsStats.acsTotalHarvestPending &&
          !_ctx.acsStats.acsTotalHarvestPending.lten(0)
            ? `${_ctx.showTokenBalance(_ctx.acsStats.acsTotalHarvestPending)} 
                  ACS 
                  ($${_ctx.fwp(_ctx.acsStats.acsTotalHarvestPendingUsd)})`
            : '--'), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.acsStats &&
          _ctx.acsStats.acsiTotalHarvestPending &&
          !_ctx.acsStats.acsiTotalHarvestPending.lten(0)
            ? `${_ctx.showTokenBalance(_ctx.acsStats.acsiTotalHarvestPending)} 
                  ACSI 
                  ($${_ctx.fwp(_ctx.acsStats.acsiTotalHarvestPendingUsd)})`
            : '--'), 1)
    ])
  ]))
}