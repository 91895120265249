
import { defineComponent, ref, watch, computed } from 'vue';
import useDarkMode from '@/composables/useDarkMode';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  components: {},

  props: {
    filterKey: { type: String },
    routerName: {
      type: String,
      default: '',
    },
  },

  emits: ['search', 'filter'],

  setup(props, { emit }) {
    const { darkMode } = useDarkMode();
    const { userNetworkConfig } = useWeb3();

    // DATA
    const searchInput = ref('');
    const filterList = {
      56: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults', 'farms', 'governance_vaults', 'onsen_ido'],
        },
        {
          key: 'core',
          label: '',
          lists: ['Core'],
          pages: ['governance_vaults'],
        },
        {
          key: 'vaults',
          label: '',
          lists: ['Vaults'],
          pages: ['governance_vaults'],
        },
        {
          key: 'onsenIdo',
          label: '',
          lists: ['Onsen/IDO'],
          pages: ['governance_vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: [
            'LiquidStaking',
            'Annex',
            'Venus',
            'Channels',
            'Valas',
            'Apelending',
            'Dforce',
          ],
          pages: ['vaults'],
        },
        {
          key: 'multi_token_lp',
          label: 'multi_token_lp',
          lists: ['Biswap', 'WAV', 'PancakeswapV3', 'UniswapV3', 'BiswapV3'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults', 'farms'],
        },
        {
          key: 'ido',
          label: 'ido',
          lists: ['WAV'],
          pages: ['farms'],
        },
        {
          key: 'deprecated_vaults',
          label: 'Deprecated',
          lists: ['BiswapV3d', 'Magicfox'],
          pages: ['vaults'],
        },
      ],
      25: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Tectonic', 'Annex'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      1666600000: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Tranquil', 'Aave'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      250: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Validator', 'Scream', 'Geist'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      43114: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Benqi'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['Flairdex'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      1313161554: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Bastion'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      1285: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Moonwell'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      1284: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Moonwell'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      592: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Starlay'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      100: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Agave'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      42161: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['RadiantV1', 'Dforce'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['UniswapV3', 'Chronos', 'SolidLizard', 'Solunea'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      137: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Dforce'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['UniswapV3'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      1: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Dforce'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['Verse'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      10: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Dforce', 'Sonne'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['UniswapV3'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      2222: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Mare'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['Equilibre'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
        {
          key: 'deprecated_vaults',
          label: 'Deprecated',
          lists: ['Toreus', 'MareV1'],
          pages: ['vaults'],
        },
      ],
      8453: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: 'singleToken',
          label: 'singleToken',
          lists: ['Sonne', 'Moonwell'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['Aerodrome', 'UniswapV3'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
      7700: [
        {
          key: 'general',
          label: '',
          lists: ['All', 'Swords'],
          pages: ['vaults'],
        },
        {
          key: '2_token_lp',
          label: '2_token_lp',
          lists: ['Velocimeter'],
          pages: ['vaults'],
        },
        {
          key: 'stable_pools',
          label: 'stable_pools',
          lists: ['USD'],
          pages: ['vaults'],
        },
      ],
    };

    // COMPUTED
    const filterListForPage = computed(() =>
      filterList[userNetworkConfig.value.chainId].filter((item) =>
        item.pages.includes(props.routerName)
      )
    );

    // WATCHERS
    watch(searchInput, (newVal) => {
      emit('search', newVal);
    });

    return {
      darkMode,
      filterListForPage,
      // data
      searchInput,
    };
  },
});
