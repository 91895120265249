<template>
  <div class="cori-container">
    <img src="~@/assets/images/cori-gold.svg" class="cori" />
    <p class="cori-meet">{{ $t('meet') }}</p>
    <p class="cori-title">{{ $t('cori') }}</p>
    <p class="cori-desc" v-html="$t('cori_desc')"></p>
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .cori-container {
    margin-top: -19.9%;
    height: 93vw;
  }
  .cori {
    width: 45%;
    top: 16%;
    left: 52%;
  }
  .cori-meet {
    top: 31%;
    left: 31%;
  }
  .cori-title {
    top: 40%;
    left: 16%;
  }
  .cori-desc {
    top: 58%;
    left: 3%;
  }
}
@media (min-width: 440px) {
  .cori-container {
    margin-top: -19.9%;
    height: 93vw;
  }
  .cori {
    width: 45%;
    top: 16%;
    left: 52%;
  }
  .cori-meet {
    top: 31%;
    left: 31%;
  }
  .cori-title {
    top: 40%;
    left: 20%;
  }
  .cori-desc {
    top: 58%;
    left: 9%;
  }
}
@media (min-width: 640px) {
  .cori-container {
    margin-top: -19.9%;
    height: 93vw;
  }
  .cori {
    width: 45%;
    top: 16%;
    left: 52%;
  }
  .cori-meet {
    top: 31%;
    left: 31%;
  }
  .cori-title {
    top: 40%;
    left: 24%;
  }
  .cori-desc {
    top: 58%;
    left: 3%;
  }
}
@media (min-width: 768px) {
  .cori-container {
    margin-top: -19.9%;
    height: 93vw;
  }
  .cori {
    width: 45%;
    top: 16%;
    left: 52%;
  }
  .cori-meet {
    top: 31%;
    left: 31%;
  }
  .cori-title {
    top: 40%;
    left: 26%;
  }
  .cori-desc {
    top: 58%;
    left: 11%;
  }
}
@media (min-width: 1024px) {
  .cori-container {
    margin-top: -240px;
    height: 1024px;
  }
  .cori {
    width: 32%;
    top: 18%;
    left: 60%;
  }
  .cori-meet {
    top: 24%;
    left: 27%;
  }
  .cori-title {
    top: 37%;
    left: 14%;
  }
  .cori-desc {
    top: 61%;
    left: 10%;
  }
}
@media (min-width: 1280px) {
  .cori-container {
    margin-top: -302px;
    height: 1024px;
  }
  .cori {
    width: 32%;
    top: 23%;
    left: 55%;
  }
  .cori-meet {
    top: 30%;
    left: 35%;
  }
  .cori-title {
    top: 41%;
    left: 21%;
  }
  .cori-desc {
    top: 61%;
    left: 18%;
  }
}
@media (min-width: 1536px) {
  .cori-container {
    margin-top: -302px;
    height: 1376px;
  }
  .cori {
    width: 32%;
    top: 16%;
    left: 52%;
  }
  .cori-meet {
    top: 30%;
    left: 35%;
  }
  .cori-title {
    top: 41%;
    left: 21%;
  }
  .cori-desc {
    top: 61%;
    left: 18%;
  }
}
.cori-container {
  @apply w-full bg-transparent relative;
  background-image: url('~@/assets/images/cori-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.cori {
  @apply absolute;
}
.cori-meet {
  @apply absolute text-black font-black text-base sm:text-3xl lg:text-5xl italic;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.cori-title {
  @apply absolute text-red-520 font-normal text-4xl sm:text-5xl lg:text-10xl;
  font-family: Gloss And Bloom;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.cori-desc {
  @apply absolute text-black italic text-center text-sssm sm:text-lg lg:text-2xl;
  font-family: DINPro-CondMedium;
  transform: rotate(-9deg);
}
</style>
