<template>
  <div class="social-links-container">
    <a
      target="_blank"
      href="https://t.me/acryptos"
      class="telegram bg-blue-810 pl-1.5"
    >
      <BalIcon
        name="bx:bxl-telegram"
        :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
      />
    </a>
    <a
      target="_blank"
      href="https://twitter.com/acryptosdao"
      class="twitter bg-blue-930 flex items-center justify-center ml-4"
    >
      <BalIcon
        name="akar-icons:twitter-fill"
        :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
      />
    </a>
    <a target="_blank" href="https://medium.com/acryptos" class="medium">
      <BalIcon
        name="ant-design:medium-outlined"
        :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
      />
    </a>
    <a target="_blank" href="https://www.reddit.com/r/ACryptoS" class="reddit">
      <BalIcon
        name="dashicons:reddit"
        :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
      />
    </a>
    <a
      target="_blank"
      href="https://discord.gg/xHqhWxUeeR"
      class="telegram bg-blue-810 ml-2"
    >
      <BalIcon
        name="akar-icons:discord-fill"
        :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
      />
    </a>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      upToLargeBreakpoint,
    };
  },
});
</script>

<style scoped>
.social-links-container {
  @apply flex mt-6 lg:mt-8;
}
.telegram,
.twitter,
.medium,
.reddit {
  @apply h-6 w-6 lg:h-8 lg:w-8 rounded-full;
}
.telegram {
  @apply bg-blue-810 pl-1 lg:pl-1.5 flex items-center text-white;
}
.twitter {
  @apply bg-blue-930 flex items-center justify-center ml-2 text-white;
}
.medium,
.reddit {
  @apply bg-zink-670 dark:bg-white flex items-center justify-center ml-2 text-white dark:text-black;
}
</style>
