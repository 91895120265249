
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeMount,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import AppNavActions from '../navs/AppNav/AppNavActions.vue';
import AppXNoticeBoxVue from '../navs/AppNav/AppXNoticeBox.vue';
import NetworkSwitch from '../navs/AppNav/NetworkSwitch.vue';
import V1Switch from '../navs/AppNav/V1Switch.vue';
import VaultsTopNav from './top/index.vue';
import VaultsFilterWithSearch from './VaultsFilterWithSearch.vue';
import VaultsDetail from './detail/index.vue';
import Tags from './Tags.vue';
import useMenuCollapsed from '@/composables/useMenuCollapsed';
import useBreakpoints from '@/composables/useBreakpoints';
import useVaultsList from '@/composables/useVaultsList';
import useVaults from '@/services/useVaults';
import { plantLevel } from '@/lib/vault/farmVaultUtils';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  components: {
    AppXNoticeBoxVue,
    V1Switch,
    NetworkSwitch,
    AppNavActions,
    VaultsTopNav,
    VaultsFilterWithSearch,
    VaultsDetail,
    Tags,
  },

  props: {
    deprecated: { type: Boolean },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { formatedFarmVaults, formatedFarmVaultsDeprecated, initDeprecated } =
      useVaults();
    const { userNetworkConfig } = useWeb3();
    const { menuCollapsed } = useMenuCollapsed();
    const { width, upToLargeBreakpoint } = useBreakpoints();
    const { selectedVaultId, setSelectedVaultId } = useVaultsList();

    //COMPUTED
    const routerName = computed(() => router.currentRoute.value.name);

    const formatedFarmVaultsList = computed(() =>
      props.deprecated
        ? formatedFarmVaultsDeprecated.value
        : formatedFarmVaults.value
    );

    const isLoadingData = computed(() => !formatedFarmVaultsList.value.length);

    const formatedFarmVaultsForPage = computed(() => {
      if (routerName.value === 'vaults') {
        return formatedFarmVaultsList.value.filter(
          (item) => !item.tags.includes('core') && item.vault
        );
      } else if (routerName.value === 'farms') {
        return formatedFarmVaultsList.value.filter(
          (item) => !item.tags.includes('core') && !item.vault
        );
      } else if (routerName.value === 'governance_vaults') {
        return formatedFarmVaultsList.value.filter((item) =>
          item.tags.includes('core')
        );
      } else if (routerName.value === 'onsen_ido') {
        return formatedFarmVaultsList.value.filter(
          (item) => item.name === 'A2B2 (ACSI)'
        );
      }
      return formatedFarmVaultsList.value;
    });

    const searchedFarmVaults = computed(() => {
      const searchedFarmVaultsValue = formatedFarmVaultsForPage.value.filter(
        (item) =>
          item.name.toLowerCase().indexOf(searchInput.value?.toLowerCase()) > -1
      );

      if (!upToLargeBreakpoint.value) {
        if (
          formatedFarmVaultsForPage.value.length !== 0 &&
          selectedVaultId.value === -1
        ) {
          setSelectedVaultId(0);
        }

        const selectedFarmVault = formatedFarmVaultsForPage.value.filter(
          (item: any) => item.id === selectedVaultId.value
        );

        if (
          selectedFarmVault.length === 0 &&
          formatedFarmVaultsForPage.value.length !== 0
        ) {
          setSelectedVaultId(formatedFarmVaultsForPage.value[0].id);
        }
      }

      return searchedFarmVaultsValue;
    });

    const filteredFarmVaults = computed(() => {
      return searchedFarmVaults.value.filter((item) => {
        if (filterKey.value === 'all') {
          return true;
        } else if (filterKey.value === 'swords') {
          return (
            !item.walletEmpty ||
            !item.vaultEmpty ||
            !item.walletFarmEmpty ||
            !item.farmEmpty ||
            !item.farmRewardsEmpty
          );
        } else if (filterKey.value === 'vaults') {
          return item.name.startsWith('ACS');
        } else if (filterKey.value === 'onsen/ido') {
          return item.name.startsWith('A2B2');
        } else if (filterKey.value === 'core') {
          return !item.name.startsWith('ACS') && !item.name.startsWith('A2B2');
        }
        return item.tags.includes(filterKey.value);
      });
    });

    const sortedFarmVaults = computed(() => {
      if (sortBy.value === '') {
        return filteredFarmVaults.value;
      } else {
        return [...filteredFarmVaults.value].sort(function (a, b) {
          const x = a[sortBy.value];
          const y = b[sortBy.value];
          const result = sortOrder.value
            ? x < y
              ? -1
              : x > y
              ? 1
              : 0
            : x < y
            ? 1
            : x > y
            ? -1
            : 0;
          return result;
        });
      }
    });

    // MOUNTED
    onBeforeMount(() => {
      filterKey.value = route.params.filterKey.toString();
    });
    onMounted(() => {
      if (props.deprecated) {
        initDeprecated();
      }

      setVaultsViewHeight();
      setSelectedVaultId(
        upToLargeBreakpoint.value
          ? -1
          : formatedFarmVaultsForPage.value.length
          ? formatedFarmVaultsForPage.value[0].id
          : -1
      );
    });

    // WATCHERS
    watch(width, () => {
      setVaultsViewHeight();
    });
    watch(menuCollapsed, () => {
      setTimeout(() => {
        setVaultsViewHeight();
      }, 1000);
    });
    watch(upToLargeBreakpoint, () => {
      setSelectedVaultId(
        upToLargeBreakpoint.value
          ? -1
          : selectedVaultId.value > -1
          ? selectedVaultId.value
          : formatedFarmVaultsForPage.value[0].id
      );
    });

    // DATA
    const searchInput = ref('');
    const filterKey = ref('all');
    const sortBy = ref('');
    const sortOrder = ref(true);
    const sortMap = ref(['name', 'apy', 'daily', 'tvl', 'balance']);

    // METHODS
    const search = (input) => {
      searchInput.value = input;
    };

    const filter = (key) => {
      router.push({
        path: `/${
          userNetworkConfig.value.shortName
        }/${routerName.value?.toString()}/${key}`,
      });
    };

    const sortUpdate = (key) => {
      if (key === sortBy.value) {
        sortOrder.value = !sortOrder.value;
      } else {
        sortBy.value = key;
        sortOrder.value = true;
      }
    };

    const setVaultsViewHeight = () => {
      if (upToLargeBreakpoint.value) {
        document.getElementById(
          'vaults_list_container'
        )!.style.height = `calc(100vh - 325px)`;
        return;
      }
      const rect = document
        .getElementById('vaults_list_container')
        ?.getBoundingClientRect();
      if (!rect) {
        return;
      }
      const y = rect.top;
      const height = `calc(100vh - ${y + 8}px)`;
      document.getElementById('vaults_list_container')!.style.height = height;
      document.getElementById('vaults_detail_container')!.style.maxHeight =
        height;
    };

    return {
      upToLargeBreakpoint,
      menuCollapsed,
      selectedVaultId,
      setSelectedVaultId,
      // data
      filterKey,
      searchInput,
      sortBy,
      sortOrder,
      sortMap,
      // computed
      routerName,
      isLoadingData,
      formatedFarmVaultsForPage,
      searchedFarmVaults,
      filteredFarmVaults,
      sortedFarmVaults,
      // methods
      plantLevel,
      setVaultsViewHeight,
      filter,
      search,
      sortUpdate,
    };
  },
});
