import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-2xl text-center my-8" }
const _hoisted_2 = { class: "text-lg text-center mb-16" }
const _hoisted_3 = { class: "flex justify-center mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: _ctx.isOpen,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
    width: "xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('confirm')), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('burn_nft_confirm_desc')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BalBtn, {
          class: "w-36 mr-4 lg:mr-8",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          color: "amber",
          outline: "",
          size: _ctx.upToLargeBreakpoint ? 'sm' : 'md',
          rounded: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_BalBtn, {
          class: "w-36",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.burnNft())),
          color: "amber",
          size: _ctx.upToLargeBreakpoint ? 'sm' : 'md',
          rounded: "",
          loading: _ctx.isBurning,
          "loading-label": _ctx.$t('burning')
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('confirm')), 1)
          ]),
          _: 1
        }, 8, ["size", "loading", "loading-label"])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}