export default [
  {
    key: 'acs',
    tokenSymbol: 'ACS',
    token: '0x4197C6EF3879a08cD51e5560da5064B773aa1d29',
    address: '0x88888888F5753904C4D42f696EBF24F277828708',
    vault: '0x7679381507af0c8DE64586A458161aa58D3A4FC3',
    vaultWeight: '0x6866f9D86E08cC7841F56cb6A0A93677F68FC014',
    baseUri: 'https://nft-data.acryptos.com',
    minStake: 8,
  },
  {
    key: 'acsi',
    tokenSymbol: 'ACSI',
    token: '0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389',
    address: '0x888888888e332eF5d4Af8FC673040346fb0fB7dF',
    vault: '0x2b66399AD01be47C5aa11C48fDd6DF689DAE929A',
    vaultWeight: '0xce6C393aFe01D051d2643E252CFfa6cf0C34891C',
    baseUri: 'https://nft-data.acryptos.com/acsi',
    minStake: 38,
  },
];
