import { ethers } from 'ethers';
import { Config } from './config/config';
import { bn, fw, np } from '@/lib/vault/bn';
import { StableSwap } from '@/lib/vault/stable-swap';
import { Pair } from '@/lib/vault/pair';
import { Blp } from '@/lib/vault/blp';
import { vaultFactoriesInfo, warningVaults } from '@/constants';
import abis from '@/constants/abis';

export const walletEmpty = (farmVault) => {
  if (
    farmVault.vault?.stats.value.userBalance &&
    !farmVault.vault.stats.value.userBalance.lten(0) &&
    !farmVault.vault.dualtoken
  ) {
    return false;
  }
  return true;
};

export const vaultEmpty = (farmVault) => {
  if (
    farmVault.vault?.stats.value.userBalanceVault &&
    !farmVault.vault.stats.value.userBalanceVault.lten(0)
  ) {
    return false;
  }
  return true;
};

export const walletFarmEmpty = (farmVault) => {
  if (
    farmVault.farm?.stats.value.userBalance &&
    !farmVault.farm.stats.value.userBalance.lten(0)
  ) {
    return false;
  }
  return true;
};

export const farmEmpty = (farmVault) => {
  if (
    farmVault.farm?.stats.value.userBalanceFarm &&
    !farmVault.farm.stats.value.userBalanceFarm.lten(0)
  ) {
    return false;
  }
  return true;
};

export const pendingEmpty = (farmVault) => {
  if (
    farmVault.farm?.stats.value.rewardPending &&
    !farmVault.farm.stats.value.rewardPending.lten(0)
  ) {
    return false;
  }
  return true;
};

export const farmRewardsEmpty = (farmVault) => {
  if (
    farmVault.farm?.masterFarm?.rewardTokenSymbol &&
    farmVault.farm?.stats.value.rewardPending
  ) {
    if (
      (farmVault.farm.masterFarm.rewardTokenSymbol == 'WAV' &&
        farmVault.farm.stats.value.rewardPending.lte(
          bn(Config.wavHarvestFee * 1e18)
        )) ||
      (farmVault.farm.masterFarm.rewardTokenSymbol == 'ACSI' &&
        farmVault.farm.stats.value.rewardPending.lte(
          bn(Config.acsiHarvestFee * 1e18)
        )) ||
      (farmVault.farm.masterFarm.rewardTokenSymbol == 'ACS' &&
        farmVault.farm.stats.value.rewardPending.lte(
          bn(Config.acsHarvestFee * 1e18)
        ))
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export const showApr = (i) => {
  return i < 10
    ? np(i, 2)
    : i < 100
    ? np(i, 1)
    : i < 1000
    ? np(i, 0)
    : i < 10000
    ? `${np(i / 1000, 2)}K`
    : i < 100000
    ? `${np(i / 1000, 1)}K`
    : i < 1000000
    ? `${np(i / 1000, 0)}K`
    : i < 10000000
    ? `${np(i / 1000000, 2)}M`
    : i < 100000000
    ? `${np(i / 1000000, 1)}M`
    : `${np(i / 1000000, 0)}M`;
};

export const updateApy = (item) => {
  if (item.deprecated) {
    return item;
  }

  const token = (item.vault && item.vault.token) || item.farm.token;
  let swapApy =
    StableSwap.stableSwapsByLpToken[token] ||
    Pair.pairs[token] ||
    Blp.blps[token];
  swapApy = swapApy && swapApy.apyDay > 0.001 && swapApy;

  // item.isBoostedApy = item.farm && item.farm.stats.value.roiDayBoosted;

  const daily =
    ((swapApy && swapApy.roiDay) || 0) +
    ((item.vault && item.vault.stats.value?.roiDay) || 0) +
    ((item.farm &&
      (item.farm.stats.value?.roiDayBoosted ||
        item.farm.stats.value?.roiDay)) ||
      0);
  item.apy = ((daily + 1) ** 365 - 1) * 100;
  item.apr = daily * 365 * 100;
  item.daily = daily * 100;
  if (item.apy) {
    item.apyShown = showApr(item.apy) + '%';
  }
  if (item.daily) {
    item.dailyShown = item.daily.toFixed(2) + '%';
  }

  if (item.farm && item.farm.stats.value.roiDayMin) {
    const dailyMin =
      ((swapApy && swapApy.roiDay) || 0) +
      ((item.vault && item.vault.stats.value.roiDay) || 0) +
      ((item.farm && item.farm.stats.value.roiDayMin) || 0);
    const dailyMax =
      ((swapApy && swapApy.roiDay) || 0) +
      ((item.vault && item.vault.stats.value.roiDay) || 0) +
      ((item.farm && item.farm.stats.value.roiDayMax) || 0);
    item.apyMin = ((dailyMin + 1) ** 365 - 1) * 100;
    item.apyMax = ((dailyMax + 1) ** 365 - 1) * 100;
    item.dailyMin = dailyMin * 100;
    item.dailyMax = dailyMax * 100;
    item.aprMin = dailyMin * 365 * 100;
    item.aprMax = dailyMax * 365 * 100;
  }

  if (swapApy && swapApy.aprDay) {
    item.swapApr = swapApy.aprDay * 100;
    item.swapApy = swapApy.apyDay * 100;
    item.swapDaily = swapApy.roiDay * 100;
  }
  if (
    item.vault &&
    item.vault.stats.value.apyDay &&
    item.vault.stats.value.apyDay > 0
  ) {
    item.vaultApr = item.vault.stats.value.aprDay * 100;
    item.vaultApy = item.vault.stats.value.apyDay * 100;
    item.vaultDaily = item.vault.stats.value.roiDay * 100;
  }
  if (item.farm && item.farm.stats.value.apyDay) {
    item.farmApy = item.farm.stats.value.apyDay * 100;
    item.farmApr = item.farm.stats.value.aprDay * 100;
    item.farmDaily = item.farm.stats.value.roiDay * 100;
    item.farmApyMin =
      item.farm.stats.value.apyDayMin && item.farm.stats.value.apyDayMin * 100;
    item.farmAprMin =
      item.farm.stats.value.aprDayMin && item.farm.stats.value.aprDayMin * 100;
    item.farmDailyMin =
      item.farm.stats.value.roiDayMin && item.farm.stats.value.roiDayMin * 100;
    item.farmApyMax =
      item.farm.stats.value.apyDayMax && item.farm.stats.value.apyDayMax * 100;
    item.farmAprMax =
      item.farm.stats.value.aprDayMax && item.farm.stats.value.aprDayMax * 100;
    item.farmDailyMax =
      item.farm.stats.value.roiDayMax && item.farm.stats.value.roiDayMax * 100;
    item.farmApyBoosted =
      item.farm.stats.value.apyDayBoosted &&
      item.farm.stats.value.apyDayBoosted * 100;
    item.farmAprBoosted =
      item.farm.stats.value.aprDayBoosted &&
      item.farm.stats.value.aprDayBoosted * 100;
    item.farmDailyBoosted =
      item.farm.stats.value.roiDayBoosted &&
      item.farm.stats.value.roiDayBoosted * 100;
  }
  return item;
};

export const updateTvl = (item) => {
  // const tvl =
  //   item.vault && !item.vault.showFarmDeprecatedOnly
  //     ? item.vault.stats.value.vaultBalance
  //     : item.farm.stats.value.farmBalance;
  const tvlUsd =
    item.vault && !item.vault.showFarmDeprecatedOnly
      ? item.vault.stats.value.vaultBalanceUsd
      : item.farm.stats.value.farmBalanceUsd;

  // calculate header tvls
  if (tvlUsd) {
    const __tvl = parseFloat(fw(tvlUsd));
    item.tvl = parseFloat(fw(tvlUsd));
    const _tvl =
      __tvl < 1000
        ? `${np(__tvl, 1)}`
        : __tvl < 100000
        ? `${np(__tvl / 1000, 1)}K`
        : __tvl < 1000000
        ? `${np(__tvl / 1000, 0)}K`
        : `${np(__tvl / 1000000, 2)}M`;
    if (_tvl) {
      item.tvlShown = '$' + _tvl;
    }

    let userBalance = bn(0);
    if (item.vault) {
      !item.vault.dualtoken &&
        item.vault.stats.value.userBalanceUsd &&
        userBalance.iadd(item.vault.stats.value.userBalanceUsd);
      item.vault.stats.value.userBalanceVaultUsd &&
        userBalance.iadd(item.vault.stats.value.userBalanceVaultUsd);
    } else {
      item.farm.stats.value.userBalanceUsd &&
        userBalance.iadd(item.farm.stats.value.userBalanceUsd);
    }
    if (item.farm) {
      item.farm.stats.value.userBalanceFarmUsd &&
        userBalance.iadd(item.farm.stats.value.userBalanceFarmUsd);
    }
    userBalance = parseFloat(fw(userBalance));
    item.balance = userBalance;
    item.balanceShown =
      userBalance <= 0
        ? '--'
        : userBalance < 1000
        ? `$${np(userBalance, 0)}`
        : userBalance < 100000
        ? `$${np(userBalance / 1000, 1)}K`
        : userBalance < 1000000
        ? `$${np(userBalance / 1000, 0)}K`
        : `$${np(userBalance / 1000000, 2)}M`;
  }

  item.rewardPending = 0;
  if (item.farm?.stats.value?.rewardPendingUsd?.gtn(0)) {
    item.rewardPending = Number.parseFloat(
      fw(
        item.farm.stats.value.rewardPendingUsd ||
          item.farm.stats.value.rewardPending
      )
    );
  }
  return item;
};

export const account = (farmVault) => {
  return (farmVault.vault || farmVault.farm)?.connect?.account || null;
};

export const initFarmVault = (item, i, t) => {
  const farmVault = {
    ...item,
    id: i,
    tokenImage: item.vault ? item.vault.tokenImage : item.farm.tokenImage,
    tokenSymbol: item.vault ? item.vault.tokenSymbol : item.farm.tokenSymbol,
    title: item.vault ? item.vault.title : item.farm.title,
    apyShown: '--',
    dailyShown: '--',
    tvlShown: '--',
    balanceShown: '--',
    walletEmpty: true,
    vaultEmpty: true,
    walletFarmEmpty: true,
    farmEmpty: true,
    farmRewardsEmpty: true,
    pendingEmpty: true,
    deprecated:
      item.vault?.showFarmDeprecatedOnly ||
      item.vault?.deprecated ||
      item.farm?.deprecated,
  };
  farmVault.name = farmVault.title || farmVault.tokenSymbol;

  if (farmVault.vault && farmVault.vault.transactionInfo) {
    farmVault.vaultTransactionInfo = farmVault.vault.transactionInfo
      .replace('Withdrawal fee', t('withdrawal_fee'))
      .replace('is non-transferrable', t('is_non_transferrable'));
  }
  if (farmVault.farm && farmVault.farm.transactionInfo) {
    farmVault.farmTransactionInfo = farmVault.farm.transactionInfo.replace(
      'Harvest fee',
      t('harvest_fee')
    );
  }
  if (farmVault.vault && farmVault.vault.walletInfo) {
    farmVault.walletInfo = farmVault.vault.walletInfo.replace(
      '>Get<',
      `>${t('get')}<`
    );
  }
  if (farmVault.farm && farmVault.farm.walletInfo) {
    farmVault.walletInfo = farmVault.farm.walletInfo.replace(
      '>Get<',
      `>${t('get')}<`
    );
  }

  return farmVault;
};

export const showTokenBalance = (i) => {
  if (!i) return;
  i = parseFloat(fw(i));
  return i > 10000
    ? i.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : i.toLocaleString(undefined, { maximumSignificantDigits: 5 });
};

export const plantLevel = (farmVault) => {
  const harvestFee =
    farmVault.farm?.masterFarm?.rewardTokenSymbol == 'ACS'
      ? Config.acsHarvestFee
      : Config.acsiHarvestFee;
  if (
    farmVault &&
    farmVault.farm &&
    farmVault.farm.stats.value &&
    farmVault.farm.stats.value.rewardPending &&
    !farmVault.farm.stats.value.rewardPending.lt(bn(harvestFee * 4 * 1e18))
  ) {
    return farmVault.farm.stats.value.rewardPending.lt(
      bn(harvestFee * 8 * 1e18)
    )
      ? 1
      : farmVault.farm.stats.value.rewardPending.lt(bn(harvestFee * 16 * 1e18))
      ? 2
      : farmVault.farm.stats.value.rewardPending.lt(bn(harvestFee * 32 * 1e18))
      ? 3
      : 4;
  }
  return 0;
};

const generateTags = (tag: string, address, tokenSymbol) => {
  const tags: string[] = [];
  tags.push(tag);
  if (tag === 'venus') {
    tags.push('venusProtocol');
  }

  //if (stableCoins.indexOf(address) !== -1) {
  //  tags.push('usd');
  //}

  if (tokenSymbol.match(/(usd|frax|dai)/gi)) {
    tags.push('usd');
  }

  return tags;
};

export const initVaultsWithFactory = async (
  wbnb,
  multicallProvider,
  chainId
) => {
  const vaultFactory = vaultFactoriesInfo.find(
    (item) => item.chainId === chainId
  );

  const configResult: any[] = [];
  const generatedResult = {};

  try {
    if (vaultFactory) {
      await Promise.all(
        vaultFactory.beacons.map(async (beacon) => {
          const vaultFactoryContract = new ethers.Contract(
            vaultFactory.address,
            abis.vaultFactory,
            multicallProvider
          );
          const proxies = await vaultFactoryContract.getAllProxiesByBeacon(
            beacon.address
          );

          await Promise.all(
            proxies.map(async (vaultAddress) => {
              const vaultConfig: any = {
                vault: {
                  address: vaultAddress,
                  isBestDayReturn: true,
                  showBorrowLimit: true,
                  vaultv2: true,
                },
              };

              if (
                beacon.warning &&
                warningVaults.find((vault) => vault === vaultAddress)
              ) {
                vaultConfig.vault.warning = beacon.warning;
              }
              if (beacon.additionalInfo) {
                vaultConfig.vault.additionalInfo = beacon.additionalInfo;
              }

              if (beacon.deprecated) {
                vaultConfig.vault.deprecated = beacon.deprecated;
              }
              if (beacon.dualtoken) {
                vaultConfig.vault.dualtoken = beacon.dualtoken;
              }

              const vaultContract = new ethers.Contract(
                vaultAddress,
                abis.vault,
                multicallProvider
              );

              let token;
              if (beacon.dualtoken) {
                token = vaultAddress;
              } else {
                token = await vaultContract.token();
              }

              if (beacon.walletInfo) {
                vaultConfig.vault.walletInfo = beacon.walletInfo;
                vaultConfig.vault.walletInfo =
                  vaultConfig.vault.walletInfo.replace('%TOKEN%', token ?? '');
                //vaultConfig.vault.warning = beacon.warning;
                //vaultConfig.vault.additionalInfo = beacon.additionalInfo;
              }

              if (wbnb === token) {
                vaultConfig.vault.isGasToken = true;
              }

              const tokenabi = beacon.dualtoken ? abis.vault : abis.erc20;
              const tokenContract = new ethers.Contract(
                token,
                tokenabi,
                multicallProvider
              );
              const setName = async () => {
                if (beacon.dualtoken) {
                  vaultConfig.vault.name = await vaultContract.symbol();
                } else {
                  vaultConfig.vault.name = await tokenContract.name();
                }
              };
              const setTokenDecimals = async () => {
                vaultConfig.vault.tokenDecimals =
                  await tokenContract.decimals();
              };
              const setTokenSymbolAndTags = async () => {
                let _tokenSymbol;
                if (beacon.dualtoken) {
                  _tokenSymbol = await vaultContract.symbol();
                  _tokenSymbol = _tokenSymbol.match(/acs\((.*)\)/)?.[1];
                } else {
                  //assumed single token /non LP
                  _tokenSymbol = await tokenContract.symbol();
                  if (_tokenSymbol.match(/(wbnb)/i)) {
                    _tokenSymbol = 'BNB';
                  }
                  if (_tokenSymbol.match(/(VERSE-X)/i)) {
                    _tokenSymbol = await vaultContract.symbol();
                    _tokenSymbol = _tokenSymbol.match(/acs\((.*)\)/)?.[1];
                  }
                }

                if (beacon.walletInfo) {
                  vaultConfig.vault.walletInfo =
                    vaultConfig.vault.walletInfo.replace(
                      '%TOKENSYMBOL%',
                      _tokenSymbol ?? ''
                    );

                  vaultConfig.vault.walletInfo =
                    vaultConfig.vault.walletInfo.replace(
                      '%VAULT%',
                      vaultAddress ?? ''
                    );
                }
                vaultConfig.vault.tokenSymbol = _tokenSymbol;

                //dirty method to get logo auto gen, refactor whenever possible
                let _tokenSymbol_svg = _tokenSymbol;
                if (_tokenSymbol.match(/(vAMM|sAMM)/)) {
                  _tokenSymbol_svg =
                    _tokenSymbol_svg.match(/(.*)-(.*)/)?.[2] ?? _tokenSymbol; //extract WKAVA/USDC from vAMM-WKAVA/USDC
                } else if (_tokenSymbol.match(/\//)) {
                  _tokenSymbol_svg =
                    _tokenSymbol_svg.match(/(.*)-(.*)/)?.[1] ?? _tokenSymbol; //extract ETH/WBNB from ETH/WBNB-500
                }

                _tokenSymbol_svg = _tokenSymbol_svg.replace('/', '-'); //replace WKAVA/USDC for WKAVA-USDC
                _tokenSymbol_svg = _tokenSymbol_svg.replace('BTCB', 'BTC');
                _tokenSymbol_svg = _tokenSymbol_svg.replace('WBNB', 'BNB');
                _tokenSymbol_svg = _tokenSymbol_svg.replace('WETH', 'ETH');
                _tokenSymbol_svg = _tokenSymbol_svg.replace('WBTC', 'BTC');

                vaultConfig.vault.tokenImage =
                  _tokenSymbol_svg.toLowerCase() + '.svg';

                vaultConfig.tags = generateTags(
                  beacon.project,
                  vaultConfig.vault.address,
                  vaultConfig.vault.tokenSymbol
                );
                if (beacon.tags) {
                  vaultConfig.tags.push(beacon.tags);
                }
              };
              await Promise.all([
                setName(),
                setTokenDecimals(),
                setTokenSymbolAndTags(),
              ]);

              configResult.push(vaultConfig);
              generatedResult[vaultAddress] = {
                strategy: vaultAddress,
                token,
              };
            })
          );
        })
      );
    }
  } catch (err) {
    console.error('Error on getting vault address.', chainId, err);
  }

  return {
    configResult,
    generatedResult,
  };
};
