import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "text-xs text-zink-500 leading-none" }
const _hoisted_3 = { class: "flex mt-4 items-center justify-between" }
const _hoisted_4 = { class: "text-right px-6 mt-4 text-smd text-zink-390 underline" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalTabs = _resolveComponent("BalTabs")!
  const _component_TradeSettingsPopover = _resolveComponent("TradeSettingsPopover")!
  const _component_InvestForm = _resolveComponent("InvestForm")!
  const _component_WithdrawForm = _resolveComponent("WithdrawForm")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    noPad: "",
    show: _ctx.isVisible,
    width: "lg",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    style: {"z-index":"39"}
  }, {
    default: _withCtx(() => [
      (_ctx.loadingPool || !_ctx.transfersAllowed || !_ctx.sorReady)
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createBlock(_component_BalCard, {
            key: 1,
            shadow: "xl",
            exposeOverflow: "",
            noBorder: ""
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.network.chainName), 1),
                _createVNode(_component_BalTabs, {
                  modelValue: _ctx.activeTab,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
                  tabs: _ctx.tabs,
                  class: "mt-4 text-md",
                  "no-pad": ""
                }, null, 8, ["modelValue", "tabs"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.activeTab === 'invest'
                  ? _ctx.$t('investInPool')
                  : _ctx.$t('withdrawFromPool')), 1),
                  _createVNode(_component_TradeSettingsPopover, {
                    context: _ctx.TradeSettingsContext.invest
                  }, null, 8, ["context"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              (_ctx.activeTab === 'invest')
                ? (_openBlock(), _createBlock(_component_InvestForm, {
                    key: 0,
                    pool: _ctx.pool
                  }, null, 8, ["pool"]))
                : _createCommentVNode("", true),
              (_ctx.activeTab === 'withdraw')
                ? (_openBlock(), _createBlock(_component_WithdrawForm, {
                    key: 1,
                    pool: _ctx.pool
                  }, null, 8, ["pool"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("a", {
                  target: "_blank",
                  href: _ctx.EXTERNAL_LINKS.AcsiFinance.Pool(_ctx.pool.id)
                }, _toDisplayString(_ctx.$t('powered_by_acsi_finance')), 9, _hoisted_5)
              ])
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["show"]))
}