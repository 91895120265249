
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { supportedNetworks } from '@/constants';
import useNetwork from '@/composables/useNetwork';

export default defineComponent({
  emits: ['close'],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const router = useRouter();
    const { networkId } = useNetwork();

    // COMPUTED
    const selectedNetwork = computed(() =>
      supportedNetworks.find((network) => networkId.value === network.chainId)
    );

    // METHOD
    const switchToBnb = () => {
      const fromPath = router.currentRoute.value.fullPath;
      const toPath = `#/BNB/${fromPath.split('/').slice(2).join('/')}`;
      window.location.replace(toPath);
      window.location.reload();
    };

    return {
      // computed
      selectedNetwork,
      // method
      switchToBnb,
    };
  },
});
