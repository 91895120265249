const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x85518cb62E1278D4F1b5a94DE8530f235eAB04DE',
  wbnb: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b', // WKAVA
  // busd: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802', // USDC
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

export { Config };

Config.farmVaults = [
  {
    vault: {
      address: '0x53a5dD07127739e5038cE81eff24ec503A6CC479',
      title: 'ACryptoS Liquid veVARA (acsVARA)',
      tokenSymbol: 'VARA',
      tokenImage: 'vara.svg',
      isLiquid: true,
      isBestDayReturn: true,
      //defaultRoiDay: 0.0318 / 365,
    },
    tags: ['equilibre', 'liquidstaking'],
  },
];
