const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0xE295aD71242373C37C5FdA7B57F26f9eA1088AFe',
  // wbnb: '0x24Dc3617963E0422C826bC7705B378Ab43da517A', // WETH
  // busd: '0x4Fabb145d64652a948d72533023f6E7A623C7C53', // BUSD
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

/*
Config.farmVaults = [
  {
    vault: {
      address: '0x016F4eb10196D247AE5eb2C9d6c2562D7Db7E61C',
      tokenSymbol: 'USX',
      tokenImage: 'usx.svg',
      isBestDayReturn: true,
      fromBlock: 33831279,
      isGasToken: true
    },
    tags: ['dforce']
  },
  {
    vault: {
      address: '0x276467500aEa9a9734F55C3B5b7De8A8299b45B0',
      tokenSymbol: 'sUSD',
      tokenImage: 'susd.svg',
      isBestDayReturn: true,
      fromBlock: 33897551,
      isGasToken: true
    },
    tags: ['sonne']
  },
  {
    vault: {
      address: '0x206298d634Be9D9BC6497d3a83B6753b4e92F945',
      tokenSymbol: 'SNX',
      tokenImage: 'snx.svg',
      isBestDayReturn: true,
      fromBlock: 33894736,
      isGasToken: true
    },
    tags: ['sonne']
  },
  {
    vault: {
      address: '0x19232a84442b25892906e24dFf91fDe494E2FA3a',
      tokenSymbol: 'OP',
      tokenImage: 'op.svg',
      isBestDayReturn: true,
      fromBlock: 33895261,
      isGasToken: true
    },
    tags: ['sonne']
  }
];
*/
export { Config };
