import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b9d5941"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "ml-2" }
const _hoisted_3 = { class: "switch-network-con" }
const _hoisted_4 = { class: "switch-network-popup-title" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "ml-1 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalPopover = _resolveComponent("BalPopover")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BalPopover, {
      align: "right",
      "no-pad": "",
      onShow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.popoverOpened = !_ctx.popoverOpened)),
      onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.popoverOpened = !_ctx.popoverOpened)),
      rounded: "top-left-to-bottom-right",
      noBorder: ""
    }, {
      activator: _withCtx(() => [
        _createVNode(_component_BalBtn, {
          color: "zink",
          rounded: "",
          outline: "",
          size: _ctx.upToLargeBreakpoint ? 'xs' : 'sm',
          class: _normalizeClass(["network-btn", [
            { 'network-btn-active': _ctx.isNetworkReady },
            { 'text-zink-important': !_ctx.showTitle },
          ]])
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.iconSrc(_ctx.selectedNetwork),
              alt: _ctx.selectedNetwork.name,
              class: _normalizeClass(["network-btn-icon", [{ 'network-btn-icon-active': _ctx.isNetworkReady }]])
            }, null, 10, _hoisted_1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selectedNetwork.label), 1),
            _createVNode(_component_BalIcon, {
              name: "akar-icons:chevron-down",
              size: _ctx.upToLargeBreakpoint ? '2xs' : 'xs',
              class: _normalizeClass(["network-btn-extend-icon", { 'transform rotate-180': _ctx.popoverOpened }])
            }, null, 8, ["size", "class"])
          ]),
          _: 1
        }, 8, ["size", "class"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('network')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportedNetworks, (network) => {
            return (_openBlock(), _createElementBlock("div", {
              key: network.chainId,
              onClick: ($event: any) => (_ctx.switchNetwork(network)),
              class: "switch-network-item"
            }, [
              _createElementVNode("img", {
                src: _ctx.iconSrc(network),
                alt: network.name,
                class: "switch-network-item-icon"
              }, null, 8, _hoisted_6),
              _createElementVNode("span", _hoisted_7, _toDisplayString(network.label), 1)
            ], 8, _hoisted_5))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}