import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/sort.svg'


const _withScopeId = n => (_pushScopeId("data-v-39cbbe9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex justify-start mb-4"
}
const _hoisted_2 = {
  key: 0,
  class: "flex justify-end mb-4"
}
const _hoisted_3 = {
  key: 0,
  class: "mt-4 mb-2 grid grid-cols-5 gap-6 pt-4"
}
const _hoisted_4 = { class: "sort" }
const _hoisted_5 = { class: "sort-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "sort-label" }
const _hoisted_8 = {
  key: 0,
  class: "sort-img",
  src: _imports_0
}
const _hoisted_9 = { class: "grid grid-cols-5 gap-6" }
const _hoisted_10 = { id: "vaults_list_container" }
const _hoisted_11 = {
  key: 1,
  class: "lg:pr-6"
}
const _hoisted_12 = {
  key: 0,
  class: "notice-container"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "w-full" }
const _hoisted_15 = {
  key: 0,
  class: "vault-item-mobile-tags"
}
const _hoisted_16 = {
  key: 0,
  class: "crown"
}
const _hoisted_17 = { class: "vault" }
const _hoisted_18 = { class: "col-span-2 items-center flex justify-between" }
const _hoisted_19 = { class: "flex items-center" }
const _hoisted_20 = ["src"]
const _hoisted_21 = {
  key: 0,
  class: "crown mr-2"
}
const _hoisted_22 = {
  key: 0,
  class: "col-span-2 items-center flex"
}
const _hoisted_23 = { class: "vault-item-value" }
const _hoisted_24 = { class: "vault-item-value" }
const _hoisted_25 = { class: "vault-item-value" }
const _hoisted_26 = ["src"]
const _hoisted_27 = {
  key: 0,
  class: "height-fit mobile-vault-detail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppXNoticeBoxVue = _resolveComponent("AppXNoticeBoxVue")!
  const _component_V1Switch = _resolveComponent("V1Switch")!
  const _component_NetworkSwitch = _resolveComponent("NetworkSwitch")!
  const _component_AppNavActions = _resolveComponent("AppNavActions")!
  const _component_VaultsTopNav = _resolveComponent("VaultsTopNav")!
  const _component_VaultsFilterWithSearch = _resolveComponent("VaultsFilterWithSearch")!
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Tags = _resolveComponent("Tags")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_VaultsDetail = _resolveComponent("VaultsDetail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId === -1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_AppXNoticeBoxVue)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId === -1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_V1Switch),
              _createVNode(_component_NetworkSwitch, { class: "mr-2" }),
              _createVNode(_component_AppNavActions)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        (
        !_ctx.upToLargeBreakpoint || (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId === -1)
      )
          ? (_openBlock(), _createBlock(_component_VaultsTopNav, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        (
        !_ctx.upToLargeBreakpoint || (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId === -1)
      )
          ? (_openBlock(), _createBlock(_component_VaultsFilterWithSearch, {
              key: 0,
              onSearch: _ctx.search,
              filterKey: _ctx.filterKey,
              routerName: _ctx.routerName,
              onFilter: _ctx.filter
            }, null, 8, ["onSearch", "filterKey", "routerName", "onFilter"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        (
        !_ctx.upToLargeBreakpoint || (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId === -1)
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortMap, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["items-center flex justify-between", item === 'name' ? 'col-span-2 lg:col-span-4' : 'col-span-1'])
                    }, [
                      _createElementVNode("div", {
                        class: "items-center flex cursor-pointer",
                        onClick: ($event: any) => (_ctx.sortUpdate(item))
                      }, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(item)), 1),
                        (!_ctx.upToLargeBreakpoint)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_6),
                      (item === 'balance')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sortUpdate('rewardPending'))),
                            class: "plant-sort",
                            src: "/images/plants/plant4.png"
                          }))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_ctx.isLoadingData)
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-full",
                  rounded: "2xl"
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.deprecated)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("span", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t('notice_deprecated')) + " ", 1),
                          _createVNode(_component_router_link, {
                            class: "text-blue-600",
                            to: "../vaults/all"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('stake_in_current')), 1)
                            ]),
                            _: 1
                          }),
                          _createTextVNode(".")
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedFarmVaults, (farmVault, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: i,
                      class: _normalizeClass(["vault-container", [
              _ctx.selectedVaultId === farmVault.id
                ? 'vault-selected'
                : 'vault-unselected',
              { 'mt-2': i !== 0 },
            ]]),
                      onClick: ($event: any) => (_ctx.setSelectedVaultId(farmVault.id))
                    }, [
                      _createElementVNode("div", _hoisted_14, [
                        (_ctx.upToLargeBreakpoint)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createVNode(_component_Tags, {
                                farmVaultTags: farmVault.tags
                              }, null, 8, ["farmVaultTags"]),
                              (farmVault.name === 'A2B2 (ACSI)')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                                    _createVNode(_component_BalIcon, {
                                      name: "jam:crown",
                                      size: "2xs"
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("img", {
                                src: 
                        farmVault.tokenImage
                          ? '/images/tokens/' + farmVault.tokenImage
                          : '/images/tokens/' +
                            farmVault.tokenSymbol.toLowerCase() +
                            '.svg'
                      ,
                                class: "h-6 w-6 lg:h-10 lg:w-10 mr-2 lg:mr-4",
                                onError: _cache[1] || (_cache[1] = ($event: any) => (
                        $event.target.src = '/images/tokens/temp-token.png'
                      ))
                              }, null, 40, _hoisted_20),
                              _createElementVNode("span", null, _toDisplayString(farmVault.name), 1)
                            ]),
                            (
                      !_ctx.upToLargeBreakpoint && farmVault.name === 'A2B2 (ACSI)'
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                  _createVNode(_component_BalIcon, {
                                    name: "jam:crown",
                                    size: "xs"
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          (!_ctx.upToLargeBreakpoint)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                _createVNode(_component_Tags, {
                                  farmVaultTags: farmVault.tags
                                }, null, 8, ["farmVaultTags"])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_23, _toDisplayString(farmVault.apyShown), 1),
                          _createElementVNode("div", _hoisted_24, _toDisplayString(farmVault.dailyShown), 1),
                          _createElementVNode("div", _hoisted_25, _toDisplayString(farmVault.tvlShown), 1),
                          _createElementVNode("div", {
                            class: _normalizeClass(["vault-item-value", 
                    farmVault.farm && !farmVault.vaultEmpty
                      ? 'text-amber-550'
                      : !farmVault.walletEmpty || !farmVault.walletFarmEmpty
                      ? 'text-blue-520 dark:text-emerald-490'
                      : ''
                  ])
                          }, [
                            _createElementVNode("span", null, _toDisplayString(farmVault.balanceShown), 1),
                            (_ctx.plantLevel(farmVault))
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "vault-plant",
                                  src: `/images/plants/plant${_ctx.plantLevel(farmVault)}.png`
                                }, null, 8, _hoisted_26))
                              : _createCommentVNode("", true)
                          ], 2)
                        ])
                      ])
                    ], 10, _hoisted_13))
                  }), 128))
                ]))
          ]),
          _withDirectives(_createElementVNode("div", {
            id: "vaults_detail_container",
            class: _normalizeClass({ 'height-fit': !_ctx.isLoadingData })
          }, [
            _createVNode(_component_VaultsDetail, {
              farmVault: 
            _ctx.formatedFarmVaultsForPage.find(
              (item) => item.id === _ctx.selectedVaultId
            )
          ,
              isLoadingData: _ctx.isLoadingData
            }, null, 8, ["farmVault", "isLoadingData"])
          ], 2), [
            [_vShow, !_ctx.upToLargeBreakpoint]
          ])
        ], 512), [
          [_vShow, 
        !_ctx.upToLargeBreakpoint || (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId === -1)
      ]
        ])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity-transition" }, {
      default: _withCtx(() => [
        (_ctx.upToLargeBreakpoint && _ctx.selectedVaultId !== -1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _createVNode(_component_VaultsDetail, {
                farmVault: 
          _ctx.formatedFarmVaultsForPage.find((item) => item.id === _ctx.selectedVaultId)
        ,
                isLoadingData: _ctx.isLoadingData
              }, null, 8, ["farmVault", "isLoadingData"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}