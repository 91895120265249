
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';
import { showNavTitle } from '@/lib/utils';
import useNetwork from '@/composables/useNetwork';
import { supportedNetworks } from '@/constants';

interface Network {
  id: string;
  label: string;
  value: string;
  chainId: number;
  logo: string;
}

export default defineComponent({
  name: 'AppNavNetworkSelect',

  setup() {
    const router = useRouter();
    const { isNetworkReady } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { networkId } = useNetwork();

    // COMPUTED
    const showTitle = computed(() =>
      showNavTitle(router.currentRoute.value.name)
    );

    // DATA
    const popoverOpened = ref(false);

    // const appNetworkSupported = networks
    //   .map(network => network.chainId)
    //   .includes(configService.network.chainId);

    const selectedNetwork = computed(() =>
      supportedNetworks.find((network) => networkId.value === network.chainId)
    );

    // METHODS
    function iconSrc(network: Network): string {
      return `/images/networks/${network.logo}`;
    }

    function switchNetwork(network: Network) {
      const fromPath = router.currentRoute.value.fullPath;
      const toPath = `#/${network.value}/${fromPath
        .split('/')
        .slice(2)
        .join('/')}`;
      window.location.replace(toPath);
      window.location.reload();
    }

    return {
      isNetworkReady,
      upToLargeBreakpoint,
      // data
      supportedNetworks,
      popoverOpened,
      // computed
      selectedNetwork,
      showTitle,
      // methods
      switchNetwork,
      iconSrc,
    };
  },
});
