
import { defineComponent } from 'vue';
import useDarkMode from '@/composables/useDarkMode';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'DarkModeToggle',

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();
    const { darkMode, toggleDarkMode } = useDarkMode();

    return {
      darkMode,
      toggleDarkMode,
      upToLargeBreakpoint,
    };
  },
});
