const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0xBb522440bFffd563D8EE07d38Ce1b7d0f20deEFe',
  wbnb: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B', // CANTO
  // busd: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802', // USDC
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

export { Config };

Config.farmVaults = [
  {
    vault: {
      address: '0x53a5dD07127739e5038cE81eff24ec503A6CC479',
      title: 'ACryptoS Liquid veFLOW (acsFLOW)',
      tokenSymbol: 'FLOW',
      tokenImage: 'flow.svg',
      isLiquid: true,
      isBestDayReturn: true,
      //defaultRoiDay: 0.0318 / 365,
    },
    tags: ['velocimeter', 'liquidstaking'],
  },
];
