
import { defineComponent, onBeforeMount, ref } from 'vue';
import { getTotalTVL } from '@/services/useTVL';
import useVaults from '@/services/useVaults';
import { fwp } from '@/lib/utils/bn';

export default defineComponent({
  components: {},

  setup() {
    const { acsStats } = useVaults();
    const totalTVL = ref<number>(0);

    function getTotal(): number {
      return totalTVL.value;
    }

    onBeforeMount(async () => {
      totalTVL.value = await getTotalTVL();
    });

    return {
      fwp,
      getTotal,
      acsStats,
    };
  },
});
