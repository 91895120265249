import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/swords.svg'
import _imports_1 from '@/assets/icons/swords-gray.svg'


const _withScopeId = n => (_pushScopeId("data-v-79f365d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-filter-container" }
const _hoisted_2 = { class: "search-container" }
const _hoisted_3 = {
  key: 0,
  class: "filter-group-label"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "h-4 w-4",
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  class: "h-4 w-4",
  src: _imports_1
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BalIcon, {
        name: "bx:bx-search",
        size: "smd",
        class: "search-icon"
      }),
      _withDirectives(_createElementVNode("input", {
        class: "search-input",
        placeholder: "Search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event))
      }, null, 512), [
        [_vModelText, _ctx.searchInput]
      ]),
      (_ctx.searchInput)
        ? (_openBlock(), _createBlock(_component_BalIcon, {
            key: 0,
            name: "gridicons:cross-small",
            size: "smd",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchInput = '')),
            class: "clear-search-icon"
          }))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterListForPage, (it, ind) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: ind }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(it.lists, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["mt-2", index === it.lists.length - 1 ? 'mr-4' : 'mr-2']),
            key: index
          }, [
            (!index)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(it.key === 'general' ? ' ' : _ctx.$t(it.label)), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_BalBtn, {
              outline: _ctx.filterKey !== item.toLowerCase(),
              color: _ctx.filterKey === item.toLowerCase() ? 'yellow' : 'zink',
              size: "sm",
              onClick: ($event: any) => (_ctx.$emit('filter', item.toLowerCase())),
              rounded: "",
              class: "filter-button"
            }, {
              default: _withCtx(() => [
                (item.toLowerCase() === 'swords')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      (_ctx.darkMode || _ctx.filterKey === 'swords')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                        : (_openBlock(), _createElementBlock("img", _hoisted_6))
                    ]))
                  : (item.toLowerCase() === 'wav')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "🌊"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item), 1))
              ]),
              _: 2
            }, 1032, ["outline", "color", "onClick"])
          ], 2))
        }), 128))
      ], 64))
    }), 128))
  ]))
}