import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdb36e28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nft-viewer-type-owner" }
const _hoisted_2 = { class: "nft-viewer-type" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "nft-viewer-type-label" }
const _hoisted_5 = { class: "nft-viewer-owner" }
const _hoisted_6 = { class: "nft-viewer-owner-label" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.nft && _ctx.nft.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.nft.image,
            class: "nft-viewer-avatar"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 1,
            rounded: "2xl",
            class: "nft-viewer-avatar"
          })),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.route.params.key === 'acs' ? _ctx.$t('acryptos_nft') : _ctx.$t('acryptosi_nft')), 1),
      _createVNode(_component_BalIcon, {
        name: "bi:patch-check-fill",
        class: "text-blue-390",
        size: _ctx.upToLargeBreakpoint ? 'xs' : 'xl'
      }, null, 8, ["size"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BalIcon, {
        name: "bi:person-circle",
        size: _ctx.upToLargeBreakpoint ? 'lg' : '2xl'
      }, null, 8, ["size"]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('owned_by')), 1),
      (_ctx.nft && _ctx.nft.owner)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "nft-viewer-owner-value",
            href: _ctx.explorerLinks.addressLink(_ctx.nft.owner),
            target: "_blank"
          }, _toDisplayString(_ctx.nft.owner), 9, _hoisted_7))
        : (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 1,
            rounded: "md",
            class: "nft-viewer-owner-value"
          }))
    ])
  ]))
}