
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();

    // COMPUTED
    const isNftPage = computed(() => {
      return (
        router.currentRoute.value.name === 'stake_mint' ||
        router.currentRoute.value.name === 'leaderboard' ||
        router.currentRoute.value.name === 'my_nfts'
      );
    });

    // METHOD
    const gotoAppX = () => {
      window.location.replace('https://app.acryptos.com/');
    };

    return {
      // computed
      gotoAppX,
      isNftPage,
    };
  },
});
