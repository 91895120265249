import { ref } from 'vue';
import { ethers } from 'ethers';

import abis from '@/constants/abis';
import { configsList, configsGeneratedList } from './config/index';
import { Vault } from './vault.js';
import { Farm } from './farm.js';
import { Pair } from './pair.ts';
import { Blp } from './blp.js';
import { StableSwap } from './stable-swap.js';
import { Poller } from './poller.js';
import { quoter } from './quoter';
import { bn, fw, fwp } from './bn.js';
import { initVaultsWithFactory } from './farmVaultUtils';

class VaultsController extends EventTarget {
  constructor() {
    super();
  }

  async resolve(connect) {
    this.connect = connect;
    await this.init();
  }

  farmVaults = ref([]);
  farmVaultsDeprecated = ref([]);

  async init() {
    this.config = configsList[this.connect.networkConfig.chainId];
    this.configGenerated =
      configsGeneratedList[this.connect.networkConfig.chainId];

    const { configResult, generatedResult } = await initVaultsWithFactory(
      this.config.wbnb,
      this.connect.multicallProvider,
      this.connect.networkConfig.chainId
    );

    this.config.farmVaults = [
      ...configResult,
      ...(this.config.farmVaults || []),
    ];
    this.configGenerated = {
      ...(this.configGenerated || {}),
      vaults: {
        ...(this.configGenerated?.vaults || {}),
        ...generatedResult,
      },
    };

    this.connect.poller = new Poller(this.connect.multicallProvider);

    const initBlockNumber = parseInt(
      await this.connect.multicallProvider.getBlockNumber()
    );
    console.log('initBlockNumber', initBlockNumber);

    const daysAgo = 3;
    this.connect.agoBlockNumber = parseInt(
      initBlockNumber - (daysAgo * 86400) / this.connect.networkConfig.blockTime
    );
    const daysAgoShort = 1;
    this.connect.agoBlockNumberShort = parseInt(
      initBlockNumber -
        (daysAgoShort * 86400) / this.connect.networkConfig.blockTime
    );

    Object.entries(
      // this.deprecated ? this.configGenerated.pairsDeprecated : this.configGenerated.pairs
      this.configGenerated.pairs || {}
    ).forEach(
      ([address, pair]) =>
        new Pair(
          {
            ...pair,
            address,
            fromBlock: this.config.contractsFromBlock[address],
          },
          this.connect
        )
    );
    Object.entries(this.configGenerated.pairsDeprecated || {}).forEach(
      ([address, pair]) =>
        new Pair(
          {
            ...pair,
            address,
            fromBlock: this.config.contractsFromBlock[address],
          },
          this.connect
        )
    );

    if (this.connect.networkConfig.chainId === 56) {
      Object.entries(this.configGenerated.blps || {}).forEach(
        ([address, blp]) =>
          new Blp(
            {
              ...blp,
              address,
              fromBlock: this.config.contractsFromBlock[address],
            },
            this.connect
          )
      );
      Object.entries(this.configGenerated.blpsDeprecated || {}).forEach(
        ([address, blp]) =>
          new Blp(
            {
              ...blp,
              address,
              fromBlock: this.config.contractsFromBlock[address],
            },
            this.connect
          )
      );
    }
    this.farmVaults.value = this.config.farmVaults.map((i) => {
      const farmVault = i;
      if (i.farm) {
        i.farm.token ||= i.vault && i.vault.address;
        farmVault.farm = new Farm(i.farm, this.connect);
      }

      if (i.vault) {
        i.vault.token ||=
          this.configGenerated.vaults[i.vault.address]?.token ||
          i.vault.stakeToken;
        i.vault.strategy ||=
          this.configGenerated.vaults[i.vault.address]?.strategy || '';
        farmVault.vault = new Vault(
          i.vault,
          this.connect,
          this.connect.networkConfig
        );
      }

      if (farmVault.farm && farmVault.vault) {
        farmVault.farm.vault = farmVault.vault;
        farmVault.vault.farm = farmVault.farm;
      }

      return farmVault;
    });

    if (this.connect.networkConfig.chainId === 56) {
      // if (!this.deprecated) {
      Farm.acsVault = this.farmVaults.value.find(
        (farmVault) => farmVault.vault && farmVault.vault.tokenSymbol == 'ACS'
      )?.vault;
      Farm.acsiVault = this.farmVaults.value.find(
        (farmVault) => farmVault.vault && farmVault.vault.tokenSymbol == 'ACSI'
      )?.vault;
      Farm.wavVault = this.farmVaults.value.find(
        (farmVault) => farmVault.vault && farmVault.vault.title == 'TGW Vault'
      )?.vault;
      // }
      // to get stableswap prices
      this.config.stableSwaps.forEach((i) => new StableSwap(i, this.connect));

      this.contractAcs = new ethers.Contract(
        this.config.acs,
        abis.erc20,
        this.connect.multicallProvider
      );
      this.contractAcsi = new ethers.Contract(
        this.config.acsi,
        abis.erc20,
        this.connect.multicallProvider
      );
      this.connect.poller.add({
        method: () => this.contractAcs.totalSupply(),
        cb: (i) => (this.acsSupply = bn(i)),
      });
      this.connect.poller.add({
        method: () => this.contractAcsi.totalSupply(),
        cb: (i) => (this.acsiSupply = bn(i)),
      });
    }

    // if (!this.deprecated) {
    this.acsStats = {
      tvl: bn(0),
      userTvl: '',
    };

    this.connect.poller.addEventListener('poll', () => {
      this.acsStats.tvl = this.getStats()[0];
      this.acsStats.userTvl = this.getStats()[1];
      this.acsStats.acsTotalHarvestPending = this.getStats()[2];
      this.acsStats.acsTotalHarvestPendingUsd = this.getStats()[3];
      this.acsStats.acsiTotalHarvestPending = this.getStats()[4];
      this.acsStats.acsiTotalHarvestPendingUsd = this.getStats()[5];

      this.acsStats.acsSupply = fwp(this.acsSupply || '0', 0);
      this.acsStats.acsiSupply = fwp(this.acsiSupply || '0', 0);

      if (
        quoter.q(this.config.acs, this.config.wbnb) &&
        quoter.q(this.config.wbnb, this.config.busd)
      ) {
        const acsPriceUsd = quoter
          .q(this.config.acs, this.config.wbnb)
          .mul(quoter.q(this.config.wbnb, this.config.busd))
          .div(bn(1e18));
        const acsiPriceUsd = quoter
          .q(this.config.acsi, this.config.wbnb)
          .mul(quoter.q(this.config.wbnb, this.config.busd))
          .div(bn(1e18));

        this.acsStats.acsPriceUsd = fwp(acsPriceUsd, 3);
        this.acsStats.acsPriceBNB = fwp(
          quoter.q(this.config.acs, this.config.wbnb),
          4
        );

        this.acsStats.acsiPriceUsd = fwp(acsiPriceUsd, 3);
        this.acsStats.acsiPriceBNB = fwp(
          quoter.q(this.config.acsi, this.config.wbnb),
          4
        );

        this.acsStats.acsMcap = fwp(
          this.acsSupply.mul(acsPriceUsd).div(bn(1e18)),
          0
        );
        this.acsStats.acsiMcap = fwp(
          this.acsiSupply.mul(acsiPriceUsd).div(bn(1e18)),
          0
        );
      }
      this.dispatchEvent(new CustomEvent('update'));
    });
    // }

    console.log('poll');
    this.connect.poller.poll().catch((e) => {
      console.error(e);
    });
    setInterval(() => {
      console.log('poll');
      this.connect.poller.poll().catch((e) => {
        console.error(e);
      });
    }, 28888);

    if (Farm.acsVault?.stats?.vaultBalance?.gt(bn(1e18).muln(1000))) {
      const postDefistationTimestampHour = parseInt(
        Date.now() / (60 * 60 * 1000)
      );
      if (
        parseInt(localStorage.getItem('postDefistationTimestampHour')) !=
        postDefistationTimestampHour
      ) {
        localStorage.setItem(
          'postDefistationTimestampHour',
          postDefistationTimestampHour
        );
        // console.log('postDefistation', postDefistationTimestampHour);
        // limit this to trigger on >1000 acsACS only
        await this.postDefistation();
      }
    }
  }

  async initDeprecated() {
    this.initDeprecated = () => {};

    this.farmVaultsDeprecated.value = (
      this.config.farmVaultsDeprecated || []
    ).map((i) => {
      const farmVault = i;
      if (i.farm) {
        i.farm.token ||= i.vault && i.vault.address;
        farmVault.farm = new Farm(i.farm, this.connect);
      }
      if (i.vault) {
        i.vault.token ||= this.configGenerated.vaults[i.vault.address].token;
        i.vault.strategy ||=
          this.configGenerated.vaults[i.vault.address].strategy;
        farmVault.vault = new Vault(
          i.vault,
          this.connect,
          this.connect.networkConfig
        );
      }
      if (farmVault.farm && farmVault.vault) {
        farmVault.farm.vault = farmVault.vault;
        farmVault.vault.farm = farmVault.farm;
      }

      return farmVault;
    });
  }

  getStats() {
    const tvl = bn(0);
    const usertvl = bn(0);
    const acsTotalHarvestPending = bn(0);
    const acsTotalHarvestPendingUsd = bn(0);
    const acsiTotalHarvestPending = bn(0);
    const acsiTotalHarvestPendingUsd = bn(0);

    this.farmVaults.value.forEach((farmVault) => {
      if (farmVault.vault && farmVault.vault.stats.value.vaultBalanceUsd) {
        tvl.iadd(farmVault.vault.stats.value.vaultBalanceUsd);
      } else if (farmVault.farm && farmVault.farm.stats.value.farmBalanceUsd) {
        tvl.iadd(farmVault.farm.stats.value.farmBalanceUsd);
      }

      if (farmVault.farm && farmVault.farm.stats.value.userBalanceFarmUsd) {
        usertvl.iadd(farmVault.farm.stats.value.userBalanceFarmUsd);
      }
      if (farmVault.vault && farmVault.vault.stats.value.userBalanceVaultUsd) {
        usertvl.iadd(farmVault.vault.stats.value.userBalanceVaultUsd);
      }
      if (farmVault.vault?.stats.value.totalStakeInNftUsd) {
        usertvl.iadd(farmVault.vault.stats.value.totalStakeInNftUsd);
      }

      if (farmVault.farm && farmVault.farm.stats.value.rewardPending) {
        if (farmVault.farm.masterFarm?.rewardTokenSymbol === 'ACS') {
          acsTotalHarvestPending.iadd(
            farmVault.farm.stats.value.rewardPending || bn(0)
          );
          acsTotalHarvestPendingUsd.iadd(
            farmVault.farm.stats.value.rewardPendingUsd || bn(0)
          );
        }
        if (farmVault.farm.masterFarm?.rewardTokenSymbol === 'ACSI') {
          acsiTotalHarvestPending.iadd(
            farmVault.farm.stats.value.rewardPending || bn(0)
          );
          acsiTotalHarvestPendingUsd.iadd(
            farmVault.farm.stats.value.rewardPendingUsd || bn(0)
          );
        }
      }
    });
    return [
      tvl,
      usertvl,
      acsTotalHarvestPending,
      acsTotalHarvestPendingUsd,
      acsiTotalHarvestPending,
      acsiTotalHarvestPendingUsd,
    ];
  }

  async postDefistation() {
    // console.log('postDefistation',)
    const data = { data: {} };
    const tvl = bn(0);
    const tbl = bn(0);
    this.farmVaults.value.forEach((farmVault) => {
      if (farmVault.vault && farmVault.vault.stats.value.vaultBalanceUsd) {
        tvl.iadd(farmVault.vault.stats.value.vaultBalanceUsd);

        let bnbLocked = bn(0);
        if (farmVault.vault.tokenSymbol.endsWith('-BNB')) {
          bnbLocked = farmVault.vault.stats.value.vaultBalanceBnb.divn(2);
        }
        if (farmVault.vault.tokenSymbol == 'BNB') {
          bnbLocked = farmVault.vault.stats.value.vaultBalanceBnb;
        }

        tbl.iadd(bnbLocked);

        data.data[farmVault.vault.tokenSymbol + ' Vault'] = {
          address: farmVault.vault.address,
          token: farmVault.vault.token,
          tokenSymbol: farmVault.vault.tokenSymbol,
          tvl: fw(farmVault.vault.stats.value.vaultBalanceUsd),
          bnbLocked: fw(bnbLocked),
        };
      } else if (farmVault.farm && farmVault.farm.stats.value.farmBalanceUsd) {
        tvl.iadd(farmVault.farm.stats.value.farmBalanceUsd);

        let bnbLocked = bn(0);
        if (farmVault.farm.tokenSymbol.endsWith('-BNB')) {
          bnbLocked = farmVault.farm.stats.value.farmBalanceBnb.divn(2);
        }
        if (farmVault.farm.tokenSymbol == 'BNB') {
          bnbLocked = farmVault.farm.stats.value.farmBalanceBnb;
        }

        tbl.iadd(bnbLocked);

        data.data[farmVault.farm.tokenSymbol + ' Farm'] = {
          address: farmVault.farm.master,
          token: farmVault.farm.token,
          tokenSymbol: farmVault.farm.tokenSymbol,
          tvl: fw(farmVault.farm.stats.value.farmBalanceUsd),
          bnbLocked: fw(bnbLocked),
        };
      }
    });
    data.tvl = parseFloat(fw(tvl));
    data.bnb = parseFloat(fw(tbl));
    // console.log(JSON.stringify(data))

    await fetch('https://api.defistation.io/dataProvider/tvl', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          'ACryptoS:b2b9984e-b98b-4235-bb10-5bd67fa2aa7a'
        )}`,
      },
      body: JSON.stringify(data),
    });
  }
}

export { VaultsController };
