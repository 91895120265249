
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'SetLocale',

  setup() {
    const store = useStore();
    const { upToLargeBreakpoint } = useBreakpoints();

    // COMPUTED
    const appLocale = computed(() => store.state.app.locale);

    // DATA
    const showLocaleMenu = ref(false);
    const locales = ref({
      'en-US': 'English',
      'zh-CN': '简体中文',
      'vi-VN': 'Tiếng Việt',
      'es-ES': 'Español',
      'de-DE': 'Deutsch',
      'tr-TR': 'Türk',
      'it-IT': 'Italiano',
      'pt-PT': 'Português',
      'fr-FR': 'Français',
      // 'ru-RU': 'Россия',
      // 'ko-KR': '한국어',
      // 'ja-JP': '日本語',
      // 'hi-IN': 'हिंदी',
      // 'ar-AE': 'عربى'
    });

    // METHODS
    const setLocale = (locale) => {
      store.commit('app/setLocale', locale);
      showLocaleMenu.value = false;
    };

    return {
      upToLargeBreakpoint,
      // data
      locales,
      showLocaleMenu,
      // computed
      appLocale,
      //methods
      setLocale,
    };
  },
});
