import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.additionalPoolRewardsSorted?.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('farm')) + " ACSI ", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalPoolRewardsSorted, (additionalPoolReward, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createTextVNode(" + " + _toDisplayString(additionalPoolReward.tokenSymbol), 1)
            ], 64))
          }), 128))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.farmVault.vault)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (
            _ctx.farmVault.vault.isPriceUsd ||
            !_ctx.farmVault.vault.tokenSymbol.includes('-')
          )
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('autocompound')) + " " + _toDisplayString(_ctx.farmVault.vault.tokenSymbol), 1)
                    ], 64))
                  : (_ctx.farmVault.vault.tokenSymbol.includes('-'))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('autocompound')) + " " + _toDisplayString(_ctx.farmVault.vault.tokenSymbol) + " LP", 1)
                      ], 64))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.farmVault.vault && _ctx.farmVault.farm)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(", ")
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.farmVault.farm)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('farm')) + " " + _toDisplayString(_ctx.farmVault.farm.master === _ctx.Config.wavFarm
            ? 'WAV'
            : _ctx.farmVault.farm.master === _ctx.Config.acsMasterFarm ||
              _ctx.farmVault.farm.master === _ctx.Config.acsMasterFarmV2
            ? 'ACS'
            : 'ACSI'), 1)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}