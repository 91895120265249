import { shallowRef, watch, ref } from 'vue';
import { ethers } from 'ethers';

import abis from '@/constants/abis';
import { Config } from './config/config';
import { quoter } from './quoter';
import { StableSwap } from './stable-swap.js';

import { bn, fw } from './bn.js';

export { Farm };

const wbnb = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
const busd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
const btcb = '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c';
const eth = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8';
const matic = '0xCC42724C6683B7E57334c4E856f4c9965ED682bD';

const maxuint =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

const masterFarmAbi = {
  '0xeaE1425d8ed46554BF56968960e2E567B49D0BED': abis.masterFarm1,
  '0x96c8390BA28eB083A784280227C37b853bc408B7': abis.masterFarm2,
  '0xb1fa5d3c0111d8E9ac43A19ef17b281D5D4b474E': abis.masterFarm3,
  '0x0C3B6058c25205345b8f22578B27065a7506671C': abis.masterFarm4,
  '0x308474E30326A1bbaA97d099A85bC12D2BBebA28': abis.masterFarm5,
};

const farmMaxBoost = 2.5;

class Farm extends EventTarget {
  static masterFarms = {};

  static async masterFarm(address, connect) {
    if (this.masterFarms[address]) return this.masterFarms[address];
    const masterFarm = {
      address: address,
      contract: new connect.web3.eth.Contract(masterFarmAbi[address], address),
      contractEthers: new ethers.Contract(
        address,
        masterFarmAbi[address],
        connect.multicallProvider
      ),
    };

    masterFarm.totalAllocPoint = bn(
      await masterFarm.contractEthers.totalAllocPoint()
    );

    const rewardPerBlockMethod = this.isV5Farm
      ? masterFarm.contractEthers.cutSushiPerBlock()
      : masterFarm.contractEthers.sushiPerBlock();
    masterFarm.rewardPerBlock = bn(await rewardPerBlockMethod);

    masterFarm.rewardToken = await masterFarm.contractEthers.sushi();
    masterFarm.rewardTokenSymbol = Config.tokens[masterFarm.rewardToken].symbol;

    return (this.masterFarms[address] = masterFarm);
  }

  constructor(i, connect) {
    super();

    for (const k in i) {
      this[k] = i[k];
    }

    this.isV2Farm =
      this.master === Config.acsMasterFarmV2 ||
      this.master === Config.acsiMasterFarmV2b ||
      this.master === Config.wavFarm;
    this.isV5Farm = this.master === Config.wavFarm;

    this.connect = connect;

    if (this.isV2Farm) {
      this.preCacheKey = ['farm.computedPoller', this.master, this.token].join(
        '.'
      );
    } else {
      this.preCacheKey = ['farm.computedPoller', this.master, this.pid].join(
        '.'
      );
    }
    this.loadLocalStorage();

    this._init();
  }

  stats = ref({});
  poller = shallowRef(0);

  async _init() {
    this.masterFarm = await Farm.masterFarm(this.master, this.connect);

    this.poller.value++;
    setInterval(() => {
      this.poller.value++;
    }, 28888);
  }

  async setInitialInfo() {
    if (this.isV2Farm) {
      this.tokenContract = new this.connect.web3.eth.Contract(
        abis.erc20,
        this.token
      );
      this.tokenContractEthers = new ethers.Contract(
        this.token,
        abis.erc20,
        this.connect.multicallProvider
      );
      this.poller.value++;

      const getPoolInfo = async () => {
        const i = await this.masterFarm.contractEthers.poolInfo(this.token);
        this.totalWeight = bn(i[0]);
        this.allocPoint = bn(i[1]); // allocPoint
      };
      const getAdditionalPoolRewardsInfo = async () => {
        this.additionalPoolRewardsLength = Number(
          await this.masterFarm.contractEthers.additionalPoolRewardsLength(
            this.token
          )
        );
        if (this.additionalPoolRewardsLength > 0) {
          this.additionalPoolRewards ||= [];
          await Promise.all(
            new Array(this.additionalPoolRewardsLength)
              .fill('0')
              .map(async (_, index) => {
                const i =
                  await this.masterFarm.contractEthers.additionalPoolRewards(
                    this.token,
                    index
                  );
                this.additionalPoolRewards[index] = {
                  ...this.additionalPoolRewards[index],
                  rewardToken: i.rewardToken,
                  from: i.from,
                  rewardPerBlock: bn(i.rewardPerBlock),
                };

                await this._initAdditioanlPoolReward(
                  this.additionalPoolRewards[index]
                );
              })
          );
        }
      };
      await Promise.all([getPoolInfo(), getAdditionalPoolRewardsInfo()]);
    } else {
      const i = await this.masterFarm.contractEthers.poolInfo(this.pid);

      this.allocPoint = bn(i[1]); // allocPoint
      this.token = i[0];
      this.tokenContract = new this.connect.web3.eth.Contract(
        abis.erc20,
        this.token
      );
      this.tokenContractEthers = new ethers.Contract(
        this.token,
        abis.erc20,
        this.connect.multicallProvider
      );
      this.poller.value++;
    }
  }

  async _initAdditioanlPoolReward(additionalPoolReward) {
    const rewardTokenContract = new ethers.Contract(
      additionalPoolReward.rewardToken,
      abis.erc20,
      this.connect.multicallProvider
    );

    const getFromBalance = async () => {
      additionalPoolReward.fromBalance = bn(
        await rewardTokenContract.balanceOf(additionalPoolReward.from)
      );
    };
    const getFromAllowance = async () => {
      additionalPoolReward.fromAllowance = bn(
        await rewardTokenContract.allowance(
          additionalPoolReward.from,
          this.masterFarm.address
        )
      );
    };
    await Promise.all([getFromBalance(), getFromAllowance()]);
    if (
      additionalPoolReward.fromBalance.lten(0) ||
      additionalPoolReward.fromAllowance.lten(0)
    ) {
      additionalPoolReward.rewardPerBlock = bn(0);
    }
  }

  async updateFarmBalance() {
    this.stats.value.farmBalance = bn(
      await this.tokenContractEthers.balanceOf(this.masterFarm.address)
    );
  }

  async updateUserBalance() {
    this.stats.value.userBalance = bn(
      await this.tokenContractEthers.balanceOf(this.connect.account)
    );
  }

  async updateUserBalanceFarm() {
    if (this.isV2Farm) {
      const i = await this.masterFarm.contractEthers.userInfo(
        this.token,
        this.connect.account
      );
      this.stats.value.userBalanceFarm = bn(i[0]);
      this.stats.value.userWeight = bn(i[1]);
    } else {
      this.stats.value.userBalanceFarm = bn(
        (
          await this.masterFarm.contractEthers.userInfo(
            this.pid,
            this.connect.account
          )
        )[0]
      );
    }
  }

  async updateRewardPending() {
    if (this.isV2Farm) {
      this.stats.value.rewardPending = bn(
        await this.masterFarm.contractEthers.pendingSushi(
          this.token,
          this.connect.account
        )
      );
    } else {
      this.stats.value.rewardPending = bn(
        await this.masterFarm.contractEthers.pendingSushi(
          this.pid,
          this.connect.account
        )
      );
    }
  }

  async updateCalculateWeight() {
    if (this.isV2Farm) {
      this.stats.value.calculateWeight = bn(
        await this.masterFarm.contractEthers.calculateWeight(
          this.token,
          this.connect.account
        )
      );
    }
  }

  async updateAll() {
    await Promise.all([
      this.poller.value === 1 && this.setInitialInfo(),
      this.tokenContractEthers && this.updateFarmBalance(),
      this.tokenContractEthers &&
        this.connect.account &&
        this.updateUserBalance(),
      this.connect.account && this.updateUserBalanceFarm(),
      this.connect.account && this.updateRewardPending(),
      this.connect.account && this.updateCalculateWeight(),
    ]);

    if (!this.isInited) {
      this.isInited = true;
    }
  }

  /**
   * WATCHERS
   */
  pollerWatcher = watch(this.poller, async () => {
    await this.updateAll();
    this.setLocalStorage();
  });

  loadLocalStorage() {
    const valueKeys = [
      'masterFarm',
      'totalWeight',
      'allocPoint',
      'additionalPoolRewards',
      'farmBalance',
    ];
    for (const valueKey of valueKeys) {
      const _cacheKey = [this.preCacheKey, valueKey].join('.');
      if (
        !(
          (valueKey === 'farmBalance' && this.stats.value[valueKey]) ||
          (valueKey !== 'farmBalance' && this[valueKey])
        )
      ) {
        const i = localStorage.getItem(_cacheKey);
        if (i) {
          if (valueKey !== 'farmBalance') {
            if (valueKey === 'additionalPoolRewards') {
              this[valueKey] = JSON.parse(i).map((additionalPoolReward) => ({
                ...additionalPoolReward,
                fromAllowance: bn(
                  `0x${additionalPoolReward.fromAllowance || '0'}`
                ),
                fromBalance: bn(`0x${additionalPoolReward.fromBalance || '0'}`),
                rewardPerBlock: bn(
                  `0x${additionalPoolReward.rewardPerBlock || '0'}`
                ),
                rewardPending: bn(
                  `0x${additionalPoolReward.rewardPending || '0'}`
                ),
                rewardPendingUsd: bn(
                  `0x${additionalPoolReward.rewardPendingUsd || '0'}`
                ),
                toUsd: bn(`0x${additionalPoolReward.toUsd || '0'}`),
              }));
            } else if (valueKey === 'masterFarm') {
              const mF = JSON.parse(i);
              this.masterFarm = {
                address: mF.address,
                rewardPerBlock:
                  mF.rewardPerBlock && bn(`0x${mF.rewardPerBlock}`),
                rewardToken: mF.rewardToken,
                rewardTokenSymbol: mF.rewardTokenSymbol,
                totalAllocPoint:
                  mF.totalAllocPoint && bn(`0x${mF.totalAllocPoint}`),
              };
            } else {
              this[valueKey] = bn(`0x${JSON.parse(i)}`);
            }
          } else {
            this.stats.value[valueKey] = bn(`0x${JSON.parse(i)}`);
          }
        }
      }
    }
  }

  setLocalStorage() {
    const valueKeys = [
      'masterFarm',
      'totalWeight',
      'allocPoint',
      'additionalPoolRewards',
      'farmBalance',
    ];
    for (const valueKey of valueKeys) {
      const value =
        (valueKey === 'farmBalance' && this.stats.value[valueKey]) ||
        (valueKey !== 'farmBalance' && this[valueKey]);
      if (value) {
        const _cacheKey = [this.preCacheKey, valueKey].join('.');
        if (valueKey === 'masterFarm') {
          localStorage.setItem(
            _cacheKey,
            JSON.stringify({
              address: value.address,
              rewardPerBlock: value.rewardPerBlock,
              rewardToken: value.rewardToken,
              rewardTokenSymbol: value.rewardTokenSymbol,
              totalAllocPoint: value.totalAllocPoint,
            })
          );
        } else {
          localStorage.setItem(_cacheKey, JSON.stringify(value));
        }
      }
    }
  }

  updateWatcher = watch(
    () => [
      this.stats.value.userBalanceFarm,
      this.stats.value.userBalance,
      this.stats.value.farmBalance,
      this.stats.value.userWeight,
      this.stats.value.calculateWeight,
    ],
    () => {
      if (!this.allocPoint) {
        return;
      }

      let tokenToUsd = quoter.q(this.token, busd);
      let tokenToBnb = quoter.q(this.token, wbnb);
      if (!tokenToBnb) {
        if (this.vault && this.vault.stats.value.pricePerFullShare) {
          const vaultTokenToBnb = quoter.q(this.vault.token, wbnb);
          const vaultTokenToUsd = quoter.q(this.vault.token, busd);
          tokenToBnb =
            vaultTokenToBnb &&
            this.vault.stats.value.pricePerFullShare
              .mul(vaultTokenToBnb)
              .div(bn(1e18));
          tokenToUsd =
            vaultTokenToUsd &&
            this.vault.stats.value.pricePerFullShare
              .mul(vaultTokenToUsd)
              .div(bn(1e18));
        } else if (StableSwap.stableSwapsByLpToken[this.token]) {
          if (
            StableSwap.stableSwapsByLpToken[this.token].baseSymbol === 'BTC'
          ) {
            tokenToBnb =
              quoter.q(btcb, wbnb) &&
              StableSwap.stableSwapsByLpToken[this.token].virtualPrice
                ?.mul(quoter.q(btcb, wbnb))
                .div(bn(1e18));
            tokenToUsd =
              quoter.q(btcb, busd) &&
              StableSwap.stableSwapsByLpToken[this.token].virtualPrice
                ?.mul(quoter.q(btcb, busd))
                .div(bn(1e18));
          } else if (
            StableSwap.stableSwapsByLpToken[this.token].baseSymbol === 'ETH'
          ) {
            tokenToBnb = StableSwap.stableSwapsByLpToken[
              this.token
            ].virtualPrice
              ?.mul(quoter.q(eth, wbnb))
              .div(bn(1e18));
            tokenToUsd = StableSwap.stableSwapsByLpToken[
              this.token
            ].virtualPrice
              ?.mul(quoter.q(eth, busd))
              .div(bn(1e18));
          } else if (
            StableSwap.stableSwapsByLpToken[this.token].baseSymbol === 'MATIC'
          ) {
            tokenToBnb = StableSwap.stableSwapsByLpToken[
              this.token
            ].virtualPrice
              ?.mul(quoter.q(matic, wbnb))
              .div(bn(1e18));
            tokenToUsd = StableSwap.stableSwapsByLpToken[
              this.token
            ].virtualPrice
              ?.mul(quoter.q(matic, busd))
              .div(bn(1e18));
          } else if (
            StableSwap.stableSwapsByLpToken[this.token].baseSymbol === 'BNB'
          ) {
            tokenToBnb =
              StableSwap.stableSwapsByLpToken[this.token].virtualPrice;
            tokenToUsd =
              quoter.q(wbnb, busd) &&
              StableSwap.stableSwapsByLpToken[this.token].virtualPrice
                ?.mul(quoter.q(wbnb, busd))
                .div(bn(1e18));
          } else {
            tokenToUsd =
              StableSwap.stableSwapsByLpToken[this.token].virtualPrice;
            tokenToBnb =
              quoter.q(busd, wbnb) &&
              tokenToUsd?.mul(quoter.q(busd, wbnb)).div(bn(1e18));
          }
        }
      }

      if (this.connect.account) {
        if (this.vault && this.vault.stats.value.pricePerFullShare) {
          this.stats.value.userBalanceFarmInVaultToken =
            this.stats.value.userBalanceFarm
              ?.mul(this.vault.stats.value.pricePerFullShare)
              .div(bn(1e18));
          this.stats.value.userBalanceFarmInVaultTokenNormalized =
            this.vault.applyTokenDecimals(
              this.stats.value.userBalanceFarmInVaultToken || bn(0)
            );
        }
        if (tokenToUsd) {
          this.stats.value.userBalanceFarmUsd = this.stats.value.userBalanceFarm
            ?.mul(tokenToUsd)
            .div(bn(1e18));
          this.stats.value.userBalanceUsd = this.stats.value.userBalance
            ?.mul(tokenToUsd)
            .div(bn(1e18));
        }
      }
      this.stats.value.farmBalanceBnb =
        tokenToBnb &&
        this.stats.value.farmBalance?.mul(tokenToBnb).div(bn(1e18));
      this.stats.value.farmBalanceUsd =
        tokenToUsd &&
        this.stats.value.farmBalance?.mul(tokenToUsd).div(bn(1e18));

      if (
        quoter.q(this.masterFarm.rewardToken, wbnb) &&
        this.stats.value.farmBalanceBnb &&
        this.stats.value.farmBalanceBnb.gtn(0)
      ) {
        this.stats.value.rewardPerDay = this.masterFarm.rewardPerBlock
          .muln(28800)
          .mul(this.allocPoint)
          .div(this.masterFarm.totalAllocPoint);
        // console.log(this.tokenSymbol, fwp(this.stats.rewardPerDay))
        this.stats.value.roiDay = parseFloat(
          fw(
            this.stats.value.rewardPerDay
              .mul(quoter.q(this.masterFarm.rewardToken, wbnb))
              .div(this.stats.value.farmBalanceBnb)
          )
        );
        this.additionalPoolRewards &&
          this.additionalPoolRewards.forEach((additionalPoolReward) => {
            try {
              const toBnb = quoter.q(additionalPoolReward.rewardToken, wbnb);
              const rewardPerDay =
                additionalPoolReward.rewardPerBlock.muln(28800);
              const roiDay = parseFloat(
                fw(rewardPerDay.mul(toBnb).div(this.stats.value.farmBalanceBnb))
              );
              this.stats.value.roiDay += roiDay;
            } catch (error) {
              console.error(error);
            }
          });

        this.stats.value.apyDay = (this.stats.value.roiDay + 1) ** 365 - 1;
        this.stats.value.aprDay = this.stats.value.roiDay * 365;
      }

      if (this.totalWeight && !this.deprecated) {
        if (this.stats.value.roiDay) {
          if (this.totalWeight.gtn(0)) {
            const totalBoost = parseFloat(
              fw(
                this.stats.value.farmBalance.mul(bn(1e18)).div(this.totalWeight)
              )
            );
            this.stats.value.roiDayMin = this.stats.value.roiDay * totalBoost;
            this.stats.value.roiDayMax =
              this.stats.value.roiDayMin * farmMaxBoost;
          }
          this.stats.value.apyDayMin =
            (this.stats.value.roiDayMin + 1) ** 365 - 1;
          this.stats.value.aprDayMin = this.stats.value.roiDayMin * 365;
          this.stats.value.apyDayMax =
            (this.stats.value.roiDayMax + 1) ** 365 - 1;
          this.stats.value.aprDayMax = this.stats.value.roiDayMax * 365;
        }
        const boostTokenVault =
          (this.master === Config.acsMasterFarmV2 && Farm.acsVault) ||
          (this.master === Config.acsiMasterFarmV2b && Farm.acsiVault) ||
          (this.master === Config.wavFarm && Farm.wavVault);
        if (boostTokenVault.stats.value.userBalanceVaultInToken) {
          const boostTokenVaultShare =
            boostTokenVault.stats.value.vaultBalance &&
            boostTokenVault.stats.value.userBalanceVaultInToken
              .mul(bn(1e18))
              .div(boostTokenVault.stats.value.vaultBalance);
          this.stats.value.maxBoostOnFirst = boostTokenVaultShare
            ?.mul(this.stats.value.farmBalance || bn(0))
            .div(bn(1e18));
          this.stats.value.maxBoostOnFirstUsd =
            tokenToUsd &&
            this.stats.value.maxBoostOnFirst?.mul(tokenToUsd).div(bn(1e18));
          if (this.vault && this.vault.stats.value.pricePerFullShare) {
            this.stats.value.maxBoostOnFirstInVaultToken =
              this.stats.value.maxBoostOnFirst
                ?.mul(this.vault.stats.value.pricePerFullShare)
                .div(bn(1e18));
          }
        }
        if (
          this.stats.value.userWeight &&
          this.stats.value.userBalanceFarm.gtn(0)
        ) {
          this.stats.value.currentBoostX = this.stats.value.userWeight
            .mul(bn(1e18))
            .div(this.stats.value.userBalanceFarm);
          this.stats.value.futureBoostX = this.stats.value.calculateWeight
            ?.mul(bn(1e18))
            .div(this.stats.value.userBalanceFarm);
          this.stats.value.maxBoostAcsNeeded =
            boostTokenVault.stats.value.vaultBalance &&
            boostTokenVault.stats.value.farmBalance &&
            this.stats.value.userBalanceFarm
              .mul(boostTokenVault.stats.value.vaultBalance)
              .div(this.stats.value.farmBalance);

          this.stats.value.currentBoost = this.stats.value.userWeight.sub(
            this.stats.value.userBalanceFarm
          );
          this.stats.value.futureBoost = this.stats.value.calculateWeight?.sub(
            this.stats.value.userBalanceFarm
          );
          if (this.vault && this.vault.stats.value.pricePerFullShare) {
            this.stats.value.currentBoostInVaultToken =
              this.stats.value.currentBoost
                .mul(this.vault.stats.value.pricePerFullShare)
                .div(bn(1e18));
            this.stats.value.futureBoostInVaultToken =
              this.stats.value.futureBoost
                ?.mul(this.vault.stats.value.pricePerFullShare)
                .div(bn(1e18));
          }

          if (this.stats.value.roiDayMin) {
            this.stats.value.roiDayBoosted =
              this.stats.value.roiDayMin *
              parseFloat(fw(this.stats.value.currentBoostX));
            this.stats.value.apyDayBoosted =
              (this.stats.value.roiDayBoosted + 1) ** 365 - 1;
            this.stats.value.aprDayBoosted =
              this.stats.value.roiDayBoosted * 365;
          }
        }
      }
    }
  );

  additionalRewardWatcher = watch(
    () => this.stats.value.rewardPending,
    () => {
      if (this.stats.value.rewardPending && this.allocPoint) {
        const toUsd = quoter.q(this.masterFarm.rewardToken, busd);
        if (toUsd)
          this.stats.value.rewardPendingUsd = this.stats.value.rewardPending
            .mul(toUsd)
            .div(bn(1e18));

        this.additionalPoolRewards &&
          this.masterFarm.rewardPerBlock.gtn(0) &&
          this.additionalPoolRewards.forEach((additionalPoolReward) => {
            additionalPoolReward.rewardPending = this.stats.value.rewardPending
              ?.mul(additionalPoolReward.rewardPerBlock)
              .div(
                this.masterFarm.rewardPerBlock
                  .mul(this.allocPoint)
                  .div(this.masterFarm.totalAllocPoint)
              );

            const toUsd = quoter.q(additionalPoolReward.rewardToken, busd);
            if (toUsd) {
              additionalPoolReward.rewardPendingUsd =
                additionalPoolReward.rewardPending.mul(toUsd).div(bn(1e18));
              additionalPoolReward.toUsd = toUsd;
            }
          });
      }
    }
  );

  async harvest() {
    if (this.isV2Farm) {
      return this.connect.send(
        this.masterFarm.contract.methods.harvest(this.token)
      );
    } else if (this.master == Config.acsMasterFarm) {
      return this.connect.send(
        this.masterFarm.contract.methods.deposit(this.pid, 0)
      );
    } else {
      return this.connect.send(
        this.masterFarm.contract.methods.harvest(this.pid)
      );
    }
  }

  async deposit(amount) {
    const allowance = bn(
      await this.tokenContract.methods
        .allowance(this.connect.account, this.masterFarm.address)
        .call()
    );
    // console.log(fw(allowance),fw(amount),allowance.lt(amount))
    if (allowance.lt(amount)) {
      await this.connect.send(
        this.tokenContract.methods.approve(this.masterFarm.address, maxuint),
        {},
        true
      );
    }
    if (this.isV2Farm) {
      return this.connect.send(
        this.masterFarm.contract.methods.deposit(this.token, amount)
      );
    } else {
      return this.connect.send(
        this.masterFarm.contract.methods.deposit(this.pid, amount)
      );
    }
  }

  async depositInVaultToken(amountInVaultToken) {
    const amount = amountInVaultToken.eq(
      this.vault.stats.value.userBalanceVaultInToken
    )
      ? this.vault.stats.value.userBalanceVault
      : amountInVaultToken
          .mul(bn(1e18))
          .div(this.vault.stats.value.pricePerFullShare);

    return this.deposit(amount);
  }

  async withdrawInVaultToken(amountInVaultToken) {
    const amount = amountInVaultToken.eq(
      this.stats.value.userBalanceFarmInVaultToken
    )
      ? this.stats.value.userBalanceFarm
      : amountInVaultToken
          .mul(bn(1e18))
          .div(this.vault.stats.value.pricePerFullShare);

    return this.withdraw(amount);
  }

  async depositAll() {
    const amount = bn(
      await this.tokenContract.methods.balanceOf(this.connect.account).call()
    );

    return this.deposit(amount);
  }

  async withdraw(amount) {
    if (this.isV2Farm) {
      return this.connect.send(
        this.masterFarm.contract.methods.withdraw(this.token, amount)
      );
    } else {
      return this.connect.send(
        this.masterFarm.contract.methods.withdraw(this.pid, amount)
      );
    }
  }
}
