import { shallowRef, watch, ref } from 'vue';
import { ethers } from 'ethers';

import abis from '@/constants/abis';
import collections from '@/constants/collections';
import { configsList } from './config/index';
import { quoter } from './quoter';
import { bn, fw } from './bn.js';
('use strict');

const maxuint =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const GET_PRICE_HISTORY_LAST = 8;
const DEFAULT_DECIMALS = 18;
const VALUE_KEYS = [
  'currentBlock',
  'pricePerFullShare',
  'rewardTokenSymbol',
  'rewardTokenDecimals',
  'vaultBalance',
  'lastHarvestedAt',
  'priceUsdFC',
  'agoBlock',
  'agoTimestamp',
  'pricePerFullShareAgo',
  'lastCompoundTimestamp',
  'pricePerFullShareLastCompound',
  'borrowLimit',
  'targetBorrowLimit',
  'estimateBestDayReturn',
  'idealBorrowLimit',
];
const TARGET_MIN_INTERVAL = 3600 * 24 * 3;

class VaultBase extends EventTarget {
  constructor(i, connect) {
    super();

    this.connect = connect;
    this.config = configsList[connect.networkConfig.chainId];

    for (const k in i) {
      this[k] = i[k];
    }

    this.isPriceUsd =
      this.isBestDayReturn ||
      this.vaultv2 ||
      this.connect.networkConfig.chainId !== 56;
    /*const abi = this.isBestDayReturn
      ? abis.vaultBestDayReturn
      : this.vaultv2 || this.connect.networkConfig.chainId !== 56
      ? this.isLiquid
        ? abis.vaultLiquid
        : abis.vaultV2
      : abis.vault;
    */
    //abi notes: vaultBestDayReturn includes vaultliquid.  vaultBestDayReturn = vaultV2
    //let abi = abis.vaultBestDayReturn;
    let abi = abis.vaultV2;
    if (!this.isBestDayReturn && !this.vaultv2) {
      abi = abis.vault;
    }

    this.contract = new this.connect.web3.eth.Contract(abi, this.address);
    this.contractEthers = new ethers.Contract(
      this.address,
      abi,
      this.connect.multicallProvider
    );

    if (this.connect.networkConfig.chainId === 56) {
      this.agoContractEthers = new ethers.Contract(
        this.address,
        abi,
        this.connect.agoMulticallProvider
      );
    }
    this.tokenContract = new this.connect.web3.eth.Contract(
      abis.erc20,
      this.token
    );
    this.tokenContractEthers = new ethers.Contract(
      this.token,
      abis.erc20,
      this.connect.multicallProvider
    );
    this.strategyContract = new this.connect.web3.eth.Contract(
      abis.strategy,
      this.strategy
    );
    this.strategyContractEthers = new ethers.Contract(
      this.strategy,
      abis.strategy,
      this.connect.multicallProvider
    );
    this.hasNft = this.tokenSymbol === 'ACS' || this.tokenSymbol === 'ACSI';
    if (this.hasNft) {
      this.collection = collections.find(
        (item) => item.tokenSymbol === this.tokenSymbol
      );
      this.nftContract = new ethers.Contract(
        this.collection.address,
        abis.nft,
        this.connect.multicallProvider
      );
      this.vaultWeightContract = new ethers.Contract(
        this.collection.vaultWeight,
        abis.vaultWeight,
        this.connect.multicallProvider
      );
    }
  }
}

class Vault extends VaultBase {
  constructor(i, connect) {
    super(i, connect);

    this.loadLocalStorage();

    this.poller.value++;
    setInterval(() => {
      this.poller.value++;
    }, 28888);
  }

  stats = ref({});
  poller = shallowRef(0);

  async setPricePerFullShare() {
    if (bn(await this.contractEthers.balance()).gtn(0)) {
      const setCurrentBlock = async () => {
        this.currentBlock =
          await this.connect.multicallProvider.getBlockNumber();
      };
      const setPricePFS = async () => {
        this.stats.value.pricePerFullShare = bn(
          await this.contractEthers.getPricePerFullShare()
        );
      };
      await Promise.all([setCurrentBlock(), setPricePFS()]);
    } else {
      this.stats.value.pricePerFullShare = bn(1e18);
    }
  }

  async setRewardTokenInfo() {
    if (this.vaultv2 || this.connect.networkConfig.chainId !== 56) {
      const rewardTokenAddress = await this.contractEthers.rewardToken();
      const rewardTokenContract = new ethers.Contract(
        rewardTokenAddress,
        abis.erc20,
        this.connect.multicallProvider
      );
      this.rewardTokenSymbol = await rewardTokenContract.symbol();
      this.rewardTokenDecimals = await rewardTokenContract.decimals();
    }
  }

  async updateVaultBalance() {
    this.stats.value.vaultBalance = bn(await this.contractEthers.balance());
  }

  async updateUserBalanceVault() {
    this.stats.value.userBalanceVault = bn(
      await this.contractEthers.balanceOf(this.connect.account)
    );
  }

  async updateUserBalance() {
    if (this.isGasToken) {
      this.stats.value.userBalance = bn(
        await this.connect.multicallProvider.getBalance(this.connect.account)
      );
    } else {
      this.stats.value.userBalance = bn(
        await this.tokenContractEthers.balanceOf(this.connect.account)
      );
    }
  }

  async updateLastHarvestedAt() {
    if (this.vaultv2 || this.connect.networkConfig.chainId !== 56) {
      this.stats.value.lastHarvestedAt = bn(
        await this.contractEthers.lastHarvestedAt()
      );
    }
  }

  async updatePriceUsdFC() {
    if (this.isPriceUsd) {
      this.stats.value.priceUsdFC = bn(await this.contractEthers.getPriceUsd());
    }
  }

  async updatePricePerFullShareAgoInfos() {
    if (this.connect.networkConfig.chainId === 56 && !this.isBestDayReturn) {
      this.agoBlock = this.agoPollerShort
        ? this.connect.agoBlockNumberShort
        : this.connect.agoBlockNumber;
      if (
        (!this.fromBlock || this.fromBlock < this.agoBlock) &&
        (!this.firstDepositBlock || this.firstDepositBlock < this.agoBlock) &&
        !this.deprecated
      ) {
        try {
          this.stats.value.pricePerFullShareAgo = bn(
            await this.agoContractEthers.getPricePerFullShare({
              blockTag: this.agoBlock,
            })
          );
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      const i = await this.contractEthers.getPriceHistory(
        GET_PRICE_HISTORY_LAST
      );
      if (i.length > 1) {
        this.lastCompoundTimestamp = parseInt(i[0].timestamp);
        this.stats.value.pricePerFullShareLastCompound = bn(
          i[0].pricePerFullShare
        );

        this.agoTimestamp = parseInt(i[1].timestamp);
        this.stats.value.pricePerFullShareAgo = bn(i[1].pricePerFullShare);
        for (let index = 2; index < i.length; index++) {
          if (i[index].timestamp.lt(i[0].timestamp.sub(TARGET_MIN_INTERVAL))) {
            this.agoTimestamp = parseInt(i[index].timestamp);
            this.stats.value.pricePerFullShareAgo = bn(
              i[index].pricePerFullShare
            );
            break;
          }
        }
      }
    }
  }

  async updateBorrowLimit() {
    if (
      this.showBorrowLimit ||
      (this.connect.networkConfig.chainId !== 56 &&
        this.connect.networkConfig.chainId !== 250)
    ) {
      this.stats.value.borrowLimit = bn(
        await this.strategyContractEthers.borrowLimit()
      );
    }
  }

  async updateTargetBorrowLimit() {
    if (
      this.showBorrowLimit ||
      (this.connect.networkConfig.chainId !== 56 &&
        this.connect.networkConfig.chainId !== 250)
    ) {
      this.stats.value.targetBorrowLimit = bn(
        await this.strategyContractEthers.targetBorrowLimit()
      );
    }
  }

  async updateUserNftAverageBalanceOfStakedVault() {
    this.stats.value.userNftAverageBalanceOfStakedVault = bn(
      await this.nftContract.ownerAverageBalanceOfStakedVault(
        this.connect.account
      )
    );
  }

  async updateUserNftBalanceOfStakedVault() {
    this.stats.value.userNftBalanceOfStakedVault = bn(
      await this.nftContract.ownerBalanceOfStakedVault(this.connect.account)
    );
  }

  async updateUserBalanceVaultWeight() {
    this.stats.value.userBalanceVaultWeight = bn(
      await this.vaultWeightContract.balanceOf(this.connect.account)
    );
  }

  async updateEstimateBestDayReturn() {
    if (this.isBestDayReturn) {
      const i = await this.contractEthers.estimateBestDayReturn();
      this.stats.value.estimateBestDayReturn = bn(i.dayReturn);
      this.stats.value.idealBorrowLimit = bn(i.borrowLimit);
    }
  }

  async updateHarvestReward() {
    this.strategyContract.methods
      .harvest()
      .call({ from: this.connect.account })
      .then((i) => {
        if (!this.rewardTokenSymbol) return;
        this.stats.value.harvestReward = bn(i).mul(
          bn(
            10 **
              (18 -
                (this.harvesterRewardSymbol
                  ? 18
                  : this.rewardTokenSymbol
                  ? this.rewardTokenDecimals || 18
                  : this.tokenDecimals || 18))
          )
        );
      })
      .catch((e) => e);
  }

  /**
   * WATCHERS
   */
  pollerWatcher = watch(this.poller, async () => {
    await Promise.all([
      this.poller.value === 1 &&
        !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.setPricePerFullShare(),
      this.poller.value === 1 && this.setRewardTokenInfo(),
      this.updateVaultBalance(),
      this.connect.account && this.updateUserBalanceVault(),
      this.connect.account && this.updateUserBalance(),
      !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.updateLastHarvestedAt(),
      this.updatePriceUsdFC(),
      !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.updatePricePerFullShareAgoInfos(),
      !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.updateBorrowLimit(),
      !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.updateTargetBorrowLimit(),
      this.hasNft &&
        this.connect.account &&
        this.updateUserNftAverageBalanceOfStakedVault(),
      this.hasNft &&
        this.connect.account &&
        this.updateUserNftBalanceOfStakedVault(),
      this.hasNft &&
        this.connect.account &&
        this.updateUserBalanceVaultWeight(),
      !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.updateEstimateBestDayReturn(),
      !this.deprecated &&
        !this.showFarmDeprecatedOnly &&
        this.connect.account &&
        this.updateHarvestReward(),
    ]);

    if (!this.isInited) {
      this.isInited = true;
    }

    this.setLocalStorage();
  });

  loadLocalStorage() {
    for (const valueKey of VALUE_KEYS) {
      const _cacheKey = [
        'vault.computedPoller',
        this.connect.networkConfig.chainId,
        this.address,
        valueKey,
      ].join('.');
      if (
        !(
          ((valueKey.endsWith('Block') ||
            valueKey.endsWith('Timestamp') ||
            valueKey.startsWith('reward')) &&
            this[valueKey]) ||
          (!valueKey.endsWith('Block') &&
            !valueKey.endsWith('Timestamp') &&
            !valueKey.startsWith('reward') &&
            this.stats.value[valueKey])
        )
      ) {
        const i = localStorage.getItem(_cacheKey);
        if (i) {
          if (
            valueKey.endsWith('Block') ||
            valueKey.endsWith('Timestamp') ||
            valueKey.startsWith('reward')
          ) {
            this[valueKey] = JSON.parse(i);
          } else {
            this.stats.value[valueKey] = bn(`0x${JSON.parse(i)}`);
          }
        }
      }
    }
  }

  setLocalStorage() {
    for (const valueKey of VALUE_KEYS) {
      const value =
        ((valueKey.endsWith('Block') ||
          valueKey.endsWith('Timestamp') ||
          valueKey.startsWith('reward')) &&
          this[valueKey]) ||
        (!valueKey.endsWith('Block') &&
          !valueKey.endsWith('Timestamp') &&
          !valueKey.startsWith('reward') &&
          this.stats.value[valueKey]);
      if (value) {
        const _cacheKey = [
          'vault.computedPoller',
          this.connect.networkConfig.chainId,
          this.address,
          valueKey,
        ].join('.');
        localStorage.setItem(_cacheKey, JSON.stringify(value));
      }
    }
  }

  // watcherPricePerFullShare = watch(
  //   () => this.stats.value.pricePerFullShare,
  //   () => {
  //     if (
  //       this.stats.value.pricePerFullShare?.lten(0) ||
  //       this.stats.value.pricePerFullShare?.gt(bn(1e18).pow(bn(2)))
  //     ) {
  //       this.stats.value.pricePerFullShare = bn(1e18);
  //     }
  //   }
  // );

  watcherForTvl = watch(
    () => [
      this.stats.value.priceUsdFC,
      this.stats.value.pricePerFullShare,
      this.stats.value.userBalance,
      this.stats.value.userBalanceVault,
      this.stats.value.vaultBalance,
    ],
    () => {
      if (!this.stats.value.pricePerFullShare || !this.currentBlock) {
        return;
      }
      if (this.stats.value.priceUsdFC) {
        this.stats.value.tokenToUsd = this.stats.value.priceUsdFC
          .mul(bn(1e18))
          .div(this.stats.value.pricePerFullShare);
      } else {
        this.stats.value.tokenToUsd = quoter.q(
          this.token,
          this.config.busd,
          DEFAULT_DECIMALS
        );
      }

      const tokenToBnb = quoter.q(this.token, this.config.wbnb);

      if (this.connect.account) {
        if (this.stats.value.userBalance) {
          this.stats.value.userBalanceNormalized = this.applyTokenDecimals(
            this.stats.value.userBalance
          );
          this.stats.value.userBalanceVaultInToken =
            this.stats.value.userBalanceVault
              ?.mul(this.stats.value.pricePerFullShare)
              .div(bn(1e18));
          if (this.stats.value.userBalanceVaultInToken) {
            this.stats.value.userBalanceVaultInTokenNormalized =
              this.applyTokenDecimals(this.stats.value.userBalanceVaultInToken);
          }

          if (this.stats.value.tokenToUsd) {
            this.stats.value.userBalanceUsd = this.stats.value.userBalance
              ?.mul(this.stats.value.tokenToUsd)
              .div(bn(1e18));
            this.stats.value.userBalanceVaultUsd =
              this.stats.value.userBalanceVaultInToken
                ?.mul(this.stats.value.tokenToUsd)
                .div(bn(1e18));
          }
        }
      }
      if (tokenToBnb) {
        this.stats.value.vaultBalanceBnb = this.stats.value.vaultBalance
          ?.mul(tokenToBnb)
          .div(bn(1e18));
      }
      if (this.stats.value.tokenToUsd) {
        this.stats.value.vaultBalanceUsd = this.stats.value.vaultBalance
          ?.mul(this.stats.value.tokenToUsd)
          .div(bn(1e18));
      }
    }
  );
  watcherForApy = watch(
    () => [
      this.stats.value.estimateBestDayReturn,
      this.stats.value.pricePerFullShareAgo,
      this.stats.value.pricePerFullShare,
      this.stats.value.pricePerFullShareLastCompound,
    ],
    () => {
      if (this.stats.value.estimateBestDayReturn) {
        this.stats.value.roiDay =
          parseFloat(fw(this.stats.value.estimateBestDayReturn)) - 1;
      } else {
        if (!this.stats.value.pricePerFullShare || !this.currentBlock) {
          return;
        }
        if (this.stats.value.pricePerFullShareAgo) {
          if (
            this.connect.networkConfig.chainId === 56 &&
            !this.isBestDayReturn
          ) {
            const daysAgo =
              ((this.currentBlock - this.agoBlock) *
                this.connect.networkConfig.blockTime) /
              86400;
            if (fw(this.stats.value.pricePerFullShareAgo) === '0') {
              this.stats.value.pricePerFullShareAgo = bn(1e18);
            }
            this.stats.value.roiDay =
              parseFloat(
                fw(
                  this.stats.value.pricePerFullShare
                    ?.mul(bn(1e18))
                    .div(this.stats.value.pricePerFullShareAgo)
                )
              ) **
                (1 / daysAgo) -
              1;
          } else {
            const daysAgo =
              this.lastCompoundTimestamp &&
              this.agoTimestamp &&
              (this.lastCompoundTimestamp - this.agoTimestamp) / 86400;
            this.stats.value.roiDay =
              this.stats.value.pricePerFullShareAgo.gtn(0) &&
              parseFloat(
                fw(
                  this.stats.value.pricePerFullShareLastCompound
                    ?.mul(bn(1e18))
                    .div(this.stats.value.pricePerFullShareAgo)
                )
              ) **
                (1 / daysAgo) -
                1;
          }
        } else if (
          this.connect.networkConfig.chainId === 56 &&
          this.stats.value.pricePerFullShare &&
          this.firstDepositBlock &&
          this.currentBlock >
            this.firstDepositBlock +
              (8 * 60 * 60) / this.connect.networkConfig.blockTime
        ) {
          const daysAgo =
            ((this.currentBlock - this.firstDepositBlock) *
              this.connect.networkConfig.blockTime) /
            86400;
          this.stats.value.roiDay =
            parseFloat(fw(this.stats.value.pricePerFullShare)) **
              (1 / daysAgo) -
            1;
        } else {
          this.stats.value.roiDay = this.defaultRoiDay;
        }
      }

      //to fallback to defaultRoiDay for liquid staked vault (BNB liquid staked)
      if (this.defaultRoiDay && !this.stats.value.roiDay) {
        this.stats.value.roiDay = this.defaultRoiDay;
      }
      this.stats.value.apyDay = (this.stats.value.roiDay + 1) ** 365 - 1;
      this.stats.value.aprDay = this.stats.value.roiDay * 365;
    }
  );

  watcherForNft = watch(
    () => [
      this.stats.value.userNftAverageBalanceOfStakedVault,
      this.stats.value.pricePerFullShare,
      this.stats.value.userNftBalanceOfStakedVault,
      this.stats.value.tokenToUsd,
      this.stats.value.userBalanceVaultWeight,
    ],
    () => {
      if (this.connect.account) {
        if (this.stats.value.userNftAverageBalanceOfStakedVault) {
          this.stats.value.nftEffectiveBoost =
            this.stats.value.userNftAverageBalanceOfStakedVault
              .mul(this.stats.value.pricePerFullShare)
              .div(bn(1e18));
        }
        if (this.stats.value.userNftBalanceOfStakedVault) {
          this.stats.value.totalStakeInNft =
            this.stats.value.userNftBalanceOfStakedVault
              .mul(this.stats.value.pricePerFullShare)
              .div(bn(1e18));
          if (this.stats.value.tokenToUsd) {
            this.stats.value.totalStakeInNftUsd =
              this.stats.value.totalStakeInNft
                ?.mul(this.stats.value.tokenToUsd)
                .div(bn(1e18));
          }
        }
        if (this.stats.value.userBalanceVaultWeight) {
          this.stats.value.nftOverallBoost =
            this.stats.value.userBalanceVaultWeight
              .mul(this.stats.value.pricePerFullShare)
              .div(bn(1e18));
        }
      }
    }
  );

  async calculateWithdraw(amountInToken) {
    const amount = amountInToken
      .mul(bn(1e18))
      .div(this.stats.value.pricePerFullShare)
      .toString();

    if (this.isLiquid) {
      const data = await this.contractEthers.calculateWithdraw(amount);
      //const data = await this.contract.calculateWithdraw(amount); //use non multicall method TODO
      this.stats.value.calculatedWithdraw = this.applyTokenDecimals(bn(data));
    }
  }

  async deposit(amount) {
    let tx;
    if (this.isGasToken) {
      if (this.connect.networkConfig.chainId === 56 && !this.vaultv2) {
        tx = this.connect.send(this.contract.methods.depositETH(), {
          value: amount,
        });
      } else {
        tx = this.connect.send(this.contract.methods.deposit(bn(0)), {
          value: amount,
        });
      }
    } else {
      const allowance = bn(
        await this.tokenContract.methods
          .allowance(this.connect.account, this.address)
          .call()
      );
      if (allowance.lt(amount)) {
        await this.connect.send(
          this.tokenContract.methods.approve(this.address, maxuint),
          {},
          true
        );
      }
      tx = this.connect.send(this.contract.methods.deposit(amount));
    }
    return tx.then((receipt) => {
      // ANALYTICS
      const data = {
        // affiliation: "Google online store",
        // coupon: "SUMMER_DISCOUNT",
        // currency: "USD",
        // shipping: 5.55,
        // tax: 3.33,
        transaction_id: receipt.transactionHash,
        // value: 28.86,
        items: [
          {
            // id: "P12346",
            name: this.tokenSymbol + ' Vault',
            // coupon: "P12346_coupon",
            // list_name: "Search Results",
            // brand: "MyBrand",
            // category: "Apparel/T-Shirts",
            // variant: "Red",
            // list_position: 5,
            // quantity: 1,
            // price: 9.99
          },
        ],
      };
      const tokenToUsd = quoter.q(this.token, this.config.busd);
      if (tokenToUsd) {
        data.currency = 'USD';
        data.value = parseFloat(fw(amount.mul(tokenToUsd).div(bn(1e18))));
        data.items[0].quantity = parseFloat(fw(amount));
        data.items[0].price = parseFloat(fw(tokenToUsd));
      }
      // console.log(data)

      // google analytics
      // gtag('event', 'purchase', data)

      // twitter conversion tracking
      // twq('track','Purchase', {
      //   //required parameters
      //   name: data.items[0].name,
      //   value: data.value,
      //   currency: 'USD',
      //   num_items: data.items[0].quantity,
      // });

      return receipt;
    });
  }

  async withdrawWithSlippage(amountInToken) {
    const amount = amountInToken.eq(this.stats.value.userBalanceVaultInToken)
      ? this.stats.value.userBalanceVault
      : amountInToken.mul(bn(1e18)).div(this.stats.value.pricePerFullShare);

    const minAmount = this.unapplyTokenDecimals(
      this.stats.value.calculatedWithdraw
    )
      .mul(bn(1e18))
      .div(this.stats.value.pricePerFullShare);

    // console.log(amountInToken.eq(this.stats.value.userBalanceVaultInToken), amount)

    return this.connect.send(
      this.contract.methods.withdrawWithSlippage(
        amount,
        minAmount,
        this.isGasToken
      )
    );
  }

  async withdrawInToken(amountInToken) {
    const amount = amountInToken.eq(this.stats.value.userBalanceVaultInToken)
      ? this.stats.value.userBalanceVault
      : amountInToken.mul(bn(1e18)).div(this.stats.value.pricePerFullShare);

    // console.log(amountInToken.eq(this.stats.value.userBalanceVaultInToken), amount)

    return this.withdraw(amount);
  }

  async withdraw(amount) {
    if (this.isGasToken) {
      if (this.connect.networkConfig.chainId === 56 && !this.vaultv2) {
        return this.connect.send(this.contract.methods.withdrawETH(amount));
      } else {
        return this.connect.send(this.contract.methods.withdrawNative(amount));
      }
    } else {
      return this.connect.send(this.contract.methods.withdraw(amount));
    }
  }

  async harvest() {
    return this.connect.send(this.strategyContract.methods.harvest());
  }

  async rebalance() {
    return this.connect.send(this.strategyContract.methods.rebalance());
  }

  applyTokenDecimals(amount) {
    if (!this.tokenDecimals) return amount;
    const o = bn(amount).mul(bn(10 ** (18 - this.tokenDecimals)));
    return typeof amount === 'string' ? o.toString() : o;
  }

  unapplyTokenDecimals(amount) {
    if (!this.tokenDecimals) return amount;
    const o = bn(amount).div(bn(10 ** (18 - this.tokenDecimals)));
    return typeof amount === 'string' ? o.toString() : o;
  }
}

export { Vault };
