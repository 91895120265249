import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b168bb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-tvl" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "top-tvl-label" }
const _hoisted_4 = { class: "my-tvl-value" }
const _hoisted_5 = { class: "flex justify-between mt-1" }
const _hoisted_6 = { class: "top-tvl-label" }
const _hoisted_7 = { class: "top-tvl-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('my_tvl')) + ":", 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.acsStats?.userTvl?.gtn(0) ? `$${_ctx.fwp(_ctx.acsStats.userTvl)}` : '--'), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('total_tvl')) + ":", 1),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getTotal() ? `$${_ctx.getTotal().toLocaleString()}` : '--'), 1)
    ])
  ]))
}