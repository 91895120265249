<template>
  <div class="gamefi-container">
    <p class="gamefi-awaits">{{ $t('gamefi_awaits') }}</p>
    <p class="gamefi-desc" v-html="$t('gamefi_desc')"></p>
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .gamefi-container {
    margin-top: -55px;
    height: 434px;
  }
  .gamefi-awaits {
    padding-top: 35px;
  }
  .gamefi-desc {
    padding-top: 10px;
    width: 300px;
    margin: auto;
  }
}
@media (min-width: 440px) {
  .gamefi-container {
    margin-top: -70px;
    height: 485px;
  }
  .gamefi-awaits {
    padding-top: 40px;
  }
  .gamefi-desc {
    padding-top: 10px;
    width: 300px;
    margin: auto;
  }
}
@media (min-width: 640px) {
  .gamefi-container {
    margin-top: -85px;
    height: 622px;
  }
  .gamefi-awaits {
    padding-top: 50px;
  }
  .gamefi-desc {
    padding-top: 10px;
    width: 400px;
    margin: auto;
  }
}
@media (min-width: 768px) {
  .gamefi-container {
    margin-top: -115px;
    height: 747px;
  }
  .gamefi-awaits {
    padding-top: 70px;
  }
  .gamefi-desc {
    padding-top: 20px;
    width: 500px;
    margin: auto;
  }
}
@media (min-width: 1024px) {
  .gamefi-container {
    margin-top: -280px;
    height: 996px;
  }
  .gamefi-awaits {
    padding-top: 240px;
  }
  .gamefi-desc {
    padding-top: 30px;
    width: 30%;
    margin-left: auto;
    margin-right: 5%;
  }
}
@media (min-width: 1280px) {
  .gamefi-container {
    margin-top: -285px;
    height: 1246px;
  }
  .gamefi-awaits {
    padding-top: 240px;
  }
  .gamefi-desc {
    padding-top: 30px;
    width: 30%;
    margin-left: auto;
    margin-right: 10%;
  }
}
@media (min-width: 1536px) {
  .gamefi-container {
    margin-top: -270px;
    height: 1496px;
  }
  .gamefi-awaits {
    padding-top: 240px;
  }
  .gamefi-desc {
    padding-top: 70px;
    width: 30%;
    margin-left: auto;
    margin-right: 10%;
  }
}
.gamefi-container {
  @apply w-full bg-transparent relative;
  background-image: url('~@/assets/images/gamefi-bg.jpg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.gamefi-awaits {
  @apply font-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl 2xl:text-9xl text-center;
  letter-spacing: -0.03em;
  background: -webkit-linear-gradient(#000, #595959);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gamefi-desc {
  @apply font-normal text-black italic text-center text-sssm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-xl;
  font-family: DINPro-CondMedium;
}
</style>
