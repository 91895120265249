
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SuccessOverlay',

  emits: ['close'],

  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    closeLabel: { type: String, required: true },
    explorerLink: { type: String, required: true },
  },
});
