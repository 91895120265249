import { Config } from './config';
import { Config as ConfigCronos } from './configCronos';
import { Config as ConfigHarmony } from './configHarmony';
import { Config as ConfigFantom } from './configFantom';
import { Config as ConfigAvax } from './configAvax';
import { Config as ConfigMoonriver } from './configMoonriver';
import { Config as ConfigMoonbeam } from './configMoonbeam';
import { Config as ConfigAstar } from './configAstar';
import { Config as ConfigGnosis } from './configGnosis';
import { Config as ConfigArbitrum } from './configArbitrum';
import { Config as ConfigEthereum } from './configEthereum';
import { Config as ConfigOptimism } from './configOptimism';
import { Config as ConfigKava } from './configKava';
import { Config as ConfigBase } from './configBase';
import { Config as ConfigCanto } from './configCanto';
import { Config as ConfigPolygon } from './configPolygon';
// import { Config as ConfigAurora } from './configAurora';

import ConfigGenerated from './generated.json';
import ConfigGeneratedCronos from './generatedCronos.json';
import ConfigGeneratedHarmony from './generatedHarmony.json';
import ConfigGeneratedFantom from './generatedFantom.json';
import ConfigGeneratedAvax from './generatedAvax.json';
import ConfigGeneratedArbitrum from './generatedArbitrum.json';
import ConfigGeneratedKava from './generatedKava.json';
import ConfigGeneratedCanto from './generatedCanto.json';
// import ConfigGeneratedAurora from './generatedAurora.json';

export const configsList = {
  56: Config,
  25: ConfigCronos,
  1666600000: ConfigHarmony,
  43114: ConfigAvax,
  250: ConfigFantom,
  1285: ConfigMoonriver,
  1284: ConfigMoonbeam,
  2222: ConfigKava,
  592: ConfigAstar,
  100: ConfigGnosis,
  42161: ConfigArbitrum,
  1: ConfigEthereum,
  10: ConfigOptimism,
  137: ConfigPolygon,
  7700: ConfigCanto,
  8453: ConfigBase,
  // 1313161554: ConfigAurora
};

export const configsGeneratedList = {
  56: ConfigGenerated,
  25: ConfigGeneratedCronos,
  1666600000: ConfigGeneratedHarmony,
  250: ConfigGeneratedFantom,
  42161: ConfigGeneratedArbitrum,
  43114: ConfigGeneratedAvax,
  2222: ConfigGeneratedKava,
  7700: ConfigGeneratedCanto,
  // 1313161554: ConfigGeneratedAurora
};
