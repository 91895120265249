const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0xBb522440bFffd563D8EE07d38Ce1b7d0f20deEFe',
  wbnb: '0x98878B06940aE243284CA214f92Bb71a2b032B8A', // WMOVR
  // busd: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D', // USDC
  // busdDecimals: 6,
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

export { Config };
