import vaultWeight from './vaultWeight.json';
import vault from './vault.json';
import vaultV2 from './vaultV2.json';
import nft from './nft.json';
import erc20 from './erc20.json';
import multicall from './multicall.json';
import uniswapV2Pair from './uniswapV2Pair.json';
import strategy from './strategy.json';
import bVault from './bVault.json';
import weightedPool from './weightedPool.json';
import stablePool from './stablePool.json';
import vyper from './vyper.json';
import vaultFactory from './vaultFactory.json';
import masterFarm1 from './masterFarm1.json';
import masterFarm2 from './masterFarm2.json';
import masterFarm3 from './masterFarm3.json';
import masterFarm4 from './masterFarm4.json';
import masterFarm5 from './masterFarm5.json';

export default {
  vaultWeight,
  vault,
  vaultV2,
  nft,
  erc20,
  multicall,
  uniswapV2Pair,
  strategy,
  bVault,
  weightedPool,
  stablePool,
  vyper,
  vaultFactory,
  masterFarm1,
  masterFarm2,
  masterFarm3,
  masterFarm4,
  masterFarm5,
};
