<template>
  <div
    class="sidebar-backdrop"
    @click="setMenuShow(false)"
    v-if="menuShow && upToLargeBreakpoint"
  ></div>
  <transition name="slide">
    <div
      class="sidebar-container"
      v-if="menuShow || !upToLargeBreakpoint"
      :class="[
        menuCollapsed ? 'w-15' : 'uncollapsed',
        { 'sidebar-panel fixed': upToLargeBreakpoint },
        {
          'sidebar-panel bg-opacity absolute':
            !upToLargeBreakpoint && currentRouteName === 'stake_mint',
        },
      ]"
    >
      <div class="relative">
        <div class="logo-container">
          <transition name="opacity-transition">
            <BalIcon
              v-if="!menuCollapsed"
              name="line-md:menu-unfold-left"
              class="menu-icon opened-menu-icon"
              :size="upToLargeBreakpoint ? 'lg' : '2xl'"
              @click="setMenuClose()"
            />
          </transition>
          <transition name="opacity-transition">
            <BalIcon
              v-if="menuCollapsed"
              name="line-md:menu-fold-right"
              class="menu-icon right-3.5"
              :size="upToLargeBreakpoint ? 'lg' : '2xl'"
              @click="
                openingMenu = true;
                setMenuCollapsed(false);
              "
            />
          </transition>
          <transition
            name="opacity-transition-logo"
            v-on:after-enter="afterUncollapse"
          >
            <div v-if="!menuCollapsed" class="logo">
              <div class="logo-text">
                <p class="logo-v2">V2</p>
              </div>
            </div>
          </transition>
        </div>
        <div class="menu-list-container" :class="{ 'w-60': !menuCollapsed }">
          <div v-for="(item, i) in menuList" :key="i">
            <div
              @click="selectMenu(i)"
              class="menu-item"
              :class="[
                currentRouteName === item.value ||
                (item.childs &&
                  item.childs.find(
                    (it) =>
                      it.value === currentRouteName ||
                      (currentRouteName === 'leaderboard' &&
                        it.key &&
                        currentRouteKey === it.key)
                  ) &&
                  !item.isOpen &&
                  !item.isOpenAlways)
                  ? 'selected'
                  : '',
                i === 0 ? 'mt-0' : 'mt-2',
              ]"
            >
              <img
                v-if="item.icon.startsWith('vault')"
                src="~@/assets/icons/vaults.png"
                :class="
                  darkMode
                    ? 'menu-list-vaults-icon-dark'
                    : 'menu-list-vaults-icon-light'
                "
              />
              <BalIcon
                v-else
                :name="item.icon"
                class="menu-list-icon"
                :size="upToLargeBreakpoint ? 'sm' : 'md'"
              />
              <transition name="opacity-transition">
                <div v-if="!menuCollapsed" class="relative">
                  <p class="menu-label">
                    {{ $t(item.value) }}
                  </p>
                  <p v-if="item.sublabel" class="menu-sublabel">
                    {{ $t(item.sublabel) }}
                  </p>
                </div>
              </transition>
              <transition name="opacity-transition">
                <BalIcon
                  v-if="item.childs && !menuCollapsed && !item.isOpenAlways"
                  name="akar-icons:chevron-left"
                  :size="upToLargeBreakpoint ? 'sm' : 'md'"
                  class="expend-icon"
                  :class="item.isOpen ? 'transform -rotate-90' : ''"
                />
              </transition>
            </div>
            <transition :name="openingMenu ? 'opacity-transition' : ''">
              <div
                v-if="
                  item.childs &&
                  (item.isOpen || item.isOpenAlways) &&
                  !menuCollapsed
                "
              >
                <div
                  v-for="(child, ind) in item.childs"
                  :key="ind"
                  @click="selectMenu(i, ind)"
                  class="menu-sub-item"
                  :class="[
                    {
                      selected:
                        currentRouteName === child.value ||
                        (currentRouteName === 'leaderboard' &&
                          child.key &&
                          currentRouteKey === child.key),
                    },
                    child.chevron
                      ? 'menu-sub-item-with-chevron'
                      : 'menu-sub-item-without-chevron',
                  ]"
                >
                  <BalIcon
                    v-if="child.chevron"
                    name="charm:chevron-right"
                    class="menu-sub-item-icon"
                    :size="upToLargeBreakpoint ? 'xs' : 'sm'"
                  />
                  <span class="menu-label">{{ $t(child.value) }}</span>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <transition name="opacity-transition">
        <div
          v-if="!menuCollapsed"
          class="cursor-default px-4 lg:px-6"
          data-dev-hint="second-main-navigation or footer navigation"
        >
          <SocialLinks />
          <Audited />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import useDarkMode from '@/composables/useDarkMode';
import useBreakpoints from '@/composables/useBreakpoints';
import useMenuCollapsed from '@/composables/useMenuCollapsed';
import useBuyTokenModal from '@/composables/useBuyTokenModal';
import useSwitchToBnbModal from '@/composables/useSwitchToBnbModal';
import useVaults from '@/services/useVaults';
import { configService } from '@/services/config/config.service';
import addresses from '@/constants/addresses';
import useWeb3 from '@/services/web3/useWeb3';
import { fwp } from '@/lib/utils/bn';
import Audited from './Audited.vue';
import SocialLinks from './SocialLinks.vue';

const { nativeAsset } = configService.network;

export default defineComponent({
  components: {
    SocialLinks,
    Audited,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const { darkMode } = useDarkMode();
    const { userNetworkConfig } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { toggleBuyTokenModal } = useBuyTokenModal();
    const { toggleSwitchToBnbModal } = useSwitchToBnbModal();
    const { menuCollapsed, setMenuCollapsed, menuShow, setMenuShow } =
      useMenuCollapsed();
    const { acsStats } = useVaults();

    onMounted(() => {
      if (!upToLargeBreakpoint.value) {
        setMenuShow(true);
      }
    });

    // COMPUTED
    const currentRouteName = computed(() => router.currentRoute.value.name);
    route.params.key;
    const currentRouteKey = computed(() => route.params.key);
    const toolsMenuIndex = computed(() =>
      menuList.value.findIndex((item) => item.value === 'tools')
    );

    // DATA
    const openingMenu = ref(false);
    const menuList = ref([
      {
        value: 'dashboard',
        icon: 'simple-line-icons:speedometer',
      },
      {
        value: 'vaults',
        url: 'vaults/all',
        // link: 'https://app.acryptos.com/',
        icon: 'vaults.png',
        sublabel: 'auto_compound',
      },
      {
        value: 'farms',
        url: 'farms/all',
        icon: 'teenyicons:plant-outline',
        sublabel: 'acsi_dot_finance',
      },
      {
        value: 'core',
        icon: 'ri:coreos-line',
        isOpenAlways: true,
        childs: [
          {
            value: 'governance_vaults',
            url: 'governance_vaults/all',
            chevron: true,
          },
          {
            value: 'onsen_ido',
            url: 'onsen_ido/all',
            chevron: true,
          },
        ],
      },
      {
        value: 'swap',
        icon: 'ant-design:swap-outlined',
        isOpenAlways: true,
        childs: [
          {
            value: 'acsi_finance',
            // link: 'https://acsi.finance/',
            chevron: true,
          },
          {
            value: 'stable_swap',
            link: 'https://app.acryptos.com/stableswap/',
            chevron: true,
          },
        ],
      },
      {
        value: 'nft',
        icon: 'ic:outline-token',
        isOpen: false,
        childs: [
          {
            value: 'stake_mint',
            url: 'stake_mint',
          },
          {
            value: 'leaderboard_acs',
            url: 'leaderboard/acs',
            key: 'acs',
          },
          {
            value: 'leaderboard_acsi',
            url: 'leaderboard/acsi',
            key: 'acsi',
          },
          {
            value: 'my_nfts',
            url: 'my_nfts',
          },
        ],
      },
      {
        value: 'tools',
        icon: 'codicon:tools',
        isOpen: false,
        childs: [
          {
            value: 'workers',
            link: 'https://app.acryptos.com/worker/',
          },
          {
            value: 'vote',
            link: 'https://vote.acryptos.com/',
          },
          {
            value: 'buyback_stats',
            link: 'https://acryptos.unrekt.net/',
          },
          {
            value: 'fee_stats',
            link: 'https://docs.acryptos.com/fees',
          },
          {
            value: 'deprecated_vaults',
            url: 'deprecated/all',
          },
        ],
      },
      {
        value: 'docs',
        icon: 'carbon:document-blank',
        isOpen: false,
        childs: [
          {
            value: 'documentation',
            link: 'https://docs.acryptos.com/',
          },
          {
            value: 'audit',
            link: 'https://docs.acryptos.com/security-and-risks#audits',
          },
          {
            value: 'smart_contracts',
            link: 'https://app.acryptos.com/contracts/',
          },
        ],
      },
    ]);

    // METHODS
    const selectMenu = (index, childIndex = -1) => {
      if (
        menuList.value[index].childs &&
        menuList.value[index].childs[childIndex]?.value === 'acsi_finance'
      ) {
        if (upToLargeBreakpoint.value) {
          setMenuShow(false);
        }
        if (userNetworkConfig.value.shortName === 'bsc') {
          toggleBuyTokenModal(true, nativeAsset.address, addresses.acs);
        } else {
          toggleSwitchToBnbModal(true);
        }
        return;
      }
      if (childIndex >= 0) {
        if (menuList.value[index].childs[childIndex].link) {
          if (
            userNetworkConfig.value.shortName !== 'bsc' &&
            menuList.value[index].childs[childIndex]?.value === 'stable_swap'
          ) {
            toggleSwitchToBnbModal(true);
          } else {
            openLink(menuList.value[index].childs[childIndex].link);
          }
        } else if (menuList.value[index].childs[childIndex].url) {
          goTo(menuList.value[index].childs[childIndex].url);
        }
      } else {
        closeSubMenu(index);
        if (menuList.value[index].childs) {
          if (menuCollapsed.value) {
            openingMenu.value = true;
            setMenuCollapsed(false);
          }
          menuList.value[index].isOpen = !menuList.value[index].isOpen;
        } else {
          if (menuList.value[index].value === 'dashboard') {
            toast.warning(t('alert_coming_soon'));
          } else if (menuList.value[index].link) {
            openLink(menuList.value[index].link);
          } else if (menuList.value[index].url) {
            goTo(menuList.value[index].url);
          }
        }
      }
    };
    const goTo = (url) => {
      if (userNetworkConfig.value.shortName === 'bsc' || url === 'vaults/all') {
        router.push({
          path: `/${userNetworkConfig.value.shortName}/${url}`,
        });
        if (upToLargeBreakpoint.value) {
          setMenuShow(false);
        }
      } else {
        toggleSwitchToBnbModal(true);
      }
    };
    const setMenuClose = () => {
      if (upToLargeBreakpoint.value) {
        setMenuShow(false);
      } else {
        setMenuCollapsed(true);
        closeSubMenu();
      }
    };
    const closeSubMenu = (index = -1) => {
      for (let i = 0; i < menuList.value.length; i++) {
        if (i === index) {
          continue;
        }
        menuList.value[i].isOpen = false;
      }
    };
    const setOpenChildMenu = (value) => {
      for (let i = 0; i < menuList.value.length; i++) {
        if (
          menuList.value[i].childs &&
          menuList.value[i].childs.find((item) => item.value === value)
        ) {
          menuList.value[i].isOpen = true;
        }
      }
    };
    const afterUncollapse = () => {
      openingMenu.value = false;
    };
    const openLink = (url) => {
      window.open(url, '_blank').focus();
    };

    // WATCHERS
    watch(upToLargeBreakpoint, (newVal) => {
      setMenuShow(!newVal);
      setMenuCollapsed(!newVal);
      closeSubMenu();
    });
    watch(currentRouteName, (newVal) => {
      setOpenChildMenu(newVal);
      if (newVal === 'stake_mint') {
        closeSubMenu();
        if (!upToLargeBreakpoint.value) {
          setMenuCollapsed(true);
        }
      }
    });

    return {
      fwp,
      darkMode,
      acsStats,
      upToLargeBreakpoint,
      menuShow,
      menuCollapsed,
      setMenuShow,
      setMenuCollapsed,
      userNetworkConfig,
      // computed
      toolsMenuIndex,
      currentRouteKey,
      currentRouteName,
      // data
      openingMenu,
      menuList,
      // methods
      afterUncollapse,
      closeSubMenu,
      setMenuClose,
      selectMenu,
      openLink,
    };
  },
});
</script>

<style scoped>
@media (max-width: 1024px) {
  .logo-container {
    height: 92px;
  }
  .logo {
    height: 92px;
    width: 95px;
  }
  .menu-list-container {
    height: calc(100vh - 302px);
  }
  .uncollapsed {
    min-width: 240px;
  }
  .menu-sub-item-with-chevron {
    @apply pl-11;
  }
  .menu-sub-item-without-chevron {
    padding-left: 52px;
  }
}
@media (min-width: 1024px) {
  .logo-container {
    height: 106px;
  }
  .logo {
    height: 106px;
    width: 113px;
  }
  .menu-list-container {
    height: calc(100vh - 348px);
  }
  .uncollapsed {
    width: 240px;
  }
  .menu-sub-item-with-chevron {
    padding-left: 60px;
  }
  .menu-sub-item-without-chevron {
    padding-left: 72px;
  }
}
.sidebar-container {
  @apply flex flex-col justify-between bg-white dark:bg-zink-780;
  transition: width 1s;
  height: 100vh;
}
.logo-container {
  @apply mt-2 lg:mt-6;
  width: 100%;
}
.logo {
  @apply relative mx-auto;
  background-image: url('~@/assets/images/acryptos-logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.logo-text {
  @apply absolute bottom-3 lg:bottom-6 right-0 text-center;
}
.logo-v2 {
  @apply font-bold text-xs lg:text-sm;
}
.menu-icon {
  @apply cursor-pointer absolute w-6 h-6 top-5 lg:w-8 lg:h-8 lg:top-8;
}
.opened-menu-icon {
  @apply right-4 lg:right-1;
}
.menu-list-container {
  @apply overflow-auto mt-10 py-2 mb-4;
}
.menu-item,
.menu-sub-item {
  @apply cursor-pointer flex;
}
.menu-item {
  @apply pl-4 lg:pl-5 relative h-6 lg:h-8 items-center;
}
.menu-sub-item {
  @apply py-1 items-start;
}
.menu-list-vaults-icon-light,
.menu-list-vaults-icon-dark {
  @apply w-4 h-4 mr-3 lg:w-5 lg:h-5 lg:mr-5;
}
.menu-list-vaults-icon-light {
  filter: invert(19%) sepia(37%) saturate(18%) hue-rotate(323deg)
    brightness(94%) contrast(85%);
}
.menu-list-vaults-icon-dark {
  filter: invert(46%) sepia(5%) saturate(285%) hue-rotate(212deg)
    brightness(95%) contrast(92%);
}
.selected .menu-list-vaults-icon-dark,
.selected .menu-list-vaults-icon-light,
.menu-item:hover .menu-list-vaults-icon-dark,
.menu-item:hover .menu-list-vaults-icon-light {
  filter: invert(77%) sepia(27%) saturate(1809%) hue-rotate(331deg)
    brightness(102%) contrast(83%);
}
.menu-list-icon {
  @apply mr-3 lg:mr-5 dark:text-zink-450;
}
.menu-sub-item-icon {
  @apply mr-0.5 lg:mr-1 dark:text-zink-450;
}
.expend-icon {
  @apply duration-100 absolute left-48 dark:text-zink-450;
}
.selected .expend-icon,
.menu-item:hover .expend-icon {
  @apply text-amber-550;
}
.menu-label {
  @apply dark:text-zink-450 font-bold text-ssm lg:text-sm;
}
.menu-sublabel {
  @apply dark:text-zink-450 font-light text-2xs lg:text-xs italic absolute w-32 left-0;
}
.selected .menu-label,
.selected .menu-sublabel,
.selected .menu-list-icon,
.menu-item:hover .menu-label,
.menu-item:hover .menu-sublabel,
.menu-item:hover .menu-list-icon,
.menu-sub-item:hover .menu-label,
.menu-sub-item:hover .menu-sub-item-icon {
  @apply text-amber-550;
}
.acs {
  @apply font-medium text-sssm lg:text-sm;
}
.number {
  @apply text-zink-450 dark:text-zink-350;
}
.w-15 {
  width: 60px;
}
.sidebar-backdrop {
  @apply fixed top-0 left-0 cursor-pointer bg-black opacity-80;
  width: 100vw;
  height: 100vh;
  z-index: 998;
}
.sidebar-panel {
  left: 0;
  top: 0;
  z-index: 999;
}
.bg-opacity {
  --tw-bg-opacity: 0.9 !important;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.opacity-transition-logo-enter-active {
  transition: opacity 0.3s;
  transition-delay: 0.7s;
}
.opacity-transition-logo-leave-active {
  transition: opacity 0.3s;
}
.opacity-transition-logo-enter-from,
.opacity-transition-logo-leave-to {
  opacity: 0;
}
</style>
