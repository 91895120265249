import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Vaults from '@/pages/Vaults.vue';
import StakeMint from '@/pages/StakeMint.vue';
import LeaderboardMyNfts from '@/pages/LeaderboardMyNfts.vue';
import NftViewer from '@/pages/NftViewer.vue';
import { configService } from '@/services/config/config.service';
import useNetwork from '@/composables/useNetwork';

const { networkId } = useNetwork();
const userNetworkConfig = configService.getNetworkConfig(networkId.value);

const NetworkShortName = userNetworkConfig.shortName.toLowerCase();

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    redirect: `/${NetworkShortName}/vaults/all/`,
  },
  { path: '/:chainName/vaults/:filterKey', name: 'vaults', component: Vaults },
  { path: '/:chainName/farms/:filterKey', name: 'farms', component: Vaults },
  {
    path: '/:chainName/governance_vaults/:filterKey',
    name: 'governance_vaults',
    component: Vaults,
  },
  {
    path: '/:chainName/onsen_ido/:filterKey',
    name: 'onsen_ido',
    component: Vaults,
  },
  {
    path: '/:chainName/deprecated/:filterKey',
    name: 'deprecated_vaults',
    component: Vaults,
  },
  { path: '/:chainName/stake_mint', name: 'stake_mint', component: StakeMint },
  {
    path: '/:chainName/leaderboard/:key',
    name: 'leaderboard',
    component: LeaderboardMyNfts,
  },
  {
    path: '/:chainName/my_nfts',
    name: 'my_nfts',
    component: LeaderboardMyNfts,
  },
  {
    path: '/:chainName/nfts/:key/:id',
    name: 'nft_viewer',
    component: NftViewer,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: `/${NetworkShortName}/vaults/all/`,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
