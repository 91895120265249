
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  props: {
    nft: { type: Object },
  },

  setup() {
    const route = useRoute();
    const { explorerLinks } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      route,
      explorerLinks,
      upToLargeBreakpoint,
    };
  },
});
