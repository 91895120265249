<template>
  <div class="p-4 lg:px-6 lg:pt-8 lg:pb-6 flex items-center lg:justify-between">
    <div v-if="upToLargeBreakpoint" class="w-1/12 flex">
      <BalIcon
        v-if="selectedVaultId === -1"
        name="line-md:menu-fold-right"
        size="lg"
        @click="setMenuShow(true)"
        :class="{ 'text-zink-650': !showTitle }"
      />
      <BalBtn
        v-else
        circle
        outline
        color="gray"
        border="thin"
        size="xs"
        @click="setSelectedVaultId(-1)"
      >
        <BalIcon name="akar-icons:chevron-left" size="sm" />
      </BalBtn>
    </div>
    <div
      v-if="!upToLargeBreakpoint || showTitle"
      class="top-nav-page-title-con"
    >
      <p class="top-nav-page-title">
        {{
          router.currentRoute.value.name && showTitle
            ? $t(router.currentRoute.value.name)
            : ''
        }}
      </p>
      <p class="top-nav-page-subtitle" v-if="!upToLargeBreakpoint">
        {{
          router.currentRoute.value.name &&
          subTitleMatch[router.currentRoute.value.name]
            ? $t(subTitleMatch[router.currentRoute.value.name])
            : ''
        }}
      </p>
    </div>
    <AppXNoticeBoxVue v-if="!upToLargeBreakpoint || !showTitle" />
    <div
      class="flex justify-end"
      :class="!upToLargeBreakpoint ? 'w-2/3' : showTitle ? 'w-3/12' : 'w-11/12'"
    >
      <V1Switch v-if="!upToLargeBreakpoint || !showTitle" />
      <!-- <BuyBNB /> -->
      <DarkModeToggle class="mr-2 lg:mr-4" />
      <SetLocale
        :class="!upToLargeBreakpoint ? 'mr-4' : showTitle ? 'mr-0' : 'mr-2'"
      />
      <NetworkSwitch
        class="mr-2 lg:mr-4"
        v-if="!upToLargeBreakpoint || !showTitle"
      />
      <AppNavActions v-if="!upToLargeBreakpoint || !showTitle" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import AppNavActions from './AppNavActions.vue';
// import BuyBNB from './BuyBNB.vue';
import AppXNoticeBoxVue from './AppXNoticeBox.vue';
import V1Switch from './V1Switch.vue';
import DarkModeToggle from './DarkModeToggle.vue';
import SetLocale from './SetLocale.vue';
import NetworkSwitch from './NetworkSwitch.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useVaultsList from '@/composables/useVaultsList';
import useMenuCollapsed from '@/composables/useMenuCollapsed';
import { showNavTitle } from '@/lib/utils';

export default defineComponent({
  components: {
    NetworkSwitch,
    AppXNoticeBoxVue,
    V1Switch,
    AppNavActions,
    // BuyBNB,
    DarkModeToggle,
    SetLocale,
  },

  setup() {
    const router = useRouter();
    const { setMenuShow } = useMenuCollapsed();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { selectedVaultId, setSelectedVaultId } = useVaultsList();

    // DATA
    const subTitleMatch = ref({
      vaults: 'auto_compound_your_investment',
      farms: 'featured_by_acsi_finance',
      governance_vaults: 'core_onsen_ido',
    });

    // COMPUTED
    const showTitle = computed(() =>
      showNavTitle(router.currentRoute.value.name)
    );

    return {
      selectedVaultId,
      setSelectedVaultId,
      upToLargeBreakpoint,
      setMenuShow,
      router,
      // datas
      subTitleMatch,
      // computed
      showTitle,
    };
  },
});
</script>

<style scoped>
.top-nav-page-title-con {
  @apply text-center lg:text-left w-8/12 lg:w-1/5 relative;
}
.top-nav-page-title {
  @apply text-lg lg:text-xl font-semibold;
}
.top-nav-page-subtitle {
  @apply absolute font-light text-xs italic;
}
</style>
