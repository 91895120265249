const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0xAa7Ebcc355A797a3225014d985aD89c2650fCe84',
  wbnb: '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a', // WONE
  busd: '0xEf977d2f931C1978Db5F6747666fa1eACB0d0339', // DAI
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [
    '0x3c422448fc8a94f4165557284ef6fcd8491f31d5', // defira WONE-DAI
    '0x6bbdd50c2cf7c370cba6eac15d13deb21681a509', // defira stONE-WONE
    '0x7af0339d5145bd9f95f6d07cba0ad006d56595bb', // defira 1WBTC-WONE
  ],
  additionalPricingPairsDeprecated: [],
};

Config.farmVaults = [
  {
    vault: {
      address: '0x569B344AD6BF087A285f0D415d0066028921D873',
      tokenSymbol: 'ONE',
      tokenImage: 'one.png',
      isGasToken: true,
      isBestDayReturn: true,
      firstDepositBlock: 24200142,
      warning: `Deposits on Tranquil Vaults are paused until Tranquil re-enables borrowing. Withdrawals are functioning normally. <a class="italic underline text-blue-50" href="https://twitter.com/acryptosx/status/1544896974658232321" target="_blank">Read More</a>`,
    },
    tags: ['tranquil', 'warning'],
  },
  {
    vault: {
      address: '0x35e801C62464A379B09D1EeD21b5187522712B6d',
      tokenSymbol: 'stONE',
      tokenImage: 'stOne.png',
      isBestDayReturn: true,
      firstDepositBlock: 24201236,
      warning: `Deposits on Tranquil Vaults are paused until Tranquil re-enables borrowing. Withdrawals are functioning normally. <a class="italic underline text-blue-50" href="https://twitter.com/acryptosx/status/1544896974658232321" target="_blank">Read More</a>`,
    },
    tags: ['tranquil', 'warning'],
  },
  {
    vault: {
      address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
      tokenSymbol: '1BTC',
      tokenImage: 'btc.svg',
      tokenDecimals: 8,
      isBestDayReturn: true,
      fromBlock: 25253358,
      defaultRoiDay: 0.086 / 365,
      warning: `Deposits on Tranquil Vaults are paused until Tranquil re-enables borrowing. Withdrawals are functioning normally. <a class="italic underline text-blue-50" href="https://twitter.com/acryptosx/status/1544896974658232321" target="_blank">Read More</a>`,
    },
    tags: ['tranquil', 'warning'],
  },
  {
    vault: {
      address: '0x2Dd5a62a5D4E4D20622061C823f9648E3078012d',
      tokenSymbol: 'ONE',
      tokenImage: 'one.png',
      isGasToken: true,
      isBestDayReturn: true,
      fromBlock: 25414629,
      defaultRoiDay: 0.1647038 / 365,
      warning: `Aave Harmony Vaults are paused as the underlying protocol has been exploited. <a class="italic underline text-blue-50" href="https://t.me/acryptos9/212644" target="_blank">Read More</a>`,
    },
    tags: ['aave', 'warning'],
  },
];

export { Config };
