<template>
  <p class="text-sm font-normal mt-3 text-center">Audited by</p>
  <div class="grid grid-cols-2 gap-4 mb-8 mt-2">
    <div
      @click="openLink('https://docs.acryptos.com/security-and-risks#audits')"
      class="audit-card cursor-pointer"
    >
      <img class="w-16" src="~@/assets/images/hacken-logo.png" />
    </div>
    <div
      @click="openLink('https://www.certik.org/projects/acryptos')"
      class="audit-card cursor-pointer"
    >
      <img
        class="w-16"
        :src="
          darkMode
            ? 'images/certik-logo-white.png'
            : 'images/certik-logo-black.png'
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useDarkMode from '@/composables/useDarkMode';

export default defineComponent({
  setup() {
    const { darkMode } = useDarkMode();

    // METHODS
    const openLink = (url) => {
      window.open(url, '_blank').focus();
    };

    return {
      darkMode,
      // methods
      openLink,
    };
  },
});
</script>

<style scoped>
.audit-card {
  @apply bg-zink-40 dark:bg-zink-750 rounded-lg py-1.5 text-center text-sm flex flex-col items-center col-span-1;
}
</style>
