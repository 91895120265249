import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between items-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-xs text-zink-500" }
const _hoisted_4 = { class: "flex items-center ml-2" }
const _hoisted_5 = { class: "mr-1" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "h-96 overflow-y-scroll"
}
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "border-b dark:border-zink-700 flex" }
const _hoisted_10 = { class: "overflow-hidden rounded-lg" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "h-96 flex items-center justify-center"
}
const _hoisted_13 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_Search = _resolveComponent("Search")!
  const _component_TokenListsListItem = _resolveComponent("TokenListsListItem")!
  const _component_TokenListItem = _resolveComponent("TokenListItem")!
  const _component_RecycleScroller = _resolveComponent("RecycleScroller")!
  const _component_BalLoadingIcon = _resolveComponent("BalLoadingIcon")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
    "no-content-pad": ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.selectTokenList)
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 0,
                color: "zink",
                size: "xs",
                class: "mr-2",
                flat: "",
                circle: "",
                onClick: _ctx.onListExit
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, {
                    name: "akar-icons:arrow-left",
                    size: "sm"
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1)
        ]),
        (!_ctx.selectTokenList)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelectTokenList && _ctx.toggleSelectTokenList(...args))),
              class: "flex items-center group cursor-pointer"
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('tokenLists')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTokenLists, (tokenlist, i) => {
                    return (_openBlock(), _createElementBlock("img", {
                      key: i,
                      src: _ctx.resolve(tokenlist.logoURI),
                      class: "rounded-full inline-block bg-white shadow w-6 h-6"
                    }, null, 8, _hoisted_6))
                  }), 128))
                ]),
                _createVNode(_component_BalIcon, {
                  name: "akar-icons:chevron-down",
                  size: "sm",
                  class: "ml-1 text-blue-500 group-hover:text-pink-500 group-focus:text-pink-500 transition-all duration-200 ease-out"
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.selectTokenList)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Search, {
              modelValue: _ctx.query,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
              placeholder: _ctx.$t('searchByName'),
              class: "px-4 py-3 flex-auto border-b dark:border-zink-700"
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("div", null, [
              (Object.keys(_ctx.tokenLists).length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokenLists, (tokenList, uri) => {
                      return (_openBlock(), _createBlock(_component_TokenListsListItem, {
                        key: uri,
                        isActive: _ctx.isActiveList(uri),
                        tokenlist: tokenList,
                        uri: uri,
                        onToggle: ($event: any) => (_ctx.onToggleList(uri))
                      }, null, 8, ["isActive", "tokenlist", "uri", "onToggle"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    textContent: _toDisplayString(_ctx.$t('errorNoLists')),
                    class: "h-96 flex items-center justify-center"
                  }, null, 8, _hoisted_8))
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Search, {
                modelValue: _ctx.query,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query) = $event)),
                placeholder: _ctx.$t('searchBy'),
                class: "px-4 py-3 flex-auto"
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_ctx.tokens.length > 0)
                ? (_openBlock(), _createBlock(_component_RecycleScroller, {
                    key: 0,
                    class: "h-96 overflow-y-scroll",
                    items: _ctx.tokens,
                    "item-size": 62,
                    "key-field": "address",
                    buffer: 100
                  }, {
                    default: _withCtx(({ item: token }) => [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.onSelectToken(token.address))
                      }, [
                        _createVNode(_component_TokenListItem, {
                          token: token,
                          balanceLoading: _ctx.dynamicDataLoading
                        }, null, 8, ["token", "balanceLoading"])
                      ], 8, _hoisted_11)
                    ]),
                    _: 1
                  }, 8, ["items"]))
                : (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_BalLoadingIcon)
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      textContent: _toDisplayString(_ctx.$t('errorNoTokens')),
                      class: "h-96 p-12 text-center text-zink-500"
                    }, null, 8, _hoisted_13))
            ])
          ], 64))
    ]),
    _: 1
  }))
}