<template>
  <div class="effect"></div>
</template>
<style scoped>
@media (max-width: 1024px) {
  .effect {
    height: 1100px;
  }
}
@media (min-width: 1024px) {
  .effect {
    height: 2314px;
  }
}
.effect {
  @apply absolute w-full;
  background-image: url('~@/assets/images/effect.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}
</style>
