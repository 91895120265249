import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_AppNavAccountBtn = _resolveComponent("AppNavAccountBtn")!

  return (_ctx.connectStatus !== 'connected')
    ? (_openBlock(), _createBlock(_component_BalBtn, {
        key: 0,
        loading: _ctx.connectStatus === 'connecting',
        "loading-label": _ctx.$t('connecting'),
        color: "amber",
        size: _ctx.upToLargeBreakpoint ? 'xs' : 'sm',
        onClick: _ctx.connectWallet,
        rounded: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            textContent: _toDisplayString(_ctx.$t('connect_wallet'))
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["loading", "loading-label", "size", "onClick"]))
    : (_openBlock(), _createBlock(_component_AppNavAccountBtn, { key: 1 }))
}