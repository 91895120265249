
import { defineComponent, ref } from 'vue';
import { showApr } from '@/lib/vault/farmVaultUtils';
import { fwp } from '@/lib/utils/bn';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object },
  },

  setup() {
    // DATA
    const showRewardsDetail = ref(false);
    const dataTotal = ref([
      {
        label: 'apy',
        min: 'apyMin',
        max: 'apyMax',
        value: 'apy',
      },
      {
        label: 'apr',
        min: 'aprMin',
        max: 'aprMax',
        value: 'apr',
      },
      {
        label: 'daily',
        min: 'dailyMin',
        max: 'dailyMax',
        value: 'daily',
      },
    ]);
    const dataSwap = ref([
      {
        label: 'swap_apy',
        value: 'swapApy',
      },
      {
        label: 'swap_apr',
        value: 'swapApr',
      },
      {
        label: 'swap_daily',
        value: 'swapDaily',
      },
    ]);
    const dataVault = ref([
      {
        label: 'vault_apy',
        value: 'vaultApy',
      },
      {
        label: 'vault_apr',
        value: 'vaultApr',
      },
      {
        label: 'vault_daily',
        value: 'vaultDaily',
      },
    ]);
    const dataFarm = ref([
      {
        label: 'farm_apy',
        min: 'farmApyMin',
        max: 'farmApyMax',
        boosted: 'farmApyBoosted',
        value: 'farmApy',
      },
      {
        label: 'farm_apr',
        min: 'farmAprMin',
        max: 'farmAprMax',
        boosted: 'farmAprBoosted',
        value: 'farmApr',
      },
      {
        label: 'farm_daily',
        min: 'farmDailyMin',
        max: 'farmDailyMax',
        boosted: 'farmDailyBoosted',
        value: 'farmDaily',
      },
    ]);

    return {
      fwp,
      showApr,
      // data
      showRewardsDetail,
      dataTotal,
      dataSwap,
      dataVault,
      dataFarm,
    };
  },
});
