import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { supportedNetworks } from '@/constants';
import { VaultsController } from '@/lib/vault/vaults-controller';
import {
  initFarmVault,
  account,
  walletEmpty,
  vaultEmpty,
  walletFarmEmpty,
  farmEmpty,
  farmRewardsEmpty,
  pendingEmpty,
  updateApy,
  updateTvl,
} from '@/lib/vault/farmVaultUtils';
import { bn } from '@/lib/utils/bn';
import useNetwork from '@/composables/useNetwork';

const acsStats = ref({});
const vaultsControllers = ref(
  [...Array(supportedNetworks.length).keys()].map(() => new VaultsController())
);
const isAllDataLoaded = ref(false);

export default function useVaults() {
  const { t } = useI18n();
  const { networkId } = useNetwork();

  // METHODS
  const formatFarmVault = (item, initialFarmVault) => {
    let farmVault = {
      ...initialFarmVault,
      ...(item as Record<string, unknown>),
    };
    farmVault.account = account(item);
    farmVault.walletEmpty = walletEmpty(farmVault);
    farmVault.vaultEmpty = vaultEmpty(farmVault);
    farmVault.walletFarmEmpty = walletFarmEmpty(farmVault);
    farmVault.farmEmpty = farmEmpty(farmVault);
    farmVault.farmRewardsEmpty = farmRewardsEmpty(farmVault);
    farmVault.pendingEmpty = pendingEmpty(farmVault);

    if (farmVault.vault || farmVault.farm) {
      farmVault = updateApy(farmVault);
      farmVault = updateTvl(farmVault);
    }
    return farmVault;
  };

  // COMPUTED
  const vaultsController = computed(
    () =>
      vaultsControllers.value.find(
        (item, index) => supportedNetworks[index].chainId === networkId.value
      ) || new VaultsController()
  );

  const farmVaults = computed(() => {
    // @ts-ignore
    return vaultsController.value.farmVaults.value || [];
  });

  const farmVaultsDeprecated = computed(() => {
    // @ts-ignore
    return vaultsController.value.farmVaultsDeprecated.value || [];
  });

  vaultsController.value.addEventListener('update', () => {
    acsStats.value =
      {
        ...vaultsControllers.value.find(
          (item) => item.connect.networkConfig.chainId === 56
        )?.acsStats,
        tvl: vaultsControllers.value.reduce(
          (total, item) => total.iadd(item.acsStats?.tvl || bn(0)),
          bn(0)
        ),
        userTvl: vaultsControllers.value.reduce(
          (total, item) => total.iadd(item.acsStats?.userTvl || bn(0)),
          bn(0)
        ),
      } || {};
  });

  const formatedFarmVaults = computed(() =>
    farmVaults.value.map((item, i) =>
      formatFarmVault(item, initFarmVault(item, i, t))
    )
  );

  const formatedFarmVaultsDeprecated = computed(() =>
    farmVaultsDeprecated.value.map((item, i) =>
      formatFarmVault(item, initFarmVault(item, i, t))
    )
  );

  // METHODS
  const init = () => {
    setInterval(async () => {
      if (!farmVaults.value.length) {
        return;
      }
      if (
        farmVaults.value
          .map(
            (farmVault) =>
              (!farmVault.farm || farmVault.farm.isInited) &&
              (!farmVault.vault || farmVault.vault.isInited)
          )
          .filter((isInited) => !isInited).length
      ) {
        return;
      }

      isAllDataLoaded.value = true;
      return true;
    }, 5000);
  };
  const resolveVaults = async (connector, index) => {
    await vaultsControllers.value[index].resolve(connector);
  };

  const initDeprecated = async () => {
    await vaultsController.value.initDeprecated();
  };

  return {
    // datas
    vaultsController,
    acsStats,
    isAllDataLoaded,
    formatedFarmVaults,
    formatedFarmVaultsDeprecated,
    // methods
    init,
    initDeprecated,
    resolveVaults,
  };
}
