
import { defineComponent, ref } from 'vue';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';
import { fwp } from '@/lib/utils/bn';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object },
    additionalPoolRewardsToShow: { type: Object },
  },

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();

    // DATA
    const showOnsenDetail = ref(false);

    // DATA
    return {
      fwp,
      showTokenBalance,
      upToLargeBreakpoint,
      // data
      showOnsenDetail,
    };
  },
});
