<template>
  <div class="mechas-container">
    <img src="~@/assets/images/mechas-effect.png" class="mechas-effect" />
    <img src="~@/assets/images/mechas.png" class="mechas" />
    <p class="mechas-coming-soon">{{ $t('coming_soon') }}</p>
    <p class="mechas-title-white">{{ $t('the_mechas') }}</p>
    <p class="mechas-title">{{ $t('the_mechas') }}</p>
    <p class="mechas-desc" v-html="$t('mechas_desc')"></p>
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .mechas-container {
    margin-top: -115px;
    height: 358px;
  }
  .mechas {
    top: 60px;
    height: 198px;
  }
  .mechas-effect {
    top: 75px;
    height: 240px;
  }
  .mechas-coming-soon {
    top: 70px;
    right: calc(34% - 40px);
  }
  .mechas-title {
    top: 105px;
    right: calc(34% - 95px);
  }
  .mechas-title-white {
    top: 106px;
    right: calc(34% - 96px);
  }
  .mechas-desc {
    top: 175px;
    right: calc(35% - 120px);
  }
}
@media (min-width: 440px) {
  .mechas-container {
    margin-top: -115px;
    height: 485px;
  }
  .mechas {
    top: 100px;
    height: 260px;
  }
  .mechas-effect {
    top: 95px;
    height: 270px;
  }
  .mechas-coming-soon {
    top: 120px;
    right: calc(34% - 40px);
  }
  .mechas-title {
    top: 150px;
    right: calc(34% - 95px);
  }
  .mechas-title-white {
    top: 151px;
    right: calc(34% - 96px);
  }
  .mechas-desc {
    top: 220px;
    right: calc(35% - 120px);
  }
}
@media (min-width: 640px) {
  .mechas-container {
    margin-top: -145px;
    height: 585px;
  }
  .mechas {
    top: 100px;
    height: 375px;
  }
  .mechas-effect {
    top: 95px;
    height: 390px;
  }
  .mechas-coming-soon {
    top: 160px;
    right: calc(34% - 65px);
  }
  .mechas-title {
    top: 210px;
    right: calc(34% - 140px);
  }
  .mechas-title-white {
    top: 211px;
    right: calc(34% - 143px);
  }
  .mechas-desc {
    top: 300px;
    right: calc(33% - 150px);
  }
}
@media (min-width: 768px) {
  .mechas-container {
    margin-top: -190px;
    height: 780px;
  }
  .mechas {
    top: 190px;
    height: 450px;
  }
  .mechas-effect {
    top: 245px;
    height: 468px;
  }
  .mechas-coming-soon {
    top: 210px;
    right: calc(30% - 45px);
  }
  .mechas-title {
    top: 280px;
    right: calc(23% - 60px);
  }
  .mechas-title-white {
    top: 281px;
    right: calc(23% - 63px);
  }
  .mechas-desc {
    top: 400px;
    right: calc(20% - 70px);
  }
}
@media (min-width: 1024px) {
  .mechas-container {
    margin-top: -235px;
    height: 974px;
  }
  .mechas {
    top: 190px;
    height: 600px;
  }
  .mechas-effect {
    top: 270px;
    height: 624px;
  }
  .mechas-coming-soon {
    top: 260px;
    right: calc(29% - 50px);
  }
  .mechas-title {
    top: 350px;
    right: calc(23% - 85px);
  }
  .mechas-title-white {
    top: 351px;
    right: calc(23% - 89px);
  }
  .mechas-desc {
    top: 500px;
    right: calc(22% - 80px);
  }
}
@media (min-width: 1280px) {
  .mechas-container {
    margin-top: -285px;
    height: 1218px;
  }
  .mechas {
    top: 230px;
    height: 750px;
  }
  .mechas-effect {
    top: 310px;
    height: 780px;
  }
  .mechas-coming-soon {
    top: 280px;
    right: calc(29% - 55px);
  }
  .mechas-title {
    top: 438px;
    right: calc(20% - 125px);
  }
  .mechas-title-white {
    top: 440px;
    right: calc(20% - 130px);
  }
  .mechas-desc {
    top: 645px;
    right: calc(22% - 95px);
  }
}
@media (min-width: 1536px) {
  .mechas-container {
    margin-top: -350px;
    height: 1462px;
  }
  .mechas {
    top: 290px;
    height: 900px;
  }
  .mechas-effect {
    top: 400px;
    height: 950px;
  }
  .mechas-coming-soon {
    top: 365px;
    right: calc(30% - 80px);
  }
  .mechas-title {
    top: 524px;
    right: calc(21% - 150px);
  }
  .mechas-title-white {
    top: 526px;
    right: calc(21% - 156px);
  }
  .mechas-desc {
    top: 804px;
    right: calc(23% - 120px);
  }
}
.mechas-container {
  @apply w-full bg-transparent relative;
  background-image: url('~@/assets/images/anniversary-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}
.mechas,
.mechas-effect {
  @apply absolute;
}
.mechas-coming-soon {
  @apply absolute text-white font-black text-base sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl italic;
  transform: rotate(-9deg);
}
.mechas-title,
.mechas-title-white {
  @apply absolute font-normal text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl 2xl:text-8xl;
  font-family: Gloss And Bloom;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.mechas-title {
  @apply text-black;
}
.mechas-title-white {
  @apply text-white;
}
.mechas-desc {
  @apply absolute text-white italic text-center text-sssm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl;
  font-family: DINPro-CondMedium;
  transform: rotate(-9deg);
}
</style>
