
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import useBreakpoints from '@/composables/useBreakpoints';
import useBuyTokenModal from '@/composables/useBuyTokenModal';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { configService } from '@/services/config/config.service';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';
import { fw } from '@/lib/utils/bn';
import addresses from '@/constants/addresses';

const { nativeAsset } = configService.network;

export default defineComponent({
  components: {},

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { stakeMint } = useNfts();
    const { formatedFarmVaults } = useVaults();
    const { toggleBuyTokenModal } = useBuyTokenModal();

    // COMPUTED
    const balance = computed(() => {
      const acsVaultFarm = formatedFarmVaults.value.find(
        (item) => item.name === 'ACS'
      );
      const acsiVaultFarm = formatedFarmVaults.value.find(
        (item) => item.name === 'ACSI'
      );
      return {
        acs: acsVaultFarm.walletEmpty
          ? null
          : acsVaultFarm.vault.stats.value.userBalanceNormalized,
        acsi: acsiVaultFarm.walletEmpty
          ? null
          : acsiVaultFarm.vault.stats.value.userBalanceNormalized,
      };
    });
    const balanceShown = computed(() => {
      return {
        acs: !balance.value.acs
          ? '--'
          : `${showTokenBalance(balance.value.acs)} ACS`,
        acsi: !balance.value.acsi
          ? '--'
          : `${showTokenBalance(balance.value.acsi)} ACSI`,
      };
    });

    // DATA
    const ranks = [
      {
        rank: `${t('rank').toUpperCase()} 1`,
        title: t('samurai'),
        desc: `(${t('lower')} 1000)`,
      },
      {
        rank: `${t('rank').toUpperCase()} 2`,
        title: t('daimyo'),
        desc: `(${t('next').toUpperCase()} 800)`,
      },
      {
        rank: `${t('rank').toUpperCase()} 3`,
        title: t('shogun'),
        desc: `(${t('top')} 88)`,
      },
    ];
    const stakeMintAmount = ref({ acs: '', acsi: '' });
    const stakeMinting = ref({ acs: false, acsi: false });
    const nftCols = ['acs', 'acsi'];

    // METHODS
    const stakeMintAction = async (key) => {
      stakeMinting.value[key] = true;
      const res = await stakeMint(
        stakeMintAmount.value[key],
        key === 'acs' ? 0 : 1
      );
      stakeMinting.value[key] = false;
      if (res) {
        router.push({ path: 'my_nfts' });
      }
    };
    const goTo = (des) => {
      router.push({ path: `/BSC/${des}` });
    };
    const setMax = (key) => {
      if (balance.value[key]) {
        stakeMintAmount.value[key] = parseFloat(fw(balance.value[key]));
      }
    };
    const openGetAcsiModal = (key) => {
      toggleBuyTokenModal(true, nativeAsset.address, addresses[key]);
    };

    return {
      upToLargeBreakpoint,
      // computed
      balanceShown,
      // data
      ranks,
      nftCols,
      stakeMinting,
      stakeMintAmount,
      // methods
      goTo,
      setMax,
      stakeMintAction,
      openGetAcsiModal,
    };
  },
});
