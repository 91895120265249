
import { defineComponent, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AppNav from '@/components/navs/AppNav/AppNav.vue';
import AppSideNav from '@/components/navs/AppSideNav/index.vue';
import BuyLpModal from '@/components/modals/BuyLpModal.vue';
import BuyTokenModal from '@/components/modals/BuyTokenModal.vue';
import SwitchToBnbModal from '@/components/modals/SwitchToBnbModal.vue';
import useWeb3 from '@/services/web3/useWeb3';
import useBuyLpModal from '@/composables/useBuyLpModal';
import useSwitchToBnbModal from '@/composables/useSwitchToBnbModal';
import useBuyTokenModal from '@/composables/useBuyTokenModal';

export default defineComponent({
  components: {
    SwitchToBnbModal,
    BuyTokenModal,
    BuyLpModal,
    AppNav,
    AppSideNav,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const { init } = useWeb3();
    const { isBuyTokenModalVisible, toggleBuyTokenModal } = useBuyTokenModal();
    const { isBuyLpModalVisible, toggleBuyLpModal, poolId } = useBuyLpModal();
    const { isSwitchToBnbModalVisible, toggleSwitchToBnbModal } =
      useSwitchToBnbModal();

    // CALLBACKS
    onBeforeMount(async () => {
      const temp = window.location.hash.split('/');
      if (temp[1] && temp[1] !== 'bsc' && temp[2] && temp[2] !== 'vaults') {
        router.push({
          path: `/${temp[1]}/vaults/all`,
        });
      }
      store.dispatch('app/init');
      init();
    });

    return {
      poolId,
      isBuyLpModalVisible,
      toggleBuyLpModal,
      isBuyTokenModalVisible,
      toggleBuyTokenModal,
      isSwitchToBnbModalVisible,
      toggleSwitchToBnbModal,
    };
  },
});
