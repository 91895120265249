import { ref } from 'vue';

/** STATE */
const isSwitchToBnbModalVisible = ref(false);

export default function useBuyLpModal() {
  // METHODS
  const toggleSwitchToBnbModal = (value: boolean) => {
    if (value !== undefined && typeof value === 'boolean') {
      isSwitchToBnbModalVisible.value = value;
      return;
    }
    isSwitchToBnbModalVisible.value = !isSwitchToBnbModalVisible.value;
  };

  return {
    // refs
    isSwitchToBnbModalVisible,

    // methods
    toggleSwitchToBnbModal,
  };
}
