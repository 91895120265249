
import BalCard from '../BalCard/BalCard.vue';
import AnimatePresence from '../../animate/AnimatePresence.vue';
import { defineComponent, ref, toRefs, watch, computed } from 'vue';

export default defineComponent({
  name: 'BalModal',

  components: {
    BalCard,
    AnimatePresence,
  },

  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: '' },
    noPad: { type: Boolean, default: false },
    noContentPad: { type: Boolean, default: false },
    rounded: { type: String, default: '' },
    bgColor: { type: String, default: '' },
    width: { type: String, default: '' },
  },

  setup(props) {
    const { show } = toRefs(props);
    const showContent = ref(show.value);

    // Watchers
    watch(show, (newVal) => {
      showContent.value = newVal;
    });

    // Methods
    function hide(): void {
      showContent.value = false;
    }

    // Computed
    const widthClasses = computed(() => {
      if (props.width === 'xl') {
        return 'max-w-xl';
      } else if (props.width === 'fit') {
        return 'w-fit';
      }
      return 'max-w-md';
    });

    return {
      widthClasses,
      showContent,
      hide,
    };
  },
});
