import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f7c6842"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "min-h-full p-4 rounded-2xl bg-white dark:bg-zink-660"
}
const _hoisted_2 = { class: "token-name" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { class: "mt-3 flex justify-between items-center" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = {
  key: 0,
  class: "crown"
}
const _hoisted_11 = { class: "mt-3 lg:mt-5 grid grid-cols-4 gap-2" }
const _hoisted_12 = { class: "detail-container" }
const _hoisted_13 = { class: "detail-label" }
const _hoisted_14 = { class: "detail-value" }
const _hoisted_15 = { class: "detail-container" }
const _hoisted_16 = { class: "detail-label" }
const _hoisted_17 = { class: "detail-value" }
const _hoisted_18 = { class: "detail-container" }
const _hoisted_19 = { class: "detail-label" }
const _hoisted_20 = { class: "detail-value" }
const _hoisted_21 = { class: "detail-container" }
const _hoisted_22 = { class: "detail-label" }
const _hoisted_23 = { class: "main-container mt-2 grid grid-cols-2 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_Tags = _resolveComponent("Tags")!
  const _component_Tokens = _resolveComponent("Tokens")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_ActionButtons = _resolveComponent("ActionButtons")!
  const _component_InvestmentPending = _resolveComponent("InvestmentPending")!
  const _component_Rewards = _resolveComponent("Rewards")!
  const _component_Advanced = _resolveComponent("Advanced")!
  const _component_AcsBoost = _resolveComponent("AcsBoost")!

  return (_ctx.isLoadingData)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        rounded: "2xl",
        class: "h-full"
      }))
    : (_ctx.farmVault)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: 
          _ctx.farmVault.tokenImage
            ? '/images/tokens/' + _ctx.farmVault.tokenImage
            : '/images/tokens/' + _ctx.farmVault.tokenSymbol.toLowerCase() + '.svg'
        ,
              onError: _cache[0] || (_cache[0] = ($event: any) => ($event.target.src = '/images/tokens/temp-token.png'))
            }, null, 40, _hoisted_3),
            (_ctx.farmVault.name === 'A2B2 (ACSI)')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "ACS-ACSI-BNB-BUSD (ACSI)"))
              : (_ctx.farmVault.name === 'A2B2 (ACS)')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "ACS-ACSI-BNB-BUSD (ACS)"))
                : (_ctx.farmVault.name === 'A2B2 (WAV)')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "ACS-ACSI-BNB-BUSD (WAV)"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.farmVault.name), 1))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Tags, {
                farmVaultTags: _ctx.farmVault.tags
              }, null, 8, ["farmVaultTags"]),
              _createVNode(_component_Tokens, {
                farmVault: _ctx.farmVault,
                additionalPoolRewardsSorted: _ctx.additionalPoolRewardsSorted
              }, null, 8, ["farmVault", "additionalPoolRewardsSorted"])
            ]),
            (_ctx.upToLargeBreakpoint && _ctx.farmVault.name === 'A2B2 (ACSI)')
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                  _createVNode(_component_BalIcon, {
                    name: "jam:crown",
                    size: "2xs"
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('apy')), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.farmVault.apyShown), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('daily')), 1),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.farmVault.dailyShown), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('tvl')), 1),
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.farmVault.tvlShown), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('balance')), 1),
              _createElementVNode("div", {
                class: _normalizeClass(["detail-value", 
            _ctx.farmVault.farm && !_ctx.farmVault.vaultEmpty
              ? 'text-amber-550'
              : !_ctx.farmVault.walletEmpty || !_ctx.farmVault.walletFarmEmpty
              ? 'text-blue-520 dark:text-emerald-490'
              : ''
          ])
              }, _toDisplayString(_ctx.farmVault.balanceShown), 3)
            ])
          ]),
          _createVNode(_component_ActionButtons, {
            class: "mt-4 lg:mt-6",
            farmVault: _ctx.farmVault
          }, null, 8, ["farmVault"]),
          _createVNode(_component_InvestmentPending, {
            class: "main-container mt-2 lg:mt-4",
            farmVault: _ctx.farmVault,
            additionalPoolRewardsToShow: _ctx.additionalPoolRewardsToShow
          }, null, 8, ["farmVault", "additionalPoolRewardsToShow"]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_Rewards, { farmVault: _ctx.farmVault }, null, 8, ["farmVault"]),
            (_ctx.farmVault.vault)
              ? (_openBlock(), _createBlock(_component_Advanced, {
                  key: 0,
                  farmVault: _ctx.farmVault
                }, null, 8, ["farmVault"]))
              : _createCommentVNode("", true)
          ]),
          (
        _ctx.farmVault.account &&
        ((_ctx.farmVault.farm?.totalWeight && !_ctx.farmVault.farm.deprecated) ||
          _ctx.farmVault.vault?.hasNft)
      )
            ? (_openBlock(), _createBlock(_component_AcsBoost, {
                key: 0,
                farmVault: _ctx.farmVault
              }, null, 8, ["farmVault"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
}