import { onsenApi } from '@/constants';

export interface AcryptosState {
  onsen: any;
}

const state: AcryptosState = {
  onsen: {},
};

const actions = {
  async getOnsen({ commit }) {
    fetch(`${onsenApi}`).then(async (r) => {
      const response = await r.text();
      const onsenData = JSON.parse(response);
      commit('setOnsen', onsenData.onsen);
    });
  },
};

const mutations = {
  setOnsen(state: AcryptosState, val: AcryptosState['onsen']) {
    state.onsen = val;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
