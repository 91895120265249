
import { defineComponent } from 'vue';
import { Config } from '@/lib/vault/config/config';

export default defineComponent({
  props: {
    farmVault: { type: Object },
    additionalPoolRewardsSorted: { type: Object },
  },

  setup() {
    return {
      Config,
    };
  },
});
