
import { defineComponent } from 'vue';
import LeaderboardMyNfts from '@/components/leaderboardmynfts/index.vue';

export default defineComponent({
  components: {
    LeaderboardMyNfts,
  },

  setup() {
    return {};
  },
});
