
import { defineComponent } from 'vue';
// import HarvestOptimizer from './HarvestOptimizer.vue';
import Tvl from './Tvl.vue';
import AcsAcsiPrice from './AcsAcsiPrice.vue';
import PendingHarvest from './PendingHarvest.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  components: {
    Tvl,
    AcsAcsiPrice,
    PendingHarvest,
    // HarvestOptimizer
  },

  setup() {
    const { userNetworkConfig } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      userNetworkConfig,
      upToLargeBreakpoint,
    };
  },
});
