
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import useBreakpoints from '@/composables/useBreakpoints';
import { sortArray } from '@/lib/utils';
import { Config } from '@/lib/vault/config/config';
import { showApr, showTokenBalance } from '@/lib/vault/farmVaultUtils';
import { fwp, bn } from '@/lib/utils/bn';
import Tags from '../Tags.vue';
import ActionButtons from './ActionButtons.vue';
import AcsBoost from './AcsBoost.vue';
import Rewards from './Rewards.vue';
import Advanced from './Advanced.vue';
import InvestmentPending from './InvestmentPending.vue';
import Tokens from './Tokens.vue';

export default defineComponent({
  components: {
    InvestmentPending,
    Rewards,
    Advanced,
    AcsBoost,
    ActionButtons,
    Tokens,
    Tags,
  },

  props: {
    farmVault: { type: Object },
    isLoadingData: { type: Boolean },
  },

  setup(props) {
    const store = useStore();
    const { upToLargeBreakpoint } = useBreakpoints();

    // COMUTED
    const onsen = computed(() => store.state.acryptos.onsen);
    const additionalPoolRewardsFormated = computed(
      () =>
        props.farmVault?.farm?.additionalPoolRewards?.map(
          (additionalPoolReward) => {
            const token = Config.tokens[additionalPoolReward.rewardToken];
            const tokenValue = `${showTokenBalance(
              additionalPoolReward.rewardPending?.mul(
                bn(10 ** (18 - (token.decimals || 18)))
              ) || bn(0)
            )} ${token.symbol} `;
            const usdValue = additionalPoolReward.rewardPendingUsd
              ? `($${fwp(additionalPoolReward.rewardPendingUsd)})`
              : '';

            const onsenJson = onsen.value[token.symbol];
            const date = onsenJson?.eta_human
              ? new Date(onsenJson.eta_human)
              : new Date();

            const totalAmount = `${showApr(
              Number.parseInt(onsenJson?.balance || 0)
            )} ${token.symbol} `;
            const totalAmountUsd = additionalPoolReward.toUsd
              ? `($${showApr(
                  bn(onsenJson?.balance || 0)
                    .mul(additionalPoolReward.toUsd)
                    .div(bn(10 ** 18))
                    .div(bn(10 ** (token.decimals || 0)))
                )})`
              : '';
            return {
              ...additionalPoolReward,
              tokenSymbol: token.symbol,
              tokenValue,
              usdValue,
              totalAmount,
              totalAmountUsd,
              partner: onsenJson?.name || '',
              startEmission: onsenJson?.emission_start || '',
              endEmission: date.toISOString().split('T')[0],
              date,
            };
          }
        ) || []
    );
    const additionalPoolRewardsSorted = computed(() =>
      sortArray(
        additionalPoolRewardsFormated.value.filter(
          (additionalPoolReward) => additionalPoolReward.date > new Date()
        ),
        'endEmission',
        true
      )
    );
    const additionalPoolRewardsWithDiffDays = computed(() =>
      additionalPoolRewardsSorted.value.map((additionalPoolReward) => {
        const date0 = additionalPoolRewardsSorted.value[0].date;
        const diffTime = Math.abs(
          additionalPoolReward.date.getTime() - date0.getTime()
        );
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return {
          ...additionalPoolReward,
          diffDays: `+${diffDays} d`,
        };
      })
    );
    const additionalPoolRewardsToShow = computed(() =>
      additionalPoolRewardsWithDiffDays.value?.filter((item) =>
        item.rewardPending?.gtn(0)
      )
    );

    return {
      upToLargeBreakpoint,
      // computed
      additionalPoolRewardsToShow,
      additionalPoolRewardsSorted,
    };
  },
});
