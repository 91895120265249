const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x1B69ef65150F440ACEa814B5C024842099EceCa5',
  wbnb: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', // WAVAX
  // busd: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70', // DAI.e
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

Config.farmVaults = [
  {
    vault: {
      address: '0x2Dd5a62a5D4E4D20622061C823f9648E3078012d',
      title: 'Acryptos Liquid veFLDX (acsFLDX)',
      tokenSymbol: 'FLDX',
      tokenImage: 'fldx.png',
      isLiquid: true,
      isBestDayReturn: true,
      //defaultRoiDay: 0.0318 / 365,
    },
    tags: ['flairdex', 'liquidstaking'],
  },
];

export { Config };
