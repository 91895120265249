const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x85518cb62E1278D4F1b5a94DE8530f235eAB04DE',
  wbnb: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // MATIC
  // busd: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802', // USDC
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

export { Config };
