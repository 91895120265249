import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "animateRef",
  class: "flex py-3 px-4 highlight items-center leading-5 text-base opacity-0"
}
const _hoisted_2 = { class: "flex-auto" }
const _hoisted_3 = { class: "text-gray text-sm w-40 md:w-60 truncate" }
const _hoisted_4 = { class: "flex flex-col items-end text-right font-medium" }
const _hoisted_5 = {
  key: 3,
  class: "text-gray-500 text-sm font-normal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalLoadingNumber = _resolveComponent("BalLoadingNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BalAsset, {
      address: $props.token.address,
      iconURI: $props.token.logoURI,
      size: 34,
      class: "mr-3"
    }, null, 8, ["address", "iconURI"]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString($props.token.symbol) + " ", 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.token.name), 1)
    ]),
    _createElementVNode("span", _hoisted_4, [
      ($props.balanceLoading)
        ? (_openBlock(), _createBlock(_component_BalLoadingNumber, {
            key: 0,
            type: "token"
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            ($setup.balance > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  ($setup.balance >= 0.0001)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString($setup.fNum($setup.balance, 'token')), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" < 0.0001 ")
                      ], 64))
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("-")
                ], 64))
          ], 64)),
      ($props.balanceLoading)
        ? (_openBlock(), _createBlock(_component_BalLoadingNumber, {
            key: 2,
            type: "fiat",
            numberWidth: "2",
            numberHeight: "4",
            class: "text-sm font-normal"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            ($setup.value > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString($setup.fNum($setup.value, 'usd')), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("-")
                ], 64))
          ]))
    ])
  ], 512))
}