import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { Web3Provider } from '@ethersproject/providers';
import { isAddress } from '@ethersproject/address';
import { supportedNetworks } from '@/constants';
import { Connect } from '@/lib/vault/connect';
import useNetwork from '@/composables/useNetwork';
import useVaults from '../useVaults';
import { rpcProviderService } from '../rpc-provider/rpc-provider.service';
import { configService } from '../config/config.service';
import { web3Service } from './web3.service';

/** STATE */
const connectors = ref(
  [...Array(supportedNetworks.length).keys()].map(() => new Connect())
);
const isInited = ref(false);
const account = ref('');
const blockNumber = ref(0);
const isNetworkReady = ref(false);
const connectStatus = ref('disconnected');
const isInitOtherNetwork = ref(false);

/** MUTATIONS */
function setBlockNumber(n: number): void {
  blockNumber.value = n;
}

/** INIT STATE */
rpcProviderService.initBlockListener(setBlockNumber);

export default function useWeb3() {
  const toast = useToast();
  const { t } = useI18n();
  const { networkId, setNetworkId } = useNetwork();
  const { init: initVaults, resolveVaults, isAllDataLoaded } = useVaults();

  // COMPUTED
  const userNetworkConfig = computed(() =>
    configService.getNetworkConfig(networkId.value)
  );
  const explorerLinks = computed(() => ({
    txLink: (txHash: string) =>
      `${userNetworkConfig.value.explorer}/tx/${txHash}`,
    addressLink: (address: string) =>
      `${userNetworkConfig.value.explorer}/address/${address}`,
    tokenLink: (address: string) =>
      `${userNetworkConfig.value.explorer}/token/${address}`,
  }));
  const isV1Supported = computed(() =>
    isAddress(userNetworkConfig.value.addresses.exchangeProxy)
  );
  const isWalletReady = computed(() => account.value !== '');
  const isSupportedAcsiFinance = computed(() => {
    return userNetworkConfig.value.supportsAcsiFinance;
  });
  const isEIP1559SupportedNetwork = computed(
    () => userNetworkConfig.value.supportsEIP1559
  );
  const isMismatchedNetwork = computed(
    () =>
      isWalletReady.value &&
      userNetworkConfig.value?.key !== userNetworkConfig.value.key
  );
  const connector = computed(
    () =>
      connectors.value.find(
        (item, index) => supportedNetworks[index].chainId === networkId.value
      ) || new Connect()
  );

  connector.value.addEventListener('accountChanged', () => {
    account.value = connector.value.account;
    connectors.value
      .filter((item) => item.networkConfig.chainId !== networkId.value)
      .forEach((item) => item.setAccount(account.value));
  });
  connector.value.addEventListener('networkConnected', () => {
    isNetworkReady.value = true;
  });
  connector.value.addEventListener('updateConnectStatus', () => {
    connectStatus.value = connector.value.connectStatus;
  });
  const userProvider = computed(() => {
    return new Web3Provider(connector.value?.web3?.currentProvider as any);
  });

  // METHODS
  // const getProvider = () => new Web3Provider(provider.value as any);
  const getProvider = () =>
    new Web3Provider(connector.value?.web3?.currentProvider as any);
  const getSigner = () => getProvider().getSigner();
  const connectWallet = async () => {
    try {
      await connector.value?.connect();
      toast(t('alert_connect_wallet_success'));
    } catch (error) {
      console.error(error);
      if (error !== 'Modal closed by user') {
        toast.error(t('alert_connect_wallet_error'));
      }
    }
  };
  const disconnectWallet = async () => {
    try {
      await connector.value?.disconnect();
    } catch (error) {
      console.error(error);
    }
  };
  const init = async () => {
    isInited.value = false;

    //const chainName = window.location.hash.split('/')[1] || 'BSC';
    const _chainName = window.location.hash.split('/')[1] || 'bsc';
    const chainName = _chainName.toLowerCase();
    const network = supportedNetworks.find((item) => item.value === chainName);
    setNetworkId(network?.chainId || 56);

    initVaults();

    const initFunc = async () => {
      try {
        if (!isInited.value) {
          await Promise.all(
            connectors.value.map((item, index) =>
              item.init(
                configService.getNetworkConfig(supportedNetworks[index].chainId)
              )
            )
          );
        }
        if (!connectors.value.filter((item) => !item.web3).length) {
          web3Service.setUserProvider(userProvider);
          isInited.value = true;
          if (interval) {
            clearInterval(interval);
          }
          connectors.value.map(
            (item, index) =>
              item.networkConfig.chainId === networkId.value &&
              resolveVaults(item, index)
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    initFunc();
    const interval = setInterval(async () => {
      initFunc();
    }, 5000);
  };

  // WATCHER
  watch(
    isAllDataLoaded,
    (newVal, oldVal) => {
      if (!oldVal && newVal && !isInitOtherNetwork.value) {
        isInitOtherNetwork.value = true;
        connectors.value.map(
          (item, index) =>
            item.networkConfig.chainId !== networkId.value &&
            resolveVaults(item, index)
        );
      }
    },
    { deep: true }
  );

  return {
    // computed
    explorerLinks,
    userNetworkConfig,
    isV1Supported,
    account,
    connectStatus,
    isWalletReady,
    isNetworkReady,
    isMismatchedNetwork,
    isSupportedAcsiFinance,
    isEIP1559SupportedNetwork,
    // data
    blockNumber,
    connector,
    connectors,
    // methods
    getProvider,
    getSigner,
    setBlockNumber,
    disconnectWallet,
    connectWallet,
    init,
  };
}
