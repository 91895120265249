import { ref } from 'vue';

// DATA
const menuCollapsed = ref(false);
const menuShow = ref(false);

export default function useMenuCollapsed() {
  // METHOD
  const setMenuCollapsed = (value) => {
    menuCollapsed.value = value;
  };
  const setMenuShow = (value) => {
    menuShow.value = value;
  };

  return {
    // data
    menuShow,
    menuCollapsed,
    // method
    setMenuShow,
    setMenuCollapsed,
  };
}
