import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaultsList = _resolveComponent("VaultsList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pb-2 relative vaults", _ctx.upToLargeBreakpoint ? 'px-4 pt-5' : 'px-6'])
  }, [
    _createVNode(_component_VaultsList, { deprecated: _ctx.deprecated }, null, 8, ["deprecated"])
  ], 2))
}