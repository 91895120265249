const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x9EBC3180a34033CA69A1CBa4f46bDfc9034c607C',
  wbnb: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d', // WETH
  // busd: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802', // USDC
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

export { Config };
