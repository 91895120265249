import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c860608"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stake-mint-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Effect = _resolveComponent("Effect")!
  const _component_Release = _resolveComponent("Release")!
  const _component_Anniversary = _resolveComponent("Anniversary")!
  const _component_StakeToMint = _resolveComponent("StakeToMint")!
  const _component_Akira = _resolveComponent("Akira")!
  const _component_Cori = _resolveComponent("Cori")!
  const _component_Satoshi = _resolveComponent("Satoshi")!
  const _component_Mechas = _resolveComponent("Mechas")!
  const _component_GameFi = _resolveComponent("GameFi")!
  const _component_Metaverse = _resolveComponent("Metaverse")!
  const _component_WelcomeHome = _resolveComponent("WelcomeHome")!
  const _component_Join = _resolveComponent("Join")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Effect),
    _createVNode(_component_Release),
    _createVNode(_component_Anniversary),
    _createVNode(_component_StakeToMint),
    _createVNode(_component_Akira),
    _createVNode(_component_Cori),
    _createVNode(_component_Satoshi),
    _createVNode(_component_Mechas),
    _createVNode(_component_GameFi),
    _createVNode(_component_Metaverse),
    _createVNode(_component_WelcomeHome),
    _createVNode(_component_Join)
  ]))
}