import { shallowRef, watch } from 'vue';
import { ethers } from 'ethers';

import abis from '@/constants/abis';
import { BN, bn, fw } from './bn.js';
import { quoter } from './quoter';

// const wbnb = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
// const busd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
// const usdt = '0x55d398326f99059fF775485246999027B3197955';
// const acs = '0x4197C6EF3879a08cD51e5560da5064B773aa1d29';
// const mdx = '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739';
// const eth = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8';
// const sxp = '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A';
// const ust = '0x23396cF899Ca06c4472205fC903bDB4de249D6fC';

const WRAPPED_NATIVE_TOKEN = {
  25: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', // wcros
  56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // wbnb
  250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wftm
  43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', // wavax
  1666600000: '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a', // wone
  1285: '0x98878B06940aE243284CA214f92Bb71a2b032B8A', // wmovr
};

class Pair {
  static pairs = {};

  config: any;
  connect: any;
  contract: any;
  agoContract: any;
  reserves: (typeof BN)[2] = [bn(0), bn(0)];
  totalSupply: typeof BN;
  agoReserves: (typeof BN)[2] = [bn(0), bn(0)];
  agoTotalSupply: typeof BN;
  currentBlock;
  agoBlock;
  roiDay;
  apyDay;
  aprDay;

  constructor(config: { address: string; tokens: string[2] }, connect) {
    Pair.pairs[config.address] = this;

    this.config = config;
    this.connect = connect;

    this.contract = new ethers.Contract(
      this.config.address,
      abis.uniswapV2Pair,
      this.connect.multicallProvider
    );
    this.agoContract = new ethers.Contract(
      this.config.address,
      abis.uniswapV2Pair,
      this.connect.agoMulticallProvider
    );

    this.loadLocalStorage();
    this._updateApy();

    this.poller.value++;
    setInterval(() => {
      this.poller.value++;
    }, 128888);

    this._addQuotes();
  }

  poller = shallowRef(0);

  async updateTotalSupply() {
    this.totalSupply = bn(await this.contract.totalSupply());
  }
  async updateCurrentBlock() {
    this.currentBlock = await this.connect.multicallProvider.getBlockNumber();
  }
  async updateReserves() {
    const i = await this.contract.getReserves();
    this.reserves[0] = bn(i[0]);
    this.reserves[1] = bn(i[1]);
  }

  async getAgoParams() {
    if (
      this.connect.networkConfig.chainId === 56 &&
      (!this.config.fromBlock ||
        this.config.fromBlock < this.connect.agoBlockNumber)
    ) {
      this.agoBlock = this.connect.agoBlockNumber;
      const setAgoReserves = async () => {
        const i = await this.agoContract.getReserves({
          blockTag: this.agoBlock,
        });
        this.agoReserves[0] = bn(i[0]);
        this.agoReserves[1] = bn(i[1]);
      };
      const setAgoTotalSupply = async () => {
        this.agoTotalSupply = bn(
          await this.agoContract.totalSupply({
            blockTag: this.agoBlock,
          })
        );
      };
      await Promise.all([setAgoReserves(), setAgoTotalSupply()]);
    }
  }

  /**
   * WATCHERS
   */
  pollerWatcher = watch(this.poller, async () => {
    await Promise.all([
      this.updateTotalSupply(),
      this.updateCurrentBlock(),
      this.updateReserves(),
      this.poller.value === 1 ? this.getAgoParams() : null,
    ]);

    this.setLocalStorage();
    this._updateApy();
    this.handlePolled();
  });

  loadLocalStorage() {
    const valueKeys = [
      'totalSupply',
      'currentBlock',
      'reserves',
      'agoBlock',
      'agoReserves',
      'agoTotalSupply',
    ];
    for (const valueKey of valueKeys) {
      const _cacheKey = [
        'pair.computedPoller',
        this.config.address,
        valueKey,
      ].join('.');
      // if (!this[valueKey]) {
      const i = localStorage.getItem(_cacheKey);
      if (i) {
        if (valueKey.endsWith('Block')) {
          this[valueKey] = JSON.parse(i);
        } else if (valueKey === 'reserves' || valueKey === 'agoReserves') {
          this[valueKey] = JSON.parse(i).map((hex) => bn(`0x${hex}`));
        } else {
          this[valueKey] = bn(`0x${JSON.parse(i)}`);
        }
      }
      // }
    }
  }

  setLocalStorage() {
    const valueKeys = [
      'totalSupply',
      'currentBlock',
      'reserves',
      'agoBlock',
      'agoReserves',
      'agoTotalSupply',
    ];
    for (const valueKey of valueKeys) {
      const value = this[valueKey];
      if (value) {
        const _cacheKey = [
          'pair.computedPoller',
          this.config.address,
          valueKey,
        ].join('.');
        localStorage.setItem(_cacheKey, JSON.stringify(value));
      }
    }
  }

  _addQuotes() {
    quoter.add([
      {
        path: this.config.tokens,
        quote: () =>
          this.reserves[0].gtn(0) &&
          this.reserves[1].mul(bn(1e18)).div(this.reserves[0]),
        liquidity: () => this.reserves[1],
        pools: [this],
      },
      {
        path: [this.config.tokens[1], this.config.tokens[0]],
        quote: () =>
          this.reserves[1].gtn(0) &&
          this.reserves[0].mul(bn(1e18)).div(this.reserves[1]),
        liquidity: () => this.reserves[0],
        pools: [this],
      },
      {
        path: [
          this.config.address,
          WRAPPED_NATIVE_TOKEN[this.connect.networkConfig.chainId],
        ],
        quote: () => {
          const r = this._reservesBnb();
          return (
            this.totalSupply?.gtn(0) &&
            r &&
            r.mul(bn(1e18)).div(this.totalSupply)
          );
        },
        liquidity: () => this._reservesBnb(),
        pools: [this],
      },
    ]);
  }

  _reservesBnb() {
    const q0 = quoter.q(
      this.config.tokens[0],
      WRAPPED_NATIVE_TOKEN[this.connect.networkConfig.chainId]
    );
    const q1 = quoter.q(
      this.config.tokens[1],
      WRAPPED_NATIVE_TOKEN[this.connect.networkConfig.chainId]
    );
    if (!q0 && !q1) return;
    const bnb0 = q0 && this.reserves[0].mul(q0).div(bn(1e18));
    const bnb1 = q1 && this.reserves[1].mul(q1).div(bn(1e18));
    return (bnb0 || bnb1).add(bnb1 || bnb0);
  }

  _updateApy() {
    if (!this.agoTotalSupply?.gtn(0) || !this.totalSupply?.gtn(0)) return;
    const k = this.reserves[0]
      .mul(this.reserves[1])
      .div(this.totalSupply)
      .mul(bn(1e18))
      .div(this.totalSupply)
      .mul(bn(1e18));
    // console.log(this.tokens[0]Symbol,this.tokens[1]Symbol,fw(this.agoTotalSupply))
    const agoK = this.agoReserves[0]
      .mul(this.agoReserves[1])
      .div(this.agoTotalSupply)
      .mul(bn(1e18))
      .div(this.agoTotalSupply)
      .mul(bn(1e18));
    const daysAgo =
      ((this.currentBlock - this.agoBlock) *
        this.connect.networkConfig.blockTime) /
      86400;
    this.roiDay = (fw(k) ** 0.5 / fw(agoK) ** 0.5) ** (1 / daysAgo) - 1;
    this.apyDay = (this.roiDay + 1) ** 365 - 1;
    this.aprDay = this.roiDay * 365;
    // console.log(this.tokens[0]Symbol, this.tokens[1]Symbol, parseFloat(fw(k))**0.5, parseFloat(fw(agoK))**0.5)
    // console.log(this.tokens[0]Symbol, this.tokens[1]Symbol, this.roiDay*100, this.apyDay*100, this.aprDay*100)
  }

  async handlePolled() {
    // console.log(this.reserves)
  }
}

export { Pair };
