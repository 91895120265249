
import { defineComponent, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { bn, fw, tw } from '@/lib/vault/bn';
import { Config } from '@/lib/vault/config/config';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import useBuyLpModal from '@/composables/useBuyLpModal';
import useBuyTokenModal from '@/composables/useBuyTokenModal';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object },
  },

  setup(props) {
    const { t } = useI18n();
    const toast = useToast();
    const { explorerLinks, userNetworkConfig } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { toggleBuyLpModal } = useBuyLpModal();
    const { toggleBuyTokenModal } = useBuyTokenModal();

    // COMPUTED
    const defaultVaultTransactionInfo = computed(
      () =>
        `<a class="wallet-info" href="https://docs.acryptos.com/fees#all-vaults-except-acs-and-acsi-vault" target="_blank"><span>${t(
          'withdrawal_fee'
        )}</span>: 0.1%</a>`
    );

    const defaultFarmTransactionInfo = computed(
      () =>
        `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms" target="_blank"><span>${t(
          'harvest_fee'
        )}</span>: ${Config.acsHarvestFee} ACS</a>`
    );

    const buttonList = computed(() => [
      {
        name: 'harvest_to_vault',
        isVisible: !props.farmVault?.farmRewardsEmpty,
      },
      {
        name: 'harvest',
        isVisible: !props.farmVault?.farmRewardsEmpty,
      },
      {
        name: 'deposit',
        isVisible:
          !props.farmVault?.walletEmpty &&
          !(props.farmVault?.farm && props.farmVault.farm.deprecated) &&
          props.farmVault?.vault &&
          !props.farmVault.vault.showFarmDeprecatedOnly &&
          !props.farmVault.vault.deprecated &&
          !props.farmVault.vault.dualtoken,
      },
      {
        name: 'stake',
        isVisible:
          props.farmVault?.farm &&
          !props.farmVault.farm.deprecated &&
          (!props.farmVault.vaultEmpty || !props.farmVault.walletFarmEmpty),
      },
      {
        name: 'unstake',
        isVisible: !props.farmVault?.farmEmpty,
      },
      {
        name: 'withdraw',
        isVisible:
          !props.farmVault?.vaultEmpty && !props.farmVault?.vault.dualtoken,
      },
    ]);

    const visibleButtonList = computed(() =>
      buttonList.value.filter((item) => item.isVisible)
    );

    const walletInfoList = computed(() => {
      if (!props.farmVault?.walletInfo) {
        return [];
      }
      const splited = props.farmVault?.walletInfo.split('a> <a');
      let label = '';
      let sublabel = '';
      let labelEnd = 0;
      const labelStart = splited[0].indexOf('> ') + 2;
      if (splited.length === 1) {
        labelEnd = splited[0].indexOf('</a');
      } else {
        labelEnd = splited[0].indexOf(': <');
      }
      label = splited[0].slice(labelStart, labelEnd);

      return splited.map((item, index) => {
        const linkStart = item.indexOf('href="') + 6;
        const linkEnd = item.indexOf('" target="_blank"');
        const link = item.slice(linkStart, linkEnd);
        if (splited.length === 2 && index === 0) {
          label = 'Get ' + label;
          sublabel = 'Acsi.Finance';
        } else if (splited.length === 2 && index === 1) {
          sublabel = '1inch';
        } else if (!props.farmVault?.tokenSymbol.includes('-')) {
          label = 'Get ' + label;
        } else if (props.farmVault?.tokenSymbol.includes('/')) {
          sublabel = label;
          label = 'Deposit / Withdraw';
        } else {
          sublabel = label + ' LP';
          label = t('pair_unpair');
        }

        return {
          label,
          sublabel,
          link,
        };
      });
    });

    const disableAction = computed(() => {
      if (isGettingCalculatedWithdraw.value) {
        return true;
      }
      if (amount.value === '') {
        return true;
      }
      if (status.value === 'withdraw' && props.farmVault?.vault.isLiquid) {
        if (!props.farmVault.vault.stats.value.calculatedWithdraw) {
          return true;
        }
        const compact =
          1 -
          fw(props.farmVault.vault.stats.value.calculatedWithdraw) /
            parseFloat(amount.value);
        if (compact > 0.05) {
          return true;
        }
      }
      return false;
    });

    const compact = computed(() =>
      props.farmVault?.vault.stats.value.calculatedWithdraw &&
      amount.value !== '' &&
      !isGettingCalculatedWithdraw.value
        ? (
            (fw(props.farmVault?.vault.stats.value.calculatedWithdraw) /
              parseFloat(amount.value) -
              1) *
            100
          ).toFixed(2)
        : 0
    );

    // DATA
    const status = ref('');
    const amount = ref('');
    const isAll = ref(true);
    const timeout = ref();
    const isGettingCalculatedWithdraw = ref(false);

    // WATCHERS
    watch(
      () => props.farmVault?.id,
      () => {
        init();
      }
    );
    watch(amount, () => {
      clearTimeout(timeout.value);
      if (
        amount.value !== '' &&
        status.value === 'withdraw' &&
        props.farmVault?.vault.isLiquid
      ) {
        timeout.value = setTimeout(async () => {
          isGettingCalculatedWithdraw.value = true;
          const formatedAmount = props.farmVault?.vault.unapplyTokenDecimals(
            bn(tw(`${amount.value}`))
          );
          await props.farmVault?.vault.calculateWithdraw(formatedAmount);
          isGettingCalculatedWithdraw.value = false;
        }, 500);
      }
    });

    // METHODS
    const doAction = async (s) => {
      if (!amount.value && s !== 'harvest') {
        toast.warning(t('alert_input_correct_amount'));
        return;
      }
      let formatedAmount = null;
      status.value = 'loading';
      try {
        if (props.farmVault?.vault) {
          if (s !== 'harvest') {
            formatedAmount = props.farmVault.vault.unapplyTokenDecimals(
              bn(tw(`${amount.value}`))
            );
          }
          if (s === 'deposit') {
            await props.farmVault.vault.deposit(formatedAmount);
            if (props.farmVault?.farm && isAll.value) {
              await props.farmVault.farm.depositAll();
            }
          } else if (s === 'stake') {
            await props.farmVault.farm.depositInVaultToken(formatedAmount);
          } else if (s === 'unstake') {
            await props.farmVault.farm.withdrawInVaultToken(formatedAmount);
            if (isAll.value) {
              await updateBalances();
              const withdrawAmount = props.farmVault?.vault.stats.value
                .userBalanceVaultInTokenNormalized
                ? fw(
                    props.farmVault.vault.stats.value
                      .userBalanceVaultInTokenNormalized
                  )
                : 0;
              const formatedWithdrawAmount =
                props.farmVault?.vault.unapplyTokenDecimals(
                  bn(tw(`${withdrawAmount}`))
                );
              await props.farmVault?.vault.withdrawInToken(
                formatedWithdrawAmount
              );
            }
          } else if (s === 'withdraw') {
            if (props.farmVault.vault.isLiquid) {
              if (!isGettingCalculatedWithdraw.value) {
                await props.farmVault.vault.withdrawWithSlippage(
                  formatedAmount
                );
              }
            } else {
              await props.farmVault.vault.withdrawInToken(formatedAmount);
            }
          }
        } else {
          if (s !== 'harvest') {
            formatedAmount = bn(tw(`${amount.value}`));
          }
          if (s === 'unstake') {
            await props.farmVault?.farm.withdraw(formatedAmount);
          } else if (s === 'stake') {
            await props.farmVault?.farm.deposit(formatedAmount);
          }
        }
        if (s === 'harvest') {
          await props.farmVault?.farm.harvest();
        }
        await updateBalances();
      } catch (error) {
        console.error(error);
        toast.error(t('alert_transaction_error'));
      }
      init();
    };

    const updateBalances = async () => {
      await Promise.all([
        props.farmVault?.farm?.updateUserBalance(),
        props.farmVault?.farm?.updateUserBalanceFarm(),
        props.farmVault?.farm?.updateRewardPending(),
        props.farmVault?.vault?.updateUserBalance(),
        props.farmVault?.vault?.updateUserBalanceVault(),
      ]);
    };

    const init = () => {
      status.value = '';
      amount.value = '';
    };

    const gotoStatus = (s) => {
      if (s === 'withdraw' && props.farmVault?.vault?.withdrawAlert) {
        alert(props.farmVault?.vault.withdrawAlert);
      }
      if (s === 'harvest_to_vault') {
        toast.warning(t('alert_coming_soon'));
        return;
      }
      status.value = s;
      isAll.value = true;
      if (s === 'harvest') {
        doAction(s);
      }
    };

    const getMax = async () => {
      amount.value = getBalanceForAction() ? fw(getBalanceForAction()) : 0;
    };

    const getBalance = () => {
      return showTokenBalance(getBalanceForAction());
    };

    const getBalanceForAction = () => {
      if (status.value === 'deposit') {
        return props.farmVault?.vault.stats.value.userBalanceNormalized;
      } else if (status.value === 'stake' || status.value === 'withdraw') {
        return props.farmVault?.vault
          ? props.farmVault.vault.stats.value.userBalanceVaultInTokenNormalized
          : props.farmVault?.farm.stats.value.userBalance;
      } else {
        // unstake
        return props.farmVault?.farm.stats.value &&
          props.farmVault.farm.stats.value.userBalanceFarmInVaultTokenNormalized
          ? props.farmVault.farm.stats.value
              .userBalanceFarmInVaultTokenNormalized
          : props.farmVault?.farm.stats.value.userBalanceFarm;
      }
    };

    const goTo = (link) => {
      const splitedLink = link.split('/');
      if (link.includes('https://app.acsi.finance/#/trade/')) {
        const tokenIn = splitedLink[splitedLink.length - 2];
        const tokenOut = splitedLink[splitedLink.length - 1];
        toggleBuyTokenModal(true, tokenIn, tokenOut);
      } else if (link.includes('https://app.acsi.finance/#/pool')) {
        const poolId = splitedLink[splitedLink.length - 1];
        toggleBuyLpModal(true, poolId);
      } else {
        window.open(link, '_blank')?.focus();
      }
    };

    return {
      explorerLinks,
      userNetworkConfig,
      upToLargeBreakpoint,
      showTokenBalance,
      // computed
      compact,
      disableAction,
      walletInfoList,
      defaultVaultTransactionInfo,
      defaultFarmTransactionInfo,
      buttonList,
      visibleButtonList,
      // data
      isAll,
      status,
      amount,
      isGettingCalculatedWithdraw,
      // methods
      goTo,
      getBalance,
      gotoStatus,
      init,
      getMax,
      doAction,
    };
  },
});
