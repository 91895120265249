<template>
  <div class="welcome-home">
    <p class="welcome-home-title">
      {{ $t('samurai').toUpperCase() }},
      <br />
      {{ $t('welcome').toUpperCase() }}
      <span class="text-red-520">{{ $t('home').toUpperCase() }}!</span>
    </p>
    <p class="welcome-home-desc" v-html="$t('welcome_home_desc')"></p>
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .welcome-home {
    margin-top: -50px;
    height: 354px;
  }
  .welcome-home-title {
    padding-top: 50px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 200px;
  }
}
@media (min-width: 440px) {
  .welcome-home {
    margin-top: -70px;
    height: 490px;
  }
  .welcome-home-title {
    padding-top: 85px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 300px;
  }
}
@media (min-width: 640px) {
  .welcome-home {
    margin-top: -85px;
    height: 590px;
  }
  .welcome-home-title {
    padding-top: 100px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 400px;
  }
}
@media (min-width: 768px) {
  .welcome-home {
    margin-top: -110px;
    height: 795px;
  }
  .welcome-home-title {
    padding-top: 120px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 500px;
  }
}
@media (min-width: 1024px) {
  .welcome-home {
    margin-top: -135px;
    height: 990px;
  }
  .welcome-home-title {
    padding-top: 150px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 600px;
  }
}
@media (min-width: 1280px) {
  .welcome-home {
    margin-top: -165px;
    height: 1195px;
  }
  .welcome-home-title {
    padding-top: 190px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 770px;
  }
}
@media (min-width: 1536px) {
  .welcome-home {
    margin-top: -240px;
    height: 1495px;
  }
  .welcome-home-title {
    padding-top: 280px;
  }
  .welcome-home-title,
  .welcome-home-desc {
    width: 950px;
  }
}
.welcome-home {
  @apply w-full bg-transparent relative;
  background-image: url('~@/assets/images/welcome-home-bg.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.welcome-home-title {
  @apply text-black italic font-black text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-center;
  letter-spacing: -0.03em;
}
.welcome-home-desc {
  @apply font-medium text-black italic text-center text-sssm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-3 lg:mt-8;
  font-family: DINPro-CondMedium;
}
</style>
