
import { defineComponent } from 'vue';
import useVaults from '@/services/useVaults';

export default defineComponent({
  components: {},

  setup() {
    const { acsStats } = useVaults();

    return {
      acsStats,
    };
  },
});
