const defaultTheme = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss').Config} */
module.exports = {
  purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'], //deprecated in tailwindcss3x
  content: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  options: {
    safelist: [
      /^shadow/,
      /^bg/,
      /^text/,
      /^border/,
      /^from/,
      /^to/,
      /^dark:/,
      /^hover:/,
      'dark',
      /^dark:/,
    ],
  },
  darkMode: 'class', // false or 'media' or 'class'
  theme: {
    fontFamily: {
      body: [
        'Inter-Variable',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
      ],
      display: [
        '"Tiempos Headline Medium"',
        'Times',
        '"Times New Roman"',
        'serif',
      ],
    },
    fontSize: {
      '3xs': ['7px', '9px'],
      '2xs': ['9px', '11px'],
      xs: ['10px', '12px'],
      sssm: ['11px', '13px'],
      ssm: ['13px', '16px'],
      sm: ['14px', '18px'],
      base: ['16px', '20px'],
      md: ['20px', '24px'],
      lg: ['22px', '28px'],
      xl: ['26px', '32px'],
      '2xl': ['34px', '41px'],
      '3xl': ['50px', '61px'],
      '4xl': ['62px', '62px'],
      '5xl': ['80px', '97px'],
      '6xl': ['110px', '110px'],
      '7xl': ['132px', '132px'],
      '8xl': ['160px', '160px'],
      '9xl': ['200px', '200px'],
      '10xl': ['280px', '280px'],
    },
    boxShadow: {
      sm: '0 2px 4px 0 rgba(0,0,0,0.05)',
      DEFAULT:
        '0px 4px 6px -1px rgba(0, 0, 0, 0.05), 0px 2px 4px -1px rgba(0, 0, 0, 0.05)',
      lg: '0 2px 4px 0 rgba(0,0,0,0.03), 0 10px 40px 0 rgba(0,0,0,0.05)',
      xl: '0 2px 4px 0 rgba(0,0,0,0.05), 0 0px 40px 0 rgba(0,0,0,0.1)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.05)',
      none: 'none',
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      DEFAULT: theme('colors.zink.100', 'currentColor'),
    }),
    screens: {
      xs: '440px',
      '3xl': '1920px',
      ...defaultTheme.screens,
    },
    borderWidth: {
      1.5: '1.5px',
      5: '5px',
      ...defaultTheme.borderWidth,
    },
    extend: {
      colors: {
        //amber: '#E9A043',
        //emerald: '#1ABA00',
        //violet: '#9C5CFF',
        zink: {
          40: '#F9FBFE',
          50: '#F8FAFC',
          100: '#EAF0F6',
          130: '#E7EDF6',
          170: '#E5E5E5',
          200: '#E2E8F0',
          250: '#D6D6D6',
          300: '#CBD5E1',
          320: '#C0C0C2',
          350: '#B4B3B7',
          400: '#94A3B8',
          430: '#8B898F',
          440: '#828286',
          450: '#727176',
          500: '#64748B',
          520: '#626167',
          550: '#5A5A63',
          600: '#475569',
          650: '#42424E',
          660: '#41414E',
          670: '#3E3E3E',
          700: '#334155',
          730: '#31313C',
          750: '#25252D',
          780: '#1E1E26',
          800: '#1E293B',
          850: '#162031',
          900: '#0F172A',
        },
        primary: {
          50: '#53555e',
          100: '#494b54',
          200: '#3f414a',
          300: '#353740',
          400: '#2b2d36',
          500: '#21232c',
          600: '#171922',
          700: '#0d0f18',
          800: '#03050e',
          900: '#000004',
        },
        'primary-dark': {
          50: '#6a7cff',
          100: '#6072ff',
          200: '#5668ff',
          300: '#4c5eff',
          400: '#4254ff',
          500: '#384aff',
          600: '#2e40f5',
          700: '#2436eb',
          800: '#1a2ce1',
          900: '#1022d7',
        },
        blue: {
          50: '#6a7cff',
          100: '#6072ff',
          200: '#5668ff',
          300: '#4c5eff',
          390: '#4276F6',
          400: '#4254ff',
          500: '#384aff',
          520: '#3683EA',
          600: '#2e40f5',
          700: '#2436eb',
          800: '#1a2ce1',
          810: '#1969C8',
          900: '#1022d7',
          930: '#07A6FF',
        },
        pink: {
          50: '#ff4dff',
          100: '#ff43ff',
          200: '#ff39ff',
          300: '#ff2fff',
          400: '#fc25ff',
          500: '#f21bf6',
          550: '#ef3bc7',
          600: '#e811ec',
          700: '#de07e2',
          800: '#d400d8',
          900: '#ca00ce',
        },
        amber: {
          280: '#FCFF5E',
          490: '#F6E9D9',
          550: '#E9A043',
          910: '#666751',
        },
        violet: {
          230: '#DECDFD',
          250: '#DFB5FE',
          450: '#9C5CFF',
          650: '#772277',
        },
        emerald: {
          490: '#1ABA00',
        },
        red: {
          520: '#ED1C24',
        },
        teal: {
          420: '#24E1B0',
        },
      },
      gridTemplateColumns: {
        64: 'repeat(64, minmax(0, 1fr))',
      },
    },
  },
  plugins: [],
  //corePlugins: {
  //  preflight: false,
  //},
};
