
import {
  defineComponent,
  reactive,
  computed,
  toRefs,
  PropType,
  Ref,
} from 'vue';
import { useStore } from 'vuex';
import useNumbers from '@/composables/useNumbers';
import AppSlippageForm from '@/components/forms/AppSlippageForm.vue';
import useFathom from '@/composables/useFathom';

import { TradeInterface } from '@/store/modules/app';
import {
  tradeLiquidityOptions,
  ethereumTxTypeOptions,
} from '@/constants/options';
import useWeb3 from '@/services/web3/useWeb3';

import useEthereumTxType from '@/composables/useEthereumTxType';

export enum TradeSettingsContext {
  trade,
  invest,
}

export default defineComponent({
  name: 'TradeSettingsPopover',

  components: {
    AppSlippageForm,
  },

  props: {
    context: {
      type: [String, Number] as PropType<TradeSettingsContext>,
      required: true,
    },
  },

  setup(props) {
    // DATA
    const { context }: { context: Ref<TradeSettingsContext> } = toRefs(props);

    // COMPOSABLES
    const store = useStore();
    const { fNum } = useNumbers();
    const { explorerLinks, isV1Supported, isEIP1559SupportedNetwork } =
      useWeb3();
    const { trackGoal, Goals } = useFathom();
    const { ethereumTxType, setEthereumTxType } = useEthereumTxType();

    // DATA
    const data = reactive({
      tradeLiquidityOptions,
    });

    // COMPUTED
    const appTradeLiquidity = computed(() => store.state.app.tradeLiquidity);
    const appTradeInterface = computed<TradeInterface>(
      () => store.state.app.tradeInterface
    );
    const appTransactionDeadline = computed<number>(
      () => store.state.app.transactionDeadline
    );
    const hideLiquidity = computed(
      () =>
        !isV1Supported.value || context.value === TradeSettingsContext.invest
    );

    // METHODS
    const setTradeLiquidity = (tradeLiquidity) =>
      store.commit('app/setTradeLiquidity', tradeLiquidity);
    const setTransactionDeadline = (transactionDeadline) =>
      store.commit('app/setTransactionDeadline', transactionDeadline);

    function onActivatorClick(): void {
      if (context.value === TradeSettingsContext.trade) {
        trackGoal(Goals.ClickTradeSettings);
      } else if (context.value === TradeSettingsContext.invest) {
        trackGoal(Goals.ClickInvestSettings);
      }
    }

    return {
      // data
      ...toRefs(data),
      Goals,
      // constants,
      TradeInterface,
      // computed
      appTradeLiquidity,
      appTradeInterface,
      appTransactionDeadline,
      hideLiquidity,
      isEIP1559SupportedNetwork,
      // methods
      setTradeLiquidity,
      setTransactionDeadline,
      fNum,
      explorer: explorerLinks,
      onActivatorClick,
      ethereumTxType,
      setEthereumTxType,
      ethereumTxTypeOptions,
    };
  },
});
