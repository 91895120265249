import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createSlots as _createSlots, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b61ea12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_AnimatePresence = _resolveComponent("AnimatePresence")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "bal-modal",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
        onKeyup: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)), ["esc"]))
      }, [
        _createVNode(_Transition, {
          name: "modal-bg",
          mode: "out-in",
          appear: ""
        }, {
          default: _withCtx(() => [
            (_ctx.showContent)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "modal-bg",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AnimatePresence, {
            initial: { opacity: 0, translateY: 7.5 },
            animate: {
          opacity: 1,
          translateY: 0,
          delay: 100,
        },
            exit: { opacity: 0, translateY: 15 },
            isVisible: _ctx.showContent,
            onOnExit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
            class: "flex justify-center w-full"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(['content', _ctx.widthClasses]),
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_BalCard, {
                  title: _ctx.title,
                  shadow: "lg",
                  "no-pad": _ctx.noPad,
                  "no-content-pad": _ctx.noContentPad,
                  rounded: _ctx.rounded,
                  bgColor: _ctx.bgColor,
                  class: "modal-card",
                  "no-border": ""
                }, _createSlots({
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _: 2
                }, [
                  (_ctx.$slots.header)
                    ? {
                        name: "header",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (_ctx.$slots.footer)
                    ? {
                        name: "footer",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1032, ["title", "no-pad", "no-content-pad", "rounded", "bgColor"])
              ], 2)
            ]),
            _: 3
          }, 8, ["initial", "isVisible"])
        ])
      ], 32))
    : _createCommentVNode("", true)
}