
import { defineComponent, ref } from 'vue';
import { fwp, fw } from '@/lib/utils/bn';
import { showApr } from '@/lib/vault/farmVaultUtils';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object },
  },

  setup(props) {
    const { account, userNetworkConfig } = useWeb3();

    // DATA
    const isHarvesting = ref(false);
    const isRebalancing = ref(false);

    // METHOD
    const getDateFromTimestamp = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      });
    };

    const harvestVault = () => {
      isHarvesting.value = true;
      props.farmVault?.vault.harvest().finally(() => {
        isHarvesting.value = false;
      });
    };

    const rebalanceVault = () => {
      isRebalancing.value = true;
      props.farmVault?.vault.rebalance().finally(() => {
        isRebalancing.value = false;
      });
    };

    return {
      fw,
      fwp,
      showApr,
      account,
      userNetworkConfig,
      // datas
      isHarvesting,
      isRebalancing,
      // methods
      harvestVault,
      rebalanceVault,
      getDateFromTimestamp,
    };
  },
});
