export { Poller };

class Poller extends EventTarget {
  constructor(provider, blockNumber = null) {
    super();

    this.provider = provider;
    this.blockNumber = blockNumber;

    this.calls = [];
  }

  add(call) {
    this.calls.push(call);
  }

  async poll(options = {}) {
    if (!this.calls || !this.calls.length) {
      return;
    }

    const calls = this.calls;
    const callsPromise = calls.map((call) => call.method());
    const result = await Promise.all(callsPromise);
    let currentBlock;
    if (!this.blockNumber) {
      currentBlock = await this.provider.getBlockNumber();
    }

    if (options.clearCalls) {
      this.calls = [];
    }
    result.forEach((data, index) =>
      calls[index].cb(data, this.blockNumber || currentBlock)
    );

    this.dispatchEvent(new CustomEvent('poll'));
  }

  init() {
    this.calls = [];
  }
}
