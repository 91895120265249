import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalCard = _resolveComponent("BalCard")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bal-popover-activator group h-6 lg:h-8",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.trigger === 'click' && _ctx.togglePopover())),
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trigger === 'hover' && _ctx.showPopover())),
      onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.trigger === 'hover' && _ctx.hidePopover()))
    }, [
      _renderSlot(_ctx.$slots, "activator")
    ], 32),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.popoverWrapperClasses)
    }, [
      _createVNode(_component_BalCard, _mergeProps({ shadow: "lg" }, _ctx.$attrs, {
        rounded: _ctx.rounded,
        noBorder: _ctx.noBorder
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16, ["rounded", "noBorder"])
    ], 2)
  ])), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}