import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_TradeCard = _resolveComponent("TradeCard")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    noPad: "",
    show: _ctx.isVisible,
    width: "lg",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    style: {"z-index":"39"}
  }, {
    default: _withCtx(() => [
      (_ctx.loadingTokenLists)
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createBlock(_component_TradeCard, { key: 1 }))
    ]),
    _: 1
  }, 8, ["show"]))
}