const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0x85518cb62E1278D4F1b5a94DE8530f235eAB04DE',
  wbnb: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', // WCRO
  busd: '0xF2001B145b43032AAF5Ee2884e456CCd805F677D', // DAI
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [
    '0x3Eb9FF92e19b73235A393000C176c8bb150F1B20', // VVS WCRO-DAI
    '0xA111C17f8B8303280d3EB01BBcd61000AA7F39F9', // VVS WCRO-WETH
    '0x3d2180DB9E1B909f35C398BC39EF36108C0FC8c3', // VVS WCRO-USDT
    '0x8F09fFf247B8fDB80461E5Cf5E82dD1aE2EBd6d7', // VVS WCRO-WBTC
    '0xe61Db569E231B3f5530168Aa2C9D50246525b6d6', // VVS WCRO-USDC
    '0x94FD83482016BCC5FcA5972A3635aA6524C3F557', // VVS TUSD-USDC
  ],
  additionalPricingPairsDeprecated: [],
};

Config.farmVaults = [
  {
    vault: {
      address: '0x0B7313B2C7812fE80fde2aA02Dbd6b45d90D114D',
      tokenSymbol: 'TUSD',
      tokenImage: 'tusd.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2584443,
    },
    tags: ['annex', 'usd'],
  },
  {
    vault: {
      address: '0x25241015d45e923Fbd645010b41120aD5296C7A9',
      tokenSymbol: 'USDT',
      tokenDecimals: 6,
      tokenImage: 'usdt.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2512986,
      defaultRoiDay: 0.246943576 / 365,
    },
    tags: ['annex', 'usd'],
  },
  {
    vault: {
      address: '0x63e379ef600F307C59b888828DdFC07CB09b527c',
      tokenSymbol: 'USDC',
      tokenDecimals: 6,
      tokenImage: 'usdc.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2512984,
      defaultRoiDay: 0.2264 / 365,
    },
    tags: ['annex', 'usd'],
  },
  {
    vault: {
      address: '0x8C706E4518F5dB613ec6FbB1EfC5B3151Ca9A5C8',
      tokenSymbol: 'CRO',
      tokenImage: 'cro.svg',
      isGasToken: true,
      isBestDayReturn: true,
      firstDepositBlock: 2486849,
      defaultRoiDay: 0.41829 / 365,
    },
    tags: ['annex'],
  },
  {
    vault: {
      address: '0x524dF48b0c63732c92C74e89C3Ef095Aecc9Ad61',
      tokenSymbol: 'WBTC',
      tokenDecimals: 8,
      tokenImage: 'btc.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2486047,
      defaultRoiDay: 0.524513 / 365,
    },
    tags: ['annex'],
  },
  {
    vault: {
      address: '0x123bd2C0f73954cB7Ff1cF9095495fAF0d8D89Fd',
      tokenSymbol: 'WETH',
      tokenImage: 'eth.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2484716,
    },
    tags: ['annex'],
  },
  {
    vault: {
      address: '0x569B344AD6BF087A285f0D415d0066028921D873',
      tokenSymbol: 'CRO',
      tokenImage: 'cro.svg',
      isBestDayReturn: true,
      isGasToken: true,
      firstDepositBlock: 1967743,
    },
    tags: ['tectonic'],
  },
  {
    vault: {
      address: '0x35e801C62464A379B09D1EeD21b5187522712B6d',
      tokenSymbol: 'WETH',
      tokenImage: 'eth.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2156894,
      defaultRoiDay: 0.086 / 365,
    },
    tags: ['tectonic'],
  },
  {
    vault: {
      address: '0xdADD97fafaE613F53cD225Bf0Ad973dEDF79C43c',
      tokenSymbol: 'USDT',
      tokenDecimals: 6,
      tokenImage: 'usdt.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2158076,
      defaultRoiDay: 0.18802 / 365,
    },
    tags: ['tectonic', 'usd'],
  },
  {
    vault: {
      address: '0x21D37Ee5924a0549Eb9BB58dFdb7AF9A1C6FE792',
      tokenSymbol: 'WBTC',
      tokenDecimals: 8,
      tokenImage: 'btc.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2173394,
      defaultRoiDay: 0.139962 / 365,
    },
    tags: ['tectonic'],
  },
  {
    vault: {
      address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
      tokenSymbol: 'USDC',
      tokenDecimals: 6,
      tokenImage: 'usdc.svg',
      isBestDayReturn: true,
      firstDepositBlock: 2173293,
      defaultRoiDay: 0.20617767 / 365,
    },
    tags: ['tectonic', 'usd'],
  },
  {
    vault: {
      address: '0x42a461E5e88426c8B969b43fAE8d3bD47219BBF2',
      tokenSymbol: 'DAI',
      tokenImage: 'dai.svg',
      isBestDayReturn: true,
      fromBlock: 2317587,
      defaultRoiDay: 0.211941868 / 365,
    },
    tags: ['tectonic', 'usd'],
  },
  {
    vault: {
      address: '0xE10DaEB5f173fcAd4b91d0299E7F725Ef7b31045',
      tokenSymbol: 'TUSD',
      tokenImage: 'tusd.svg',
      isBestDayReturn: true,
      fromBlock: 2317589,
      defaultRoiDay: 0.198084581 / 365,
    },
    tags: ['tectonic', 'usd'],
  },
];

export { Config };
