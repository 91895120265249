
import { defineComponent } from 'vue';
import Effect from '@/components/mint/Effect.vue';
import Release from '@/components/mint/Release.vue';
import Anniversary from '@/components/mint/Anniversary.vue';
import StakeToMint from '@/components/mint/StakeToMint.vue';
import Akira from '@/components/mint/Akira.vue';
import Cori from '@/components/mint/Cori.vue';
import Satoshi from '@/components/mint/Satoshi.vue';
import Mechas from '@/components/mint/Mechas.vue';
import GameFi from '@/components/mint/GameFi.vue';
import Metaverse from '@/components/mint/Metaverse.vue';
import WelcomeHome from '@/components/mint/WelcomeHome.vue';
import Join from '@/components/mint/Join.vue';

export default defineComponent({
  components: {
    Effect,
    Release,
    Anniversary,
    StakeToMint,
    Akira,
    Cori,
    Satoshi,
    Mechas,
    GameFi,
    Metaverse,
    WelcomeHome,
    Join,
  },
});
