import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_component_BalBtn, {
    circle: "",
    color: "zink",
    size: _ctx.upToLargeBreakpoint ? 'xs' : 'sm',
    onClick: _ctx.toggleDarkMode
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalIcon, {
        name: "bx:bxs-sun",
        size: _ctx.upToLargeBreakpoint ? 'sm' : 'md'
      }, null, 8, ["size"])
    ]),
    _: 1
  }, 8, ["size", "onClick"]))
}