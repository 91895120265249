import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63e9dfa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.farmVaultTags, (tag, ind) => {
      return (_openBlock(), _createElementBlock("span", { key: ind }, [
        (_ctx.tags[tag])
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["tags", [
          _ctx.tags[tag].toLowerCase(),
          tag === 'boost' || tag === 'onsen' || tag === 'warning'
            ? ''
            : 'px-2 rounded-full',
        ]])
            }, [
              (tag === 'warning')
                ? (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 0,
                    name: "ant-design:warning-filled",
                    class: "text-red-520"
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.tags[tag]), 1)
                  ], 64))
            ], 2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}