import { computed } from 'vue';
import useWeb3 from './web3/useWeb3';
import addresses from '@/constants/addresses';
import abis from '@/constants/abis';
import { chunkArray } from '@/lib/utils';

export default function useMultiCall() {
  const { connector } = useWeb3();

  // COMPUTED
  const multiCallContract = computed(() =>
    connector.value.web3
      ? new connector.value.web3.eth.Contract(
          abis.multicall,
          addresses.multiCall
        )
      : null
  );

  // METHODS
  const multiCall = async (calls) => {
    if (!calls.length) {
      return;
    }
    const callsO = [];
    calls.forEach((call) => {
      const method = call.method();
      if (method) {
        call.outputs =
          method._method.outputs.length > 1
            ? method._method.outputs
            : method._method.outputs[0];
        // @ts-ignore
        callsO.push([call.target, method.encodeABI()]);
      }
    });

    const ret = await Promise.all(
      chunkArray(callsO, 1000).map((item) =>
        multiCallContract.value.methods.aggregate(item).call()
      )
    );
    const o = ret.map((i, ind) => {
      // @ts-ignore
      return i.returnData.map((j, index) => {
        const outputs = calls[index + ind * 1000].outputs;
        if (Array.isArray(outputs)) {
          j = connector.value.web3.eth.abi.decodeParameters(outputs, j);
        } else {
          j = connector.value.web3.eth.abi.decodeParameter(outputs, j);
        }
        // @ts-ignore
        return calls[index + ind * 1000].cb(j, i[0]);
      });
    });

    return o;
  };

  return {
    // methods
    multiCall,
  };
}
