
import { defineComponent } from 'vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {},

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      upToLargeBreakpoint,
    };
  },
});
