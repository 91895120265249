import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-alert', _ctx.classes])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['bal-alert-container', _ctx.containerClasses])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(['bal-alert-icon', _ctx.iconClasses])
      }, [
        _createVNode(_component_BalIcon, {
          name: "akar-icons:info",
          size: _ctx.iconSize
        }, null, 8, ["size"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(['bal-alert-content', _ctx.contentClasses])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h5", {
            class: _normalizeClass(['bal-alert-title', _ctx.titleClasses, _ctx.textSizeClass])
          }, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ])
          ], 2),
          (_ctx.$slots.default || _ctx.description)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(['bal-alert-description', _ctx.descriptionColor, _ctx.textSizeClass])
              }, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.description), 1)
                ])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        (_ctx.actionLabel)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([_ctx.actionClasses])
            }, [
              _createVNode(_component_BalBtn, {
                color: _ctx.btnColor,
                size: "xs",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('actionClick')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.actionLabel), 1)
                ]),
                _: 1
              }, 8, ["color"])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}