export const acsNftBaseURI = 'https://nft-data.acryptos.com';

export const tofunft = 'https://tofunft.com/nft/bsc';

export const onsenApi = 'https://api.unrekt.info/api/v1/acryptos-onsen';

export const supportedNetworks = [
  {
    id: 'binance',
    label: 'BNB Chain',
    value: 'bsc',
    logo: 'binance.svg',
    chainId: 56,
  },
  {
    id: 'base',
    label: 'Base',
    logo: 'base.svg',
    value: 'base',
    chainId: 8453,
  },
  {
    id: 'kava',
    label: 'Kava',
    logo: 'kava.svg',
    value: 'kava',
    chainId: 2222,
  },
  {
    id: 'optimism',
    label: 'Optimism',
    logo: 'optimism.svg',
    value: 'optimism',
    chainId: 10,
  },
  {
    id: 'ethereum',
    label: 'Ethereum',
    logo: 'ethereum.svg',
    value: 'ether',
    chainId: 1,
  },
  {
    id: 'fantom',
    label: 'Fantom',
    logo: 'fantom.svg',
    value: 'ftm',
    chainId: 250,
  },
  {
    id: 'arbitrum',
    label: 'Arbitrum',
    logo: 'arbitrum.svg',
    value: 'arbitrum',
    chainId: 42161,
  },
  {
    id: 'polygon',
    label: 'Polygon',
    logo: 'polygon.svg',
    value: 'polygon',
    chainId: 137,
  },
  {
    id: 'cronos',
    label: 'Cronos',
    logo: 'cronos.svg',
    value: 'cronos',
    chainId: 25,
  },
  {
    id: 'avalanche',
    label: 'Avalanche',
    logo: 'avalanche.svg',
    value: 'avax',
    chainId: 43114,
  },
  {
    id: 'moonriver',
    label: 'Moonriver',
    logo: 'moonriver.svg',
    value: 'movr',
    chainId: 1285,
  },
  {
    id: 'moonbeam',
    label: 'Moonbeam',
    logo: 'moonbeam.svg',
    value: 'glmr',
    chainId: 1284,
  },
  {
    id: 'astar',
    label: 'Astar',
    logo: 'astar.svg',
    value: 'astr',
    chainId: 592,
  },
  {
    id: 'canto',
    label: 'Canto',
    logo: 'canto.svg',
    value: 'canto',
    chainId: 7700,
  },
  {
    id: 'gnosis',
    label: 'Gnosis',
    logo: 'gnosis.svg',
    value: 'xdai',
    chainId: 100,
  },
  {
    id: 'harmony',
    label: 'Harmony',
    logo: 'harmony.png',
    value: 'one',
    chainId: 1666600000,
  },
  // {
  //   id: 'aurora',
  //   label: 'Aurora',
  //   logo: 'aurora.svg',
  //   value: 'AURORA',
  //   chainId: 1313161554
  // }
];

export const vaultFactoriesInfo = [
  {
    chainId: 56,
    address: '0xD3c37bF1B31C706D19bf63D8D30D5331af921938',
    beacons: [
      {
        address: '0x7B40816aCF6D8e9Dbca54983931dc3E1c241617c',
        project: 'channels',
      },
      {
        address: '0x99371dff21367e13df0ba87e252286E234474fF0',
        project: 'annex',
      },
      {
        address: '0xECaeD91ef8Eed08Ee4238c82c18d16B91e8DAAE8',
        project: 'venus',
      },
      {
        address: '0x295F3458fBd0C30A23EA81a8d345517C985ce1e0',
        project: 'apelending',
      },
      {
        address: '0xba4b7A193868FbCeffCFec647051a17f51421D8A',
        project: 'dforce',
      },
      {
        address: '0x2B217F385099A92653c0B49a01479e28137bEce2',
        project: 'uniswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/56/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
      {
        address: '0xEb7E2DCc30c065FCd7Ac4e1f0e050d7fD3Ee25BA',
        project: 'pancakeswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/56/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
      {
        address: '0x316285126A5e579a9CD529E10fdB661775649Db0',
        project: 'biswapv3d',
        tags: ['deprecated'],
        deprecated: true,
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/56/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
      {
        address: '0x97AFFDCA8d2D90C4C6Ed6eEdB3fE1eD97507EB3E',
        project: 'biswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/56/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
      {
        address: '0x07016c00258d9B6CA891653DB5A0A548A94528BA',
        project: 'magicfox',
        walletInfo:
          '<a class="wallet-info" href="https://app.magicfox.fi/swap/liquidity/add/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo: '<small>Mint this LP token at Magicfox.</small>',
        deprecated: true,
        tags: ['deprecated'],
      },
    ],
  },
  {
    chainId: 43114,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'benqi',
      },
      {
        address: '0x16C4BA98623a1B05d35b9923fB00E9fE02D0B47f',
        project: 'flairdex',
        walletInfo:
          '<a class="wallet-info" href="https://app.flairdex.xyz/liquidity/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo: '<small>Mint this LP token at Flairdex.</small>',
      },
    ],
  },
  {
    chainId: 1285,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'moonwell',
      },
    ],
  },
  {
    chainId: 250,
    address: '0x35e801C62464A379B09D1EeD21b5187522712B6d',
    beacons: [
      {
        address: '0x2Dd5a62a5D4E4D20622061C823f9648E3078012d',
        project: 'scream',
        deprecated: true,
        tags: ['deprecated'],
      },
      {
        address: '0xA68A4e6fce6Ac77F82253c8BFD9454CD0D5576aC',
        project: 'geist',
        deprecated: true,
        tags: ['deprecated'],
      },
    ],
  },
  {
    chainId: 1284,
    address: '0x21D37Ee5924a0549Eb9BB58dFdb7AF9A1C6FE792',
    beacons: [
      {
        address: '0x700D012B384666f88Db9630F55fD751d30fD3fef',
        project: 'moonwell',
        warning:
          'There has been a security incident with the Nomad bridge affecting our Moonwell Artemis Vaults on Moonbeam. <a target="_blank" href="https://twitter.com/acryptosx/status/1554319673503518721">Read More</a>',
      },
    ],
  },
  {
    chainId: 592,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'starlay',
        test: true,
      },
    ],
  },
  {
    chainId: 100,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'agave',
        test: true,
      },
    ],
  },
  {
    chainId: 42161,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'radiantv1',
        deprecated: true,
        tags: ['deprecated'],
      },
      {
        address: '0xE10DaEB5f173fcAd4b91d0299E7F725Ef7b31045',
        project: 'dforce',
      },
      {
        address: '0xdFA7339e1924086b4DDdA6c996Df5092C39D95bB',
        project: 'solidlizard',
        walletInfo:
          '<a class="wallet-info" href="https://solidlizard.finance/liquidity/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo: '<small>Mint this LP token at Solidlizard.</small>',
      },
      {
        address: '0xefdb18850b7ad0234da2d1afb5aa68c150def5b3',
        project: 'chronos',
        deprecated: true,
        tags: ['deprecated'],
        walletInfo:
          '<a class="wallet-info" href="https://app.chronos.exchange/liquidity" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo:
          '<small>Mint this LP token at Chronos. Select "Deposit LP" instead of "Deposit + Stake".</small>',
      },
      {
        address: '0x0ABF9e4Dcf80198D022ed795E1EA09FEe235801e',
        project: 'solunea',
        deprecated: true,
        tags: ['deprecated'],
        walletInfo:
          '<a class="wallet-info" href="https://www.solunea.xyz/liquidity/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo:
          '<small>Mint this LP token at Solunea. Click "Advanced" and Select "Deposit LP" instead of "Deposit + Stake".</small>',
      },
      {
        address: '0xDd21ae18d164711AEacD6C187a8DAC5a973AA4B3',
        project: 'uniswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/42161/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
    ],
  },
  {
    chainId: 1,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x21D37Ee5924a0549Eb9BB58dFdb7AF9A1C6FE792',
        project: 'dforce',
      },
      {
        address: '0x16C4BA98623a1B05d35b9923fB00E9fE02D0B47f',
        project: 'verse',
        walletInfo:
          '<a class="wallet-info" href="https://verse.bitcoin.com/pools/" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo: '<small>Mint this LP token at Verse.</small>',
        deprecated: true,
        tags: ['deprecated'],
      },
    ],
  },
  {
    chainId: 10,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'dforce',
      },
      {
        address: '0xE10DaEB5f173fcAd4b91d0299E7F725Ef7b31045',
        project: 'sonne',
      },
      {
        address: '0x3E70Db52d48B03b5ff6e3aF12CBE1858901c2854',
        project: 'uniswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/10/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
    ],
  },
  {
    chainId: 2222,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x37115CbFce229F3D65073DD155bc0cb4a39d9454',
        project: 'marev1',
        deprecated: true,
        tags: ['deprecated'],
      },
      {
        address: '0x9cDEe51E8683bDaac6f0FFF59c048025cD9ACf8A',
        project: 'mare',
      },
      {
        address: '0xFD5f3f639CCAA0cD42b0a19f497590bd715fd414',
        project: 'toreus',
        deprecated: true,
        tags: ['deprecated'],
      },
      {
        address: '0x2Dd5a62a5D4E4D20622061C823f9648E3078012d',
        project: 'equilibre',
        walletInfo:
          '<a class="wallet-info" href="https://equilibrefinance.com/liquidity/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo:
          '<small>Mint this LP token at Equilibre, Do not deposit into the gauge directly: toggle "advanced" and trigger "deposit" instead of "stake LP".</small>',
      },
    ],
  },
  {
    chainId: 8453,
    address: '0x35e801C62464A379B09D1EeD21b5187522712B6d',
    beacons: [
      {
        address: '0xFD5f3f639CCAA0cD42b0a19f497590bd715fd414',
        project: 'sonne',
      },
      {
        address: '0x16C4BA98623a1B05d35b9923fB00E9fE02D0B47f',
        project: 'moonwell',
      },
      {
        address: '0x0C5047E17105a744f3D1f1DeDDb4677748AFE9a1',
        project: 'aerodrome',
        walletInfo:
          '<a class="wallet-info" href="https://aerodrome.finance/liquidity/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo:
          '<small>Mint this LP token at Aerodrome, Do not deposit into the gauge directly: toggle "advanced" and trigger "deposit" instead of "stake LP".</small>',
      },
      {
        address: '0x2Dd5a62a5D4E4D20622061C823f9648E3078012d',
        project: 'uniswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/8453/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
    ],
  },
  {
    chainId: 7700, //canto
    address: '0x8EDbB16169e4ca9e22d41426D9448C3c0F8e4961',
    beacons: [
      {
        address: '0x21D37Ee5924a0549Eb9BB58dFdb7AF9A1C6FE792',
        project: 'velocimeter',
        deprecated: true,
        tags: ['deprecated'],
        walletInfo:
          '<a class="wallet-info" href="https://www.velocimeter.xyz/liquidity/%TOKEN%" target="_blank"><span data-i18n="get">Get</span> %TOKENSYMBOL%</a><br />',
        additionalInfo:
          '<small>Mint this LP token at Velocimeter, Do not deposit into the gauge directly: toggle "advanced" and trigger "deposit" instead of "stake LP".</small>',
      },
    ],
  },
  {
    chainId: 137,
    address: '0x569B344AD6BF087A285f0D415d0066028921D873',
    beacons: [
      {
        address: '0x42a461E5e88426c8B969b43fAE8d3bD47219BBF2',
        project: 'dforce',
      },
      {
        address: '0x53a5dD07127739e5038cE81eff24ec503A6CC479',
        project: 'uniswapv3',
        tags: ['test'],
        walletInfo:
          '<a class="wallet-info" href="https://app.acryptos.com/vaults/137/%VAULT%" target="_blank"><span data-i18n="deposit">Deposit</span> via Lite UI</a><br />',
        dualtoken: true,
      },
    ],
  },
];

export const warningVaults = [
  '0x16e2dA81A9A6AAFF03FF8bEE3e14D352457f67Dd',
  '0x4D181689b7E060B42E24B311C8bCF764dc605Fa4',
  '0x34449F61483C6c31987205f299a93F799651070F',
];

/*
const stableCoins = [
  '0x55d398326f99059fF775485246999027B3197955',
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  '0x14016E85a25aeb13065688cAFB43044C2ef86784',
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
  '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
  '0x1A93B23281CC1CDE4C4741353F3064709A16197d',
  '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
  '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
  '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
  '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
  '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E'
];
*/
