
import { defineComponent } from 'vue';
import useNfts from '@/services/useNfts';

export default defineComponent({
  setup() {
    const { setSuccessModalOpen, successModalOpen, newNft, loadingNewNft } =
      useNfts();

    return {
      loadingNewNft,
      newNft,
      successModalOpen,
      setSuccessModalOpen,
    };
  },
});
