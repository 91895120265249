
import { computed, defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import MintSuccessModal from './MintSuccessModal.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import useWeb3 from '@/services/web3/useWeb3';
import { sortArray } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';

export default defineComponent({
  components: {
    MintSuccessModal,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const { account } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { collectionsData } = useNfts();
    const { formatedFarmVaults } = useVaults();

    // DATA
    const sortBy = ref('rank');
    const sortOrder = ref(false);

    // COMPUTED
    const currentPage = computed(() => {
      if (router.currentRoute.value.name === 'leaderboard') {
        return `leaderboard_${route.params.key}`;
      }
      return router.currentRoute.value.name;
    });

    const acsNfts = computed(() => {
      return collectionsData.value[0].allNfts.slice(2) || [];
    });
    const acsiNfts = computed(() => {
      return collectionsData.value[1].allNfts.slice(2) || [];
    });

    const totalSupplyAcs = computed(() => {
      return acsNfts.value.length;
    });
    const totalSupplyAcsi = computed(() => {
      return acsiNfts.value.length;
    });

    const sortedAcsNftsByPrice = computed(() => {
      return sortArray(
        sortArray(
          acsNfts.value.map((item) => {
            return {
              ...item,
              rank: item && item.attributes ? item.attributes[1].value : '--',
              idInt: Number.parseInt(item?.id || '0'),
              key: 'acs',
            };
          }) || [],
          'stakedUsd',
          false
        ),
        'rank',
        false
      ).map((item, index) => {
        return {
          ...item,
          no: index + 1,
        };
      });
    });
    const sortedAcsiNftsByPrice = computed(() => {
      // return sortArray(acsiNfts.value, 'stakedUsd', false).map(
      //   (item, index) => {
      //     return {
      //       ...item,
      //       rank: index < 88 ? 3 : index < 888 ? 2 : 1,
      //       idInt: Number.parseInt(item?.id || '0'),
      //       no: index + 1,
      //       key: 'acsi'
      //     };
      //   }
      // );
      return sortArray(
        sortArray(
          acsiNfts.value.map((item) => {
            return {
              ...item,
              rank: item && item.attributes ? item.attributes[1].value : '--',
              idInt: Number.parseInt(item?.id || '0'),
              key: 'acsi',
            };
          }) || [],
          'stakedUsd',
          false
        ),
        'rank',
        false
      ).map((item, index) => {
        return {
          ...item,
          no: index + 1,
        };
      });
    });

    const sortedNftsByPrice = computed(() => {
      if (router.currentRoute.value.name === 'leaderboard') {
        if (route.params.key === 'acs') {
          return sortedAcsNftsByPrice.value;
        }
        return sortedAcsiNftsByPrice.value;
      }
      return sortedAcsNftsByPrice.value.concat(sortedAcsiNftsByPrice.value);
    });

    const showedNfts = computed(() => {
      if (sortBy.value === 'rank' && sortOrder.value) {
        return sortArray(
          [...sortedNftsByPrice.value].reverse(),
          sortBy.value,
          sortOrder.value
        );
      }
      return sortArray(sortedNftsByPrice.value, sortBy.value, sortOrder.value);
    });

    const forRnak3 = computed(() => {
      // if (currentPage.value === 'leaderboard_acsi') {
      //   return `${
      //     sortedNftsByPrice.value.length >= 88
      //       ? sortedNftsByPrice.value[87].price || 8
      //       : 38
      //   } ACSI`;
      // }
      const rank3Nfts = sortedNftsByPrice.value.filter(
        (item) => item.rank === '3 - Shogun'
      );
      if (currentPage.value === 'leaderboard_acsi') {
        return `${rank3Nfts[rank3Nfts.length - 1]?.price || 8} ACSI`;
      }
      return `${rank3Nfts[rank3Nfts.length - 1]?.price || 8} ACS`;
    });

    const forRnak2 = computed(() => {
      // if (currentPage.value === 'leaderboard_acsi') {
      //   return `${
      //     sortedNftsByPrice.value.length >= 888
      //       ? sortedNftsByPrice.value[887].price || 8
      //       : 38
      //   } ACSI`;
      // }
      const rank2Nfts = sortedNftsByPrice.value.filter(
        (item) => item.rank === '2 - Daimyo'
      );
      if (currentPage.value === 'leaderboard_acsi') {
        return `${rank2Nfts[rank2Nfts.length - 1]?.price || 8} ACSI`;
      }
      return `${rank2Nfts[rank2Nfts.length - 1]?.price || 8} ACS`;
    });

    const forRnak1 = computed(() => {
      if (currentPage.value === 'leaderboard_acsi') {
        return '38 ACSI';
      }
      return '8 ACS';
    });

    const myNfts = computed(() => {
      return sortedNftsByPrice.value
        .filter(
          (item) =>
            item.owner &&
            account.value &&
            item.owner.toLowerCase() === account.value.toLowerCase()
        )
        .map((item, index) => {
          return {
            ...item,
            myNo: index + 1,
          };
        });
    });

    const showedMyNfts = computed(() =>
      sortArray(myNfts.value, sortBy.value, sortOrder.value)
    );

    const totalStakedAcs = computed(() => {
      return acsNfts.value.length
        ? acsNfts.value.reduce((total, item) => (item.staked || 0) + total, 0)
        : 0;
    });
    const totalStakedAcsi = computed(() => {
      return acsiNfts.value.length
        ? acsiNfts.value.reduce((total, item) => (item.staked || 0) + total, 0)
        : 0;
    });

    const currentApyAcs = computed(
      () =>
        formatedFarmVaults.value.find((item) => item.name === 'ACS').apyShown
    );

    const currentApyAcsi = computed(
      () =>
        formatedFarmVaults.value.find((item) => item.name === 'ACSI').apyShown
    );

    // METHODS
    const isUsersNft = (nft) => {
      return (
        nft.owner &&
        account.value &&
        nft.owner.toLowerCase() === account.value.toLowerCase()
      );
    };
    const sort = (key) => {
      sortBy.value = key;
      sortOrder.value = !sortOrder.value;
    };
    const gotoNftViewer = (nft) => {
      router.push({ path: `/BSC/nfts/${nft.key}/${nft.id}` });
    };

    return {
      showApr,
      totalSupplyAcs,
      totalSupplyAcsi,
      upToLargeBreakpoint,
      // computed
      currentPage,
      currentApyAcs,
      currentApyAcsi,
      totalStakedAcs,
      totalStakedAcsi,
      myNfts,
      showedMyNfts,
      showedNfts,
      forRnak3,
      forRnak2,
      forRnak1,
      // methods
      gotoNftViewer,
      sort,
      isUsersNft,
    };
  },
});
