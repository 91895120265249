<template>
  <div class="metaverse-container">
    <p class="metaverse-acs">AcryptoS</p>
    <p class="metaverse-title">{{ $t('metaverse').toUpperCase() }}</p>
    <p
      class="metaverse-desc brimming"
      v-html="$t('world_brimming_with_adventures')"
    ></p>
    <p class="metaverse-desc" v-html="$t('trailer_coming_soon')"></p>
    <img
      src="~@/assets/images/metaverse-trailer.jpg"
      class="metaverse-trailer"
    />
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .metaverse-container {
    margin-top: -50px;
    height: 390px;
  }
  .metaverse-title {
    margin-top: -60px;
  }
  .metaverse-acs {
    padding-top: 45px;
  }
  .brimming {
    margin-top: 30px;
  }
  .metaverse-trailer {
    height: 155px;
  }
}
@media (min-width: 440px) {
  .metaverse-container {
    margin-top: -70px;
    height: 535px;
  }
  .metaverse-title {
    margin-top: -60px;
  }
  .metaverse-acs {
    padding-top: 60px;
  }
  .brimming {
    margin-top: 35px;
  }
  .metaverse-trailer {
    height: 240px;
  }
}
@media (min-width: 640px) {
  .metaverse-container {
    margin-top: -85px;
    height: 645px;
  }
  .metaverse-title {
    margin-top: -85px;
  }
  .metaverse-acs {
    padding-top: 70px;
  }
  .brimming {
    margin-top: 40px;
  }
  .metaverse-trailer {
    height: 280px;
  }
}
@media (min-width: 768px) {
  .metaverse-container {
    margin-top: -110px;
    height: 865px;
  }
  .metaverse-title {
    margin-top: -105px;
  }
  .metaverse-acs {
    padding-top: 120px;
  }
  .brimming {
    margin-top: 60px;
  }
  .metaverse-trailer {
    height: 360px;
  }
}
@media (min-width: 1024px) {
  .metaverse-container {
    margin-top: -135px;
    height: 1080px;
  }
  .metaverse-title {
    margin-top: -120px;
  }
  .metaverse-acs {
    padding-top: 150px;
  }
  .brimming {
    margin-top: 70px;
  }
  .metaverse-trailer {
    height: 495px;
  }
}
@media (min-width: 1280px) {
  .metaverse-container {
    margin-top: -165px;
    height: 1295px;
  }
  .metaverse-title {
    margin-top: -160px;
  }
  .metaverse-acs {
    padding-top: 190px;
  }
  .brimming {
    margin-top: 90px;
  }
  .metaverse-trailer {
    height: 545px;
  }
}
@media (min-width: 1536px) {
  .metaverse-container {
    margin-top: -205px;
    height: 1625px;
  }
  .metaverse-title {
    margin-top: -190px;
  }
  .metaverse-acs {
    padding-top: 240px;
  }
  .brimming {
    margin-top: 130px;
  }
  .metaverse-trailer {
    height: 595px;
  }
}
.metaverse-container {
  @apply w-full bg-transparent relative;
  background-image: url('~@/assets/images/metaverse-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}
.metaverse-title {
  @apply text-white font-black text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-center;
  letter-spacing: 0.2em;
}
.metaverse-acs {
  @apply text-black font-normal text-3xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl text-center;
  font-family: Gloss And Bloom;
  letter-spacing: -0.03em;
}
.metaverse-desc {
  @apply font-medium text-white italic text-ssm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center;
  font-family: DINPro-CondMedium;
}
.metaverse-trailer {
  @apply mx-auto mt-4 lg:mt-8;
}
</style>
