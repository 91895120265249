<template>
  <div class="akira-container">
    <img src="~@/assets/images/akira-gold.svg" class="akira" />
    <p class="akira-meet">{{ $t('meet') }}</p>
    <p class="akira-title">{{ $t('akira') }}</p>
    <p class="akira-desc" v-html="$t('akira_desc')"></p>
  </div>
</template>
<style scoped>
@media (max-width: 440px) {
  .akira-container {
    margin-top: -17.6%;
    height: 341px;
  }
  .akira {
    width: 40%;
    top: 25%;
    left: 3%;
  }
  .akira-meet {
    top: 35%;
    left: 34%;
  }
  .akira-title {
    top: 30%;
    left: 43%;
  }
  .akira-desc {
    top: 52%;
    left: 32%;
  }
}
@media (min-width: 440px) {
  .akira-container {
    margin-top: -144px;
    height: 480px;
  }
  .akira {
    width: 28%;
    top: 35%;
    left: 3%;
  }
  .akira-meet {
    top: 36%;
    left: 35%;
  }
  .akira-title {
    top: 34%;
    left: 44%;
  }
  .akira-desc {
    top: 55%;
    left: 30%;
  }
}
@media (min-width: 640px) {
  .akira-container {
    margin-top: -144px;
    height: 680px;
  }
  .akira {
    width: 28%;
    top: 35%;
    left: 3%;
  }
  .akira-meet {
    top: 33%;
    left: 30%;
  }
  .akira-title {
    top: 32%;
    left: 44%;
  }
  .akira-desc {
    top: 49%;
    right: 2%;
  }
}
@media (min-width: 768px) {
  .akira-container {
    margin-top: -144px;
    height: 680px;
  }
  .akira {
    width: 28%;
    top: 30%;
    left: 3%;
  }
  .akira-meet {
    top: 33%;
    left: 33%;
  }
  .akira-title {
    top: 32%;
    left: 44%;
  }
  .akira-desc {
    top: 52%;
    left: 18%;
  }
}
@media (min-width: 1024px) {
  .akira-container {
    margin-top: -278px;
    height: 980px;
  }
  .akira {
    width: 28%;
    top: 33%;
    left: 3%;
  }
  .akira-meet {
    top: 28%;
    left: 17%;
  }
  .akira-title {
    top: 27%;
    left: 23%;
  }
  .akira-desc {
    top: 55%;
    right: 5%;
  }
}
@media (min-width: 1280px) {
  .akira-container {
    margin-top: -278px;
    height: 1024px;
  }
  .akira {
    width: 28%;
    top: 27%;
    left: 10%;
  }
  .akira-meet {
    top: 28%;
    left: 30%;
  }
  .akira-title {
    top: 27%;
    left: 36%;
  }
  .akira-desc {
    top: 49%;
    left: 25%;
  }
}
@media (min-width: 1536px) {
  .akira-container {
    margin-top: -278px;
    height: 1374px;
  }
  .akira {
    width: 28%;
    top: 27%;
    left: 17%;
  }
  .akira-meet {
    top: 28%;
    left: 40%;
  }
  .akira-title {
    top: 27%;
    left: 47%;
  }
  .akira-desc {
    top: 49%;
    left: 37%;
  }
}
.akira-container {
  @apply w-full bg-transparent relative;
  background-image: url('~@/assets/images/akira-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.akira {
  @apply absolute;
}
.akira-meet {
  @apply absolute text-black font-black text-base sm:text-3xl lg:text-5xl italic;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.akira-title {
  @apply absolute text-red-520 font-normal text-4xl sm:text-5xl lg:text-10xl;
  font-family: Gloss And Bloom;
  transform: rotate(-9deg);
  letter-spacing: -0.03em;
}
.akira-desc {
  @apply absolute text-black italic text-center text-sssm sm:text-lg lg:text-2xl;
  font-family: DINPro-CondMedium;
  transform: rotate(-9deg);
}
</style>
