const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  multicall: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  wbnb: '0x24Dc3617963E0422C826bC7705B378Ab43da517A', // WETH
  busd: '0x4Fabb145d64652a948d72533023f6E7A623C7C53', // BUSD
  stableSwaps: [],
  tokens: {},
  contractsFromBlock: {},
  additionalPricingPairs: [],
  additionalPricingPairsDeprecated: [],
};

export { Config };
