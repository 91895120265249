
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import useBreakpoints from '@/composables/useBreakpoints';
import useNfts from '@/services/useNfts';
import useWeb3 from '@/services/web3/useWeb3';
import { shorten } from '@/lib/utils/index';
import { tofunft } from '@/constants';
import TypeOwner from './TypeOwner.vue';
import BurnAlertModal from './BurnAlertModal.vue';

export default defineComponent({
  components: {
    BurnAlertModal,
    TypeOwner,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const toast = useToast();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { collectionsData } = useNfts();
    const { explorerLinks, account } = useWeb3();

    // DATA
    const copiedLink = ref(false);
    const isOpenBurnAlertModal = ref(false);

    // COMPUTED
    const collectionData = computed(() => {
      return collectionsData.value[route.params.key === 'acs' ? 0 : 1];
    });
    const nft = computed(() =>
      collectionData.value.allNfts.find(
        (item) => item && item.id === route.params.id
      )
    );

    const isUserNft = computed(
      () =>
        nft.value?.owner &&
        account.value &&
        nft.value?.owner.toLowerCase() === account.value.toLowerCase()
    );

    // METHODS
    const makeAvatar = () => {
      toast.warning(t('alert_coming_soon'));
    };

    const downloadImage = async () => {
      if (nft.value && nft.value.image) {
        const splited = nft.value.image.split('/');
        const imageName = splited[splited.length - 1];
        const link = document.createElement('a');
        link.href = await toDataURL(nft.value.image);
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const toDataURL = (url) => {
      return fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          return URL.createObjectURL(blob);
        });
    };

    const copyLink = () => {
      if (!copiedLink.value) {
        navigator.clipboard.writeText(window.location.href);
        copiedLink.value = true;

        setTimeout(() => {
          copiedLink.value = false;
        }, 2 * 1000);
      }
    };

    const trade = () => {
      if (nft.value && nft.value.id) {
        window
          .open(
            `${tofunft}/${collectionData.value.address}/${nft.value.id}`,
            '_blank'
          )
          ?.focus();
      }
    };

    return {
      route,
      collectionData,
      shorten,
      explorerLinks,
      upToLargeBreakpoint,
      // computed
      nft,
      isUserNft,
      // data
      copiedLink,
      isOpenBurnAlertModal,
      // methods
      trade,
      copyLink,
      downloadImage,
      makeAvatar,
    };
  },
});
